import moment from "moment";

export const getChartLabels = (step, monthes) => {
  if (step === "days") {
    return [...Array(30)]
      .map((it, i) => {
        if (i === 0) {
          return moment().format("DD.MM");
        }

        return moment().add(-i, "day").format("DD.MM");
      })
      .reverse();
  } else if (step === "quarter") {
    return [...Array(90)]
      .map((it, i) => {
        if (i === 0) {
          return moment().format("DD.MM");
        }

        return moment().add(-i, "day").format("DD.MM");
      })
      .reverse();
  } else {
    const currentMonth = new Date().getMonth() + 1;
    const startArray = monthes?.slice(0, currentMonth);
    const endArray = monthes?.slice(currentMonth, 12);
    return [...endArray, ...startArray];
  }
};
