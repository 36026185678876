import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Scrollbar } from "../../../../components/common/Scrollbar/Scrollbar";
import { StyledDataTable } from "../../../../components/common/Table/Table.styled";
import { NoDataComponent } from "../../../../components/common/TableColumns";
import { SkeletonDashboardPromoTable } from "../../../../components/ui/Skeleton/SkeletonDashboardPromoTable/SkeletonDashboardPromoTable";
import { SkeletonPaginationDashboardTable } from "../../../../components/ui/Skeleton/SkeletonPaginationDashboardTable/SkeletonPaginationDashboardTable";
import { TableCheckbox } from "../../../../components/ui/TableCheckbox/TableCheckbox";
import { useLangUrlDefault } from "../../../../hooks";
import { ApiService } from "../../../../services";
import { getAllCabinet } from "../../../../store/reducers/CabinetReducer/Cabinet.selectors";

import { PromoModalDelete } from "./PromoModalDelete";
import {
  actionColumn,
  activeColumn,
  dateColumn,
  promocodeColumn,
  siteColumn
} from "./Promocodes.columns";

export const PromocodesTable = ({
  loading,
  fetchCabinetPromocodes,
  params,
  setParams
}) => {
  // **Props
  const [, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { t } = useTranslation();
  const { promocodes } = useSelector(getAllCabinet);

  // **Local state
  const [deletedPromocodeId, setDeletedPromocodeId] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [listSites, setListSites] = useState([]);

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const openModalHandler = (id) => {
    setIsModalOpened(true);
    setDeletedPromocodeId(id);
  };

  const handleFilter = (value) => setParams({ ...params, siteIds: value });

  const conditionalRowStyles = [
    {
      when: (row) => selectedRows.some((r) => r.id === row.id),
      style: {
        backgroundColor: "rgba(246, 247, 249, 0.80)"
      }
    }
  ];

  const columns = [
    siteColumn(params.siteIds, handleFilter, listSites, t),
    promocodeColumn(t),
    dateColumn(t),
    activeColumn(fetchCabinetPromocodes, t),
    actionColumn(hrefLang, openModalHandler, t)
  ];

  useEffect(() => {
    if (JSON.stringify(params) !== JSON.stringify(promocodes.prevParams)) {
      fetchCabinetPromocodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const customStyles = {
    table: {
      style: {
        marginBottom: "18px"
      }
    },
    rows: {
      style: {
        minHeight: "64px"
      }
    }
  };

  useEffect(() => {
    ApiService.getUserPromoSitesList().then((response) => {
      if (response.status !== 200 || response.data?.length < 1) {
        return;
      }
      setListSites(response.data);
    });
  }, []);

  return (
    <>
      <Scrollbar horizontal>
        <StyledDataTable
          styleRules={{
            showedAll: true
          }}
          noDataComponent={<NoDataComponent bordered />}
          data={promocodes.data?.content}
          columns={columns}
          selectableRows
          selectableRowsComponent={TableCheckbox}
          selectableRowsHighlight
          onSelectedRowsChange={handleRowSelected}
          conditionalRowStyles={conditionalRowStyles}
          progressPending={loading}
          progressComponent={<SkeletonDashboardPromoTable cells={10} />}
          persistTableHead={!loading}
          customStyles={customStyles}
        />
      </Scrollbar>
      {loading && <SkeletonPaginationDashboardTable />}
      <PromoModalDelete
        isModalOpened={isModalOpened}
        setIsModalOpened={setIsModalOpened}
        deletedPromocodeId={deletedPromocodeId}
        setDeletedPromocodeId={setDeletedPromocodeId}
        fetchCabinetPromocodes={fetchCabinetPromocodes}
      />
    </>
  );
};
