import { ApiService } from "../../../../services";

export const visitCountryHelper = (country, fingerprint) => {
  const sessionVisitedCountry = sessionStorage?.getItem("visitedCountry")
    ? JSON.parse(sessionStorage?.getItem("visitedCountry"))
    : [];

  if (
    sessionVisitedCountry.length === 0 ||
    !sessionVisitedCountry.includes(country)
  ) {
    sessionStorage.setItem(
      "visitedCountry",
      JSON.stringify([...sessionVisitedCountry, country])
    );
    ApiService.visitedCountry({
      countryId: country,
      fingerprint
    });
  }
};
