import * as yup from "yup";

import {
  EMAIL_REGEX,
  EMAIL_STAGE_REGEX,
  SPECIAL_CHARACTERS,
  URL_IN_TEXT
} from "../constants";

export const ReviewSchemaWithAuth = (trns, t) =>
  yup.object().shape({
    name: yup.string().required(t(`${trns}.name.rules.required`)),
    email: yup
      .string()
      .trim()
      .required(t(`${trns}.email.rules.required`))
      .matches(
        process.env?.REACT_APP_MODE === "prod"
          ? EMAIL_REGEX
          : EMAIL_STAGE_REGEX,
        t(`${trns}.email.rules.email`)
      ),
    advantages: yup
      .string()
      .test({
        name: "advantages-url",
        message: t(`${trns}.reviewBody.rules.url`),
        test: (value) => !URL_IN_TEXT.test(value)
      })
      .test({
        name: "advantages-special-characters",
        message: t(`${trns}.reviewBody.rules.specialCharacters`),
        test: (value) => !SPECIAL_CHARACTERS.test(value)
      }),
    disadvantages: yup
      .string()
      .test({
        name: "disadvantages-url",
        message: t(`${trns}.reviewBody.rules.url`),
        test: (value) => !URL_IN_TEXT.test(value)
      })
      .test({
        name: "disadvantages-special-characters",
        message: t(`${trns}.reviewBody.rules.specialCharacters`),
        test: (value) => !SPECIAL_CHARACTERS.test(value)
      }),
    reviewBody: yup
      .string()
      .required(t(`${trns}.reviewBody.rules.required`))
      .test({
        name: "review-max",
        message: t(`${trns}.reviewBody.rules.max`),
        test: (value) => value?.length <= 100
      })
      .test({
        name: "review-url",
        message: t(`${trns}.reviewBody.rules.url`),
        test: (value) => !URL_IN_TEXT.test(value)
      })
      .test({
        name: "review-special-characters",
        message: t(`${trns}.reviewBody.rules.specialCharacters`),
        test: (value) => !SPECIAL_CHARACTERS.test(value)
      })
  });

export const ReviewSchema = (trns, t) =>
  yup.object().shape({
    advantages: yup
      .string()
      .test({
        name: "advantages-url",
        message: t(`${trns}.reviewBody.rules.url`),
        test: (value) => !URL_IN_TEXT.test(value)
      })
      .test({
        name: "advantages-special-characters",
        message: t(`${trns}.reviewBody.rules.specialCharacters`),
        test: (value) => !SPECIAL_CHARACTERS.test(value)
      }),
    disadvantages: yup
      .string()
      .test({
        name: "disadvantages-url",
        message: t(`${trns}.reviewBody.rules.url`),
        test: (value) => !URL_IN_TEXT.test(value)
      })
      .test({
        name: "disadvantages-special-characters",
        message: t(`${trns}.reviewBody.rules.specialCharacters`),
        test: (value) => !SPECIAL_CHARACTERS.test(value)
      }),
    reviewBody: yup
      .string()
      .required(t(`${trns}.reviewBody.rules.required`))
      .test({
        name: "review-max",
        message: t(`${trns}.reviewBody.rules.max`),
        test: (value) => value?.length <= 100
      })
      .test({
        name: "review-url",
        message: t(`${trns}.reviewBody.rules.url`),
        test: (value) => !URL_IN_TEXT.test(value)
      })
      .test({
        name: "review-special-characters",
        message: t(`${trns}.reviewBody.rules.specialCharacters`),
        test: (value) => !SPECIAL_CHARACTERS.test(value)
      })
  });
