import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ApiService } from "../../../services";
import { AllActions } from "../../../store/reducers/AllActions";
import {
  StyledBreadcrumbs,
  StyledTitle,
  StyledTitleCard
} from "../AddEditPromocode/AddEditPromocode.styled";
import { StyledProfileCard } from "../Profile/Profile.styled";

import { Form } from "./Form/Form";

const AddEditService = () => {
  const { id } = useParams();

  const { t } = useTranslation();
  return (
    <>
      <StyledBreadcrumbs
        items={[
          {
            title: t("dashboard.services.table.title"),
            route: "dashboard/services/"
          },
          {
            title: t(`dashboard.services.addEdit.title${id ? "Edit" : "Add"}`)
          }
        ]}
      />
      <StyledTitle size="sm">
        {t(`dashboard.services.addEdit.title${id ? "Edit" : "Add"}`)}
      </StyledTitle>
      <StyledProfileCard size="small" radius="small">
        <StyledTitleCard size="sm">
          {t(`dashboard.services.addEdit.title${id ? "Edit" : "Add"}`)}
        </StyledTitleCard>
        <Form />
      </StyledProfileCard>
    </>
  );
};

export default AddEditService;

AddEditService.getServerSideState = async (store, params) => {
  const { data: pageContent } = await ApiService.getPageContent({
    ...params,
    languageCode: params.locale
  });

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setPageContent(pageContent));
};
