import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useDispatchedActions } from "../../../../../hooks";
import { getAllProxySite } from "../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { Reviews } from "../Reviews";

import { InfoPart } from "./InfoPart/InfoPart";
import { ReplyPart } from "./ReplyPart/ReplyPart";
import {
  StyledAnswers,
  StyledReviewItem,
  StyledShowAllButton
} from "./ReviewItem.styled";
import { UserPart } from "./UserPart/UserPart";

export const ReviewItem = ({
  siteTitle,
  captchaRef,
  setTokenCaptcha,
  review,
  changeHandler,
  isRootReview,
  nestedResponseValue,
  isDashboard,
  parentToggleReview,
  last
}) => {
  const { t } = useTranslation();
  // **Redux state
  const { reviews } = useSelector(getAllProxySite);

  // **Local state
  const [maxHeight, setMaxHeight] = useState(nestedResponseValue === 1 ? 1000 : null);
  const [answersToShow, setAnswersToShow] = useState([]);
  const [showAllAnswers, setShowAllAnswers] = useState(false);

  // **Dispatch
  const { setReviewInfo } = useDispatchedActions();

  // **Ref
  const reviewInnerRef = useRef(null);
  const reviewRef = useRef(null);

  const toggleReview = (toParent) => {
    parentToggleReview?.(true);
    if (!reviewInnerRef.current) {
      return;
    }

    const newMaxHeight = reviewInnerRef.current.scrollHeight + 1000;

    setMaxHeight(maxHeight && toParent !== true ? null : newMaxHeight);
  };

  useEffect(() => {
    if (reviewInnerRef.current && nestedResponseValue === 1) {
      const newMaxHeight = reviewInnerRef.current.scrollHeight + 1000;

      setMaxHeight(newMaxHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewInnerRef.current]);

  const showAllHandler = () => {
    setAnswersToShow(review.responses);
    setShowAllAnswers(true);
  };

  useEffect(() => {
    setAnswersToShow(
      review.responses?.length > 2
        ? review.responses?.slice(0, 2)
        : review.responses
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [review]);

  // logick for scroll to review if  we have reviewId
  useEffect(() => {
    if (!reviewRef.current) {
      return;
    }

    if (reviews.reviewId && review.id === reviews.reviewId) {
      const coords = reviewRef.current.getBoundingClientRect();

      window.scrollTo({
        top: coords.top + window.scrollY,
        behavior: "smooth"
      });

      setReviewInfo({ page: null, id: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewRef.current, reviews.reviewId]);

  return (
    <StyledReviewItem
      ref={nestedResponseValue === 1 ? reviewRef : null}
      isDashboard={isDashboard}
      nestingLevel={nestedResponseValue}
      isRootReview={isRootReview}
      last={last}
      itemScope
      itemType="https://schema.org/Review"
    >
      <UserPart
        review={review}
        isRootReview={isRootReview}
        siteTitle={siteTitle || review?.siteName}
        isDashboard={isDashboard}
      />
      <InfoPart
        review={review}
        toggleReview={parentToggleReview}
        nestingLevel={nestedResponseValue}
        isDashboard={isDashboard}
      />
      <ReplyPart
        isDashboard={isDashboard}
        captchaRef={captchaRef}
        setTokenCaptcha={setTokenCaptcha}
        review={review}
        changeHandler={changeHandler}
        toggleReview={toggleReview}
        isRootReview={isRootReview}
        isReviewsOpened={!!maxHeight}
        nestedResponseValue={nestedResponseValue}
        parentToggleReview={parentToggleReview}
      />
      {review?.responses?.length !== 0 && (
        <StyledAnswers
          ref={reviewInnerRef}
          nestingLevel={nestedResponseValue}
          maxHeight={maxHeight}
        >
          <Reviews
            siteTitle={siteTitle || review?.siteName}
            captchaRef={captchaRef}
            setTokenCaptcha={setTokenCaptcha}
            reviews={answersToShow}
            changeHandler={changeHandler}
            nestedResponseValue={nestedResponseValue + 1}
            toggleReview={toggleReview}
            isDashboard={isDashboard}
          />
          {review?.responses?.length > 2 && !showAllAnswers && (
            <StyledShowAllButton
              color="secondary"
              onClick={showAllHandler}
              iconRight="refresh"
            >
              {t("reviews.showAll")}
            </StyledShowAllButton>
          )}
        </StyledAnswers>
      )}
    </StyledReviewItem>
  );
};
