import { useTranslation } from "react-i18next";

import animation from "../../assets/animations/main.riv";
import { mainHeroImageMobile } from "../../assets/img";
import { SeoSection } from "../../components/common/SeoSection/SeoSection";
import { Rive } from "../../components/ui/Rive/Rive";
import { scrollToWithOffset } from "../../utils/helpers/scroll.helper";

import { getMainPageState } from "./MainPage.state";
import { RestyledMainSection } from "./MainPage.styled";
import { Proxy } from "./components/Proxy/Proxy";
import { UserChoice } from "./components/UserChoice/UserChoice";

const MainPage = () => {
  const { t } = useTranslation();

  const handleButtonClick = () => {
    scrollToWithOffset("proxyContent", -50);
  };

  return (
    <>
      <RestyledMainSection
        buttonLabel={t("navigation.selectService")}
        animation={<Rive src={animation} autoPlay />}
        images={{
          mobile1x: mainHeroImageMobile,
          mobile2x: mainHeroImageMobile
        }}
        handleButtonClick={handleButtonClick}
        star
      />
      <Proxy />
      <UserChoice />
      <SeoSection noPaddingTop />
    </>
  );
};
export default MainPage;

MainPage.getServerSideState = async (store, params) => {
  await getMainPageState(store, params);
};
