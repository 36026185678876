export const allCategory = [
  {
    id: "All",
    name: "all",
    localization: {
      en: "All",
      ru: "Все",
      ua: "Всі"
    }
  }
];
