import { useEffect, useId, useRef, useState } from "react";

import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { RecentReviews } from "../../../../components/common/RecentReviews/RecentReviews";
import { GroupButtonTab } from "../../../../components/ui/GroupButtonTab/GroupButtonTab";
import { Select } from "../../../../components/ui/Select/Select";
import { useLangUrlDefault } from "../../../../hooks";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { Scrollbar } from "../../../../components/common/Scrollbar/Scrollbar";

import { generateOptions } from "./About.helper";
import {
  StyledAboutContainer,
  StyledAboutContentTop,
  StyledAboutSelectBox,
  StyledCaptchaBox
} from "./About.styled";
import { HeadAbout } from "./HeadAbout/HeadAbout";
import { Info } from "./Informations/Info";
import { Promocodes } from "./Promocodes/Promocodes";
import { Rating } from "./Rating/Rating";
import { AddReview } from "./Reviews/AddReview/AddReview";
import { ListReview } from "./Reviews/ListReview/ListReview";

export const About = () => {
  const { alias } = useParams();
  const id = useId();
  const { pathname, hash } = useLocation();
  const [, lastUrlParam] = pathname.split("/").reverse();

  // **Ref
  const reCaptchaRef = useRef();
  const { t } = useTranslation();
  const [, hrefLang] = useLangUrlDefault();

  const options = generateOptions(t);

  const initTab = (urlParam, hash, isInit = false) => {
    if (urlParam === "promocodes") {
      return "promocodes";
    }

    if (isInit) {
      return "info";
    }

    if (hash) {
      const tab = options.find(
        (option) => option.value === hash.replace("#", "")
      );

      return tab ? tab.value : "info";
    }

    return "info";
  };

  // **Redux state
  const { captchaConfig } = useSelector(getAllContent);
  const { recentReviews } = useSelector(getAllProxySite);

  const navigate = useNavigate();

  const [inited, setInited] = useState(false);
  const [activeTab, setActiveTab] = useState(
    initTab(lastUrlParam, hash.replace("#", ""), true)
  );
  const [, setTokenCaptcha] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState(false);

  const { enable, siteKey } = captchaConfig.data;
  const setTab = (newTab = "") => {
    if (!newTab || !options.find((option) => option.value === newTab)) {
      setActiveTab("info");
      navigate(`${hrefLang}/proxy/${alias}/#info`, { replace: true });
      return;
    }

    setActiveTab(newTab);
    if (newTab === "promocodes") {
      navigate(`${hrefLang}/proxy/${alias}/promocodes/`, {
        replace: !newTab
      });
    } else {
      navigate(`${hrefLang}/proxy/${alias}/#${newTab}`, {
        replace: !newTab
      });
    }
  };

  useEffect(() => {
    if (!inited) {
      const tab = initTab(lastUrlParam, hash.replace("#", ""));
      if (activeTab !== tab) {
        setActiveTab(tab);
      }
      setInited(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUrlParam, hash]);

  return (
    <StyledAboutContainer active={activeTab} id={id}>
      <StyledAboutContentTop active={activeTab}>
        <HeadAbout id={id} />
        <StyledAboutSelectBox>
          <Select
            options={options}
            value={activeTab}
            onChange={setTab}
            size="lg"
          />
        </StyledAboutSelectBox>
        <Scrollbar horizontal>
          <GroupButtonTab data={options} active={activeTab} onSelect={setTab} />
        </Scrollbar>
        <Info active={activeTab === "info"} />
        <AddReview
          active={activeTab === "reviews"}
          captchaRef={reCaptchaRef}
          setTokenCaptcha={setTokenCaptcha}
          recaptchaError={recaptchaError}
        />
        <Rating active={activeTab === "rating"} />
        <Promocodes active={activeTab === "promocodes"} />
      </StyledAboutContentTop>
      {recentReviews.data?.length > 0 ? (
        <RecentReviews
          isHiden={activeTab !== "info"}
          onAll={() => setTab("reviews")}
          reviews={recentReviews}
          isDataLoaded={recentReviews.isDataLoaded}
          isSitePage
        />
      ) : null}
      <ListReview
        active={activeTab === "reviews"}
        captchaRef={reCaptchaRef}
        setTokenCaptcha={setTokenCaptcha}
      />
      {enable && siteKey && (
        <StyledCaptchaBox>
          <ReCAPTCHA
            sitekey={siteKey}
            size="invisible"
            ref={reCaptchaRef}
            onErrored={() => setRecaptchaError(true)}
          />
        </StyledCaptchaBox>
      )}
    </StyledAboutContainer>
  );
};
