export const getCountriesById = (countryIds, allCountries) => {
  const countriesArray = [];

  countryIds.forEach((countryId) => {
    allCountries.forEach((stateCountry) => {
      if (countryId === stateCountry.id && stateCountry.frontShow) {
        countriesArray.push(stateCountry);
      }
    });
  });

  return countriesArray;
};

export const getCountryById = (countryId, allCountries) => {
  let country = "";
  allCountries?.forEach((stateCountry) => {
    if (countryId === stateCountry.id && stateCountry.frontShow) {
      country = stateCountry;
    }
  });

  return country;
};
