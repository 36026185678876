import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Title } from "../../components/ui/Title/Title";
import {
  useBrowserFingerprint,
  useDispatchedActions,
  useLangUrlDefault
} from "../../hooks";
import { ApiService } from "../../services";
import { getAllContent } from "../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getAllUser } from "../../store/reducers/UserReducer/User.selectors";
import { fingerprintLibVersion } from "../../utils/constants/fingerprintLibVersion";

import { getSiteState } from "./ServicePage.state";
import {
  StyledContainer,
  StyledContentBlock,
  StyledSiteNotFound,
  StyledTitleContainer
} from "./ServicePage.styled";
import { About } from "./components/About/About";
import { Alternative } from "./components/Alternative/Alternative";
import { Info } from "./components/Info/Info";
import { ProductServiceMarkup } from "./components/ProductServiceMarkup/ProductServiceMarkup";

const ServicePage = () => {
  // **Props
  const { alias } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const browserFingerprint = useBrowserFingerprint();

  // **Redux state
  const { site, statistic, alternative, alternativePromocodes } =
    useSelector(getAllProxySite);

  const [, hrefLang] = useLangUrlDefault();
  const {
    pageContent: { current }
  } = useSelector(getAllContent);
  const { isUserAuthenticated } = useSelector(getAllUser);

  // **Local state
  const [isLoading, setIsLoading] = useState(alias !== site.data?.alias);

  // **Dispatch
  const {
    getProxySiteInfo,
    getProxySiteReviewsAmount,
    getAlternativeSites,
    getAllPromocodesBySite,
    getProxySiteCompaints,
    setSiteViewStatistic,
    getAlternativePromocodes,
    getProxySiteRecentReviews
  } = useDispatchedActions();

  useEffect(() => {
    if (alias !== site.data?.alias) {
      if (!statistic.isStatisticSites.includes(alias)) {
        setSiteViewStatistic(alias);
      }
      getProxySiteInfo(alias);
      getProxySiteReviewsAmount(alias);
      getAllPromocodesBySite(alias);
      getAlternativePromocodes({
        currentSiteAlias: alias,
        size: 3
      });
      getProxySiteRecentReviews({
        sort: "creationDate",
        siteId: alias,
        page: 0,
        size: 5,
        showMore: 0
      });
      if (isUserAuthenticated) {
        getProxySiteCompaints({
          sort: {
            creationDate: "ASC"
          },
          siteId: alias,
          currentPage: 0,
          size: 10
        });
      }

      window.scrollTo({ top: 0, behavior: "smooth" });

      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias]);

  useEffect(() => {
    if (!alternative.isDataLoaded) {
      getAlternativeSites(6);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!alternativePromocodes.isDataLoaded) {
      getAlternativePromocodes({
        currentSiteAlias: alias,
        size: 3
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (site?.isError === 409) {
      return navigate(`${hrefLang}/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site.isError, hrefLang]);

  useEffect(() => {
    if (site?.data?.id && browserFingerprint) {
      ApiService.sendStatistics({
        siteId: site?.data?.id,
        type: "VISIT",
        fingerprint: browserFingerprint,
        fingerprintLibVersion
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias, browserFingerprint]);

  return (
    <>
      <ProductServiceMarkup />
      <section>
        <StyledContainer>
          {!site.isError ? (
            <>
              <StyledTitleContainer>
                <Title tag="h1" loading={isLoading}>
                  {current?.data?.h1 || site.data?.name}
                </Title>
              </StyledTitleContainer>
              <StyledContentBlock>
                <Info />
                <About />
                <Alternative />
              </StyledContentBlock>
            </>
          ) : (
            <StyledSiteNotFound>
              {t("proxySitePage.notFound")}
            </StyledSiteNotFound>
          )}
        </StyledContainer>
      </section>
    </>
  );
};

export default ServicePage;

ServicePage.getServerSideState = async (store, params) => {
  await getSiteState(store, params);
};
