import styled from "@emotion/styled";

export const StyledAboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    width: calc(100% - 844px);
    border-radius: 12px;
    padding: ${(p) => (p.active === "reviews" ? "32px" : "0")};

    background: ${(p) => p.theme.colors.white};
    box-shadow: ${(p) => p.theme.shadows.recentReviewCard};
  }
`;

export const StyledAboutContentTop = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;

  background: ${(p) => p.theme.colors.white};
  padding: 16px;
  border-radius: 12px;
  box-shadow: ${(p) => p.theme.shadows.recentReviewCard};

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    padding: ${(p) => (p.active === "reviews" ? "0" : "32px")};
    background: transparent;
    box-shadow: none;
  }

  & .ScrollbarsCustom-Content {
    @media screen and (min-width: ${({ theme }) => theme.screenSizes.xlMin}) {
      height: 70px;
    }
  }

  & .ScrollbarsCustom-TrackX {
    bottom: 4px !important;
    width: 100% !important;
  }
`;

export const StyledLabelOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: fit-content;
  & > svg {
    width: 20px;
    height: 20px;
  }
`;

export const StyledCaptchaBox = styled.div`
  margin: 0 !important;
  padding: 0 !important;
`;

export const StyledAboutSelectBox = styled.div`
  position: relative;
  z-index: 10;
  margin: 0 !important;
  width: 100%;
  & button {
    min-height: 48px;
  }
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    display: none;
  }
`;
