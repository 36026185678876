import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";
import { Button } from "../../../../components/ui/Button/Button";
import { Flag as BaseFlag } from "../../../../components/ui/Flag/Flag";
import { Icon } from "../../../../components/ui/Icon/Icon";

export const RestyledContainer = styled(Container)`
  padding-bottom: 64px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-bottom: 120px;
  }
`;

export const Inner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${(p) => p.theme.colors.white};
  z-index: 7;
  padding: 16px;
  border-radius: 12px;
  box-shadow: ${(p) => p.theme.shadows.card};
  margin-top: -45px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 36px;
    margin-top: -130px;
    flex-direction: row;
    min-height: 432px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-bottom: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
    margin-right: 36px;
  }
`;

export const IpWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background: ${(p) => p.theme.colors["background-color"]};
  padding: 12px;
  margin-bottom: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 24px;
    min-height: 120px;
  }
`;

export const IpBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const StyledTitle = styled.h3`
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 120%;
  width: auto;
  text-align: center;
  margin-bottom: 8px;
`;

export const StyledSubtitle = styled.p`
  text-align: center;
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
  margin-bottom: 24px;
`;

export const IpTitle = styled(StyledTitle)`
  margin-bottom: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
    margin-right: 12px;
  }
`;

export const IpAddressWrapper = styled.div`
  display: flex;
  align-items: center;

  > div {
    display: flex;
  }
`;

export const Flag = styled(BaseFlag)`
  margin-right: 12px;
  border-radius: 2px;
  transform: scale(1.3);
`;

export const IpAddress = styled.span`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 120%;

  ${(p) =>
    p.overflow &&
    `
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 240px;
  `}
`;

export const StyledCopyIcon = styled(Icon)`
  color: ${(p) => p.theme.colors["neutral-400"]};
  margin-left: 8px;
  margin-bottom: 2px;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover,
  &:focus {
    color: ${(p) => p.theme.colors["main-700"]};
    transition: color 0.3s ease;
  }
`;

export const Country = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.l};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 165%;

  & > svg {
    color: ${(p) => p.theme.colors["neutral-400"]};
    margin-right: 8px;
  }
`;

export const RestyledButton = styled(Button)`
  max-width: 313px;
`;

export const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(p) => (p.isLoading ? "center" : "flex-start")};
  justify-content: ${(p) => (p.isLoading ? "center" : "flex-start")};

  width: 100%;
  max-width: 100%;
  min-height: 324px;
  height: 100%;
  border-radius: 12px;
  background: ${(p) => p.theme.colors["background-color"]};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    min-height: 360px;
    max-width: 540px;
  }
`;

export const ResultsBox = styled.div``;
