import {
  BoxCell,
  CustomExpandCell,
  HeaderNameCell,
  SiteNameCell,
  TextCell
} from "../../../../../components/common/TableColumns";
import { YesNo } from "../../../../../components/common/YesNo/YesNo";
import { Rating } from "../../../../../components/ui/Raiting/Rating";
import { TextButton } from "../../../../../components/ui/TextButton/TextButton";
import {
  sortMinPriceColumn,
  sortMinRentColumn,
  time
} from "../../../../../utils/helpers";

export const genColumns = (queryLang, hrefLang, t, handleExpand) => [
  {
    name: (
      <HeaderNameCell minWidth={240}>
        {t("proxyPage.content.table.name.name")}
      </HeaderNameCell>
    ),
    cell: (row) => (
      <BoxCell minWidth={240}>
        <SiteNameCell
          imgUrl={row?.imageFile}
          favicon={row?.favicon}
          faviconContentType={row?.faviconContentType}
          name={row?.name}
          rating={row?.rating}
          showAnnotation
          positive={row?.ratingPositive}
          negative={row?.ratingNegative}
          showReviewButton={row?.showReviewButton}
          reviewButtonUrl={row?.reviewButtonUrl}
          // linkToReview={`${hrefLang}/proxy/${row.alias}/#reviews`}
        />
      </BoxCell>
    ),
    width: "240px"
  },
  {
    name: (
      <HeaderNameCell minWidth={100} sortable>
        {t("proxyPage.content.table.rating.name")}
      </HeaderNameCell>
    ),

    cell: (row) => (
      <BoxCell minWidth={100}>
        <Rating rating={row?.rating} size="md" />
      </BoxCell>
    ),
    sortable: true,
    selector: (row) => row?.rating
  },

  {
    name: (
      <HeaderNameCell width={100} sortable>
        {t("proxyPage.content.table.minPrice.title")}
      </HeaderNameCell>
    ),
    cell: ({ currentTypeProp }) => (
      <BoxCell width={100}>
        <TextCell>
          {!currentTypeProp ||
          (!currentTypeProp?.minPrice && currentTypeProp?.minPrice !== 0)
            ? ""
            : `$${currentTypeProp?.minPrice}`}
        </TextCell>
      </BoxCell>
    ),
    sortable: true,
    selector: (row) => row?.currentTypeProp?.minPrice,
    sortFunction: (rowA, rowB) => sortMinPriceColumn(rowA, rowB)
  },
  {
    name: (
      <HeaderNameCell width={110} sortable>
        {t("proxyPage.content.table.minRent.title")}
      </HeaderNameCell>
    ),
    cell: ({ currentTypeProp }) => (
      <BoxCell width={110}>
        <TextCell>
          {!currentTypeProp || !currentTypeProp?.minRentPeriod
            ? ""
            : time(
                currentTypeProp?.minRentPeriod?.periodName,
                currentTypeProp?.minRentPeriod?.periodValue,
                queryLang,
                t("times", { returnObjects: true })
              )}
        </TextCell>
      </BoxCell>
    ),
    sortable: true,
    // selector: (row) =>
    //   row?.currentTypeProp?.minRentPeriod?.periodMilliseconds,
    sortFunction: (rowA, rowB) => sortMinRentColumn(rowA, rowB)
    // width: "110px"
  },
  {
    name: (
      <HeaderNameCell width={94}>
        {t("proxyPage.content.table.freeTest.title")}
      </HeaderNameCell>
    ),
    cell: (row) => (
      <BoxCell width={94}>
        <YesNo value={row?.freeTest} />
      </BoxCell>
    )

    // width: "94px"
  },
  {
    name: (
      <HeaderNameCell width={105}>
        {t("proxyPage.content.table.site.title")}
      </HeaderNameCell>
    ),
    cell: (row) => (
      <BoxCell width={105}>
        <TextButton
          size="sm"
          color="table"
          iconRight="arrowAltRight"
          linkTo={`${hrefLang}/proxy/${row.alias}/`}
        >
          {t("proxyPage.content.table.site.btn")}
        </TextButton>
      </BoxCell>
    )
    // width: "105px"
  },
  {
    cell: (row) => (
      <BoxCell width={44}>
        <CustomExpandCell
          id={`expand-${row.id}`}
          open={row?.expanded}
          onClick={handleExpand}
        />
      </BoxCell>
    ),
    width: "44px"
  }
];
