import { useTranslation } from "react-i18next";

import { FormCheckbox } from "../../../../components/forms/FormCheckbox/FormCheckbox";

import {
  StyledAgreeButton,
  StyledAgreeLabel,
  StyledAuthFormComponentBox
} from "./AgreePolicy.styled";

export const AgreePolicy = () => {
  // **Redux state
  const { t } = useTranslation();
  const handleOpenPolicy = () => null;

  return (
    <StyledAuthFormComponentBox>
      <FormCheckbox
        name="agree"
        showError
        label={
          <StyledAgreeLabel>
            {`${t("authPages.agree.with")} `}
            <StyledAgreeButton
              onClick={handleOpenPolicy}
              type="link"
              dataTestId="regForm_buttonOffer"
            >
              {t("authPages.agree.offer")}
            </StyledAgreeButton>
            {` ${t("authPages.agree.and")} `}
            <StyledAgreeButton
              onClick={handleOpenPolicy}
              type="link"
              dataTestId="regForm_buttonPrivacyPolicy"
            >
              {`  ${t("authPages.agree.privacy")}`}
            </StyledAgreeButton>
            .
          </StyledAgreeLabel>
        }
        noActionLabel
        dataTestId="regForm_checkboxAgree"
      />
    </StyledAuthFormComponentBox>
  );
};
