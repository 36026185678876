import styled from "@emotion/styled";

import { Main } from "../../components/common/Main/Main";

export const RestyledMainSection = styled(Main)`
  padding-bottom: 70px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-bottom: 0;
    // min-height: 450px;
  }

  // @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
  //   min-height: 600px;
  // }

  & .main-content-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      padding-top: 105px;
      padding-bottom: 141px;
    }
  }

  & .main-image-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      top: 30px;
      width: 478px;
      height: 302px;
    }

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      top: 47px;
      width: 598px;
      height: 376px;
      right: -50px;
    }

    // @media (min-width: 1530px) {
    //   right: 7vw;
    // }

    &::before {
      width: 236px;
      height: 236px;
      bottom: -20px;
    }

    img {
      margin-top: -20px;
    }
  }
`;
