import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getAllBlogInner } from "../../../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";
import { scrollToWithOffset } from "../../../../../../utils/helpers/scroll.helper";

import {
  StyledBody,
  StyledBodyWrapper,
  StyledSummary,
  StyledSummaryItem,
  StyledSummaryTitle
} from "./Body.styled";

export const Body = () => {
  const { content } = useSelector(getAllBlogInner);
  const [activeSection, setActiveSection] = useState(null);

  const { t } = useTranslation();

  const observer = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleSection = entries.find(
          (entry) => entry.isIntersecting
        )?.target;
        if (visibleSection) {
          setActiveSection(visibleSection.id);
        }
      },
      {
        rootMargin: "-50%"
      }
    );

    const sections = document.querySelectorAll("[data-section]");

    sections.forEach((section) => {
      observer.current.observe(section);
    });
    return () => {
      sections.forEach((section) => {
        observer.current.unobserve(section);
      });
    };
  }, [content]);

  useEffect(() => {
    if (content?.data?.anchors?.length && !activeSection) {
      setActiveSection(content.data.anchors[0]?.anchor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const handleAnchorClick = (item) => {
    scrollToWithOffset(item.anchor, -50);
  };

  return (
    <StyledBodyWrapper>
      <StyledBody
        dangerouslySetInnerHTML={{ __html: content.data?.articleBody }}
        isSummary={content?.data?.anchors?.length}
      />
      {content?.data?.anchors?.length ? (
        <StyledSummary>
          <StyledSummaryTitle>
            {t("blogInnerPage.articleBody.inThisArticle")}
          </StyledSummaryTitle>
          <ul>
            {content.data?.anchors?.map((item) => (
              <StyledSummaryItem
                isActive={activeSection === item.anchor}
                key={item.id}
                id={item.title}
                onClick={() => handleAnchorClick(item)}
              >
                {item.title}
              </StyledSummaryItem>
            ))}
          </ul>
        </StyledSummary>
      ) : null}
    </StyledBodyWrapper>
  );
};
