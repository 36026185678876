export const FONTS_LINK = [
  {
    rel: "preload",
    href: "/fonts/ProximaNova-Bold.woff2",
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  },
  {
    rel: "preload",
    href: "/fonts/ProximaNova-Regular.woff2",
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  },
  {
    rel: "preload",
    href: "/fonts/Lato-Bold.woff2",
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  },
  {
    rel: "preload",
    href: "/fonts/Lato-Regular.woff2",
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  }
];
