import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";

import { useLangUrlDefault } from "../../../../hooks";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { nextYearValue } from "../../../../utils/helpers/productMarkup.helpers";

export const ProductServiceMarkup = () => {
  const [queryLang] = useLangUrlDefault();

  const { routeContent } = useSelector(getAllContent);
  const { site, reviews } = useSelector(getAllProxySite);

  const homeURL = process.env.REACT_APP_URL;

  const cleanDescription = (description) => {
    const regex = /(<([^>]+)>)/;
    if (regex.test(description)) {
      return description?.replace(/(<([^>]+)>)/gi, "");
    } else {
      return description || "";
    }
  };

  const proxyReviews = reviews.data?.content?.map(
    (item) =>
      `{
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": "${item.userName}"
        },
        "datePublished": "${item.creationDate}",
        "reviewBody": "${item.translation?.[queryLang]?.reviewBody}",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": ${item.rating},
          "bestRating": "5",
          "worstRating": "1"
        }
      }`
  );

  return (
    <Helmet>
      <script type="application/ld+json">
        {`
                {
                "@context": "http://schema.org",
                "@type": "Product",
                "image": [
                  "${homeURL}${site?.data?.imageFile}"
                ],
                "name": "Proxy Server ${site.data?.name}",
                "description": "${cleanDescription(
                  site.data?.description?.[queryLang]
                )}",
                "brand": {
                  "@type": "Brand",
                  "name": "Trusty Tech"
                },
                "sku": "0000000008",
                "mpn": "0000010",
                "aggregateRating":{
                  "@type":"AggregateRating",
                  "ratingValue": ${site.data?.rating},
                  "reviewCount": ${
                    site?.data?.reviewRatingNegative +
                    site?.data?.reviewRatingPositive
                  },
                  "bestRating": 5
                },
                "offers": {
                  "@type": "Offer",
                  "availability": "https://schema.org/InStock",
                  "url": "${homeURL}${routeContent?.location}",
                  "price": "${site.data?.price}",
                  "priceCurrency": "USD",
                  "priceValidUntil": "${nextYearValue()}",
                  "itemCondition": "https://schema.org/NewCondition"
                },
                "review": [
                 ${proxyReviews}
                ]
              }
            `}
      </script>
    </Helmet>
  );
};
