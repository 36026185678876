import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useLangUrlDefault } from "../../../../hooks";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { Promocode } from "../../Promocodes/Promocode";

import {
  StyledPromosBox,
  StyledPromosContainer,
  StyledPromosList,
  StyledPromosTitle
} from "./Promocodes.styled";

export const Promocodes = () => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  const filterPromocodes = (array) => {
    if (!array || !array?.length) {
      return [];
    }

    return array.filter(
      (seller) => seller.promoCode && seller.promoCode?.length > 0
    );
  };
  // **Redux state
  const { sellers } = useSelector(getAllContent);

  const [checkedSellers, setCheckedSellers] = useState(
    filterPromocodes(sellers.data)
  );

  useEffect(() => {
    if (sellers.data?.length) {
      setCheckedSellers(filterPromocodes(sellers.data));
    }
  }, [sellers.data]);
  return (
    <StyledPromosContainer>
      <StyledPromosTitle>
        {t("proxyPage.sidebar.promocodes.title")}
      </StyledPromosTitle>
      <StyledPromosBox>
        <StyledPromosList>
          {sellers?.isDataLoaded
            ? checkedSellers.map((seller) => (
                <li key={seller.id}>
                  <Promocode
                    variant="gray"
                    promocode={seller?.promoCode}
                    description={
                      seller.promoCodeDescription?.[queryLang]
                        ? seller.promoCodeDescription[queryLang]
                        : t("proxyPage.sidebar.right.sellers.promocode")
                    }
                    title={seller.name}
                    image={seller.image}
                    favicon={seller.favicon}
                    faviconContentType={seller.faviconContentType}
                    time={seller.endDate}
                    eternal={seller.eternal}
                    siteId={seller?.id}
                  />
                </li>
              ))
            : Array.from({ length: 5 }).map((_, index) => (
                <li key={index}>
                  <Promocode variant="gray" loading />
                </li>
              ))}
        </StyledPromosList>
      </StyledPromosBox>
    </StyledPromosContainer>
  );
};
