import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ProxyTabFilter } from "../../../../components/common/ProxyTabFilter/ProxyTabFilter";
import { Sidebar } from "../../../../components/common/Sidebar/Sidebar";
import { TextButton } from "../../../../components/ui/TextButton/TextButton";
import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { PROXY_FILTERS } from "../../../../utils/constants/filter.constant";
import { getCountryId, getGoalId, isEqual } from "../../../../utils/helpers";

import {
  ProxyCard,
  ProxyContainer,
  ProxyContentContainer,
  ProxyViewMoreBox
} from "./Proxy.styled";
import { ProxyContentTop } from "./ProxyContentTop/ProxyContentTop";
import { ProxyTable } from "./ProxyTable/ProxyTable";

export const Proxy = () => {
  // **Props
  const [searchParams] = useSearchParams();

  const [, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { countries, goals } = useSelector(getAllContent);
  const { proxySites } = useSelector(getAllProxySite);
  const { t } = useTranslation();
  const { getProxySites } = useDispatchedActions();

  // **Local state
  const [checkFetchParams, setCheckFetchParams] = useState(
    proxySites.fetchParams
  );

  useEffect(() => {
    const currentFilterParams = {
      page: 0,
      country: getCountryId(countries.data, searchParams.get("fc"))?.id || "",
      sort: searchParams.get("filter") || PROXY_FILTERS.reviews,
      goal: getGoalId(goals.data, searchParams.get("fg"))?.id || "",
      proxyType: searchParams.get("fpt") ? [searchParams.get("fpt")] : ["IPv4"],
      useNew: true,
      top: false,
      size: 10
    };
    const isEqualFilter = isEqual(checkFetchParams, currentFilterParams);

    if (!isEqualFilter) {
      setCheckFetchParams(currentFilterParams);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, countries, goals]);

  useEffect(() => {
    const isEqualFilter = isEqual(proxySites?.fetchParams, checkFetchParams);
    if (!isEqualFilter) {
      getProxySites(checkFetchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkFetchParams]);

  useEffect(() => {
    if (proxySites.isError) {
      toast.error(t("notifications.apiError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxySites.isError]);

  return (
    <section>
      <ProxyContainer>
        <ProxyContentTop />
        <ProxyContentContainer id="proxyContent">
          <ProxyCard shadow>
            <ProxyTabFilter />
            <ProxyTable />
            {proxySites?.isDataLoaded && !!proxySites?.content?.length && (
              <ProxyViewMoreBox>
                <TextButton
                  iconRight="arrowAltRight"
                  color="secondary"
                  size="md"
                  linkTo={`${hrefLang}/proxy/${
                    searchParams.get("fpt")
                      ? `?fpt=${searchParams.get("fpt")}`
                      : ""
                  }`}
                  // onClick={handleSetRowsPerPage}
                >
                  {t("buttons.viewMore")}
                </TextButton>
              </ProxyViewMoreBox>
            )}
          </ProxyCard>
          <Sidebar />
        </ProxyContentContainer>
      </ProxyContainer>
    </section>
  );
};
