import { Autoplay, Scrollbar } from "swiper/modules";

import { animationPortScannerStep } from "../../../../../assets/animations";
import {
  Swiper,
  SwiperSlide
} from "../../../../../components/ui/Swiper/Swiper.styled";
import { useSeoBlock } from "../../../../../hooks";
import { parseSeoData } from "../../../../../utils/helpers";

import { PortScannerStep } from "./PortScannerStep/PortScannerStep";
import {
  StyledAnimation,
  StyledAnimationWrapper,
  StyledContainer,
  StyledSection
} from "./PortScannerSteps.styled";

export const PortScannerSteps = () => {
  const { data } = useSeoBlock(["toolsPortScanerHowToUse"]);

  if (!data?.toolsPortScanerHowToUse?.showOnFront) {
    return null;
  }

  const list = parseSeoData(data?.toolsPortScanerHowToUse?.list, "JSON") || [];

  return (
    <StyledSection>
      <StyledContainer>
        <Swiper
          className="port-scanner-steps"
          direction="horizontal"
          modules={[Scrollbar, Autoplay]}
          spaceBetween={30}
          grabCursor={true}
          rewind={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false
          }}
          scrollbar={{
            hide: false
          }}
          wrapperTag="ul"
        >
          {list?.length > 0
            ? list.map((item, index) => (
                <SwiperSlide key={index} tag="li">
                  <PortScannerStep
                    title={parseSeoData(data?.toolsPortScanerHowToUse?.title)}
                    index={index + 1}
                    {...item}
                  />
                </SwiperSlide>
              ))
            : null}
        </Swiper>
        <StyledAnimationWrapper>
          <StyledAnimation
            src={animationPortScannerStep}
            autoPlay
            stateMachines="State Machine 1"
          />
        </StyledAnimationWrapper>
      </StyledContainer>
    </StyledSection>
  );
};
