import { useEffect, useId, useRef, useState } from "react";

import PT from "prop-types";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";

import { Icon } from "../../../ui/Icon/Icon";
import { StyledSkeleton } from "../../../ui/Skeleton/components/SkeletonTableComponents.styled";
import { StyledTooltip } from "../../../ui/Tooltip/Tooltip.styled";

import {
  StyledPromoImage,
  StyledPromoTitle,
  StyledPromoTitleBox,
  StyledPromoTitleInfoBox,
  StyledPromoTitleTime,
  StyledPromoTitleWrapper
} from "./PromoTitle.styled";

export const PromoTitle = ({
  variant,
  title,
  image,
  time,
  eternal = false,
  loading
}) => {
  const { t } = useTranslation();
  const id = useId();
  const ref = useRef(null);
  const [needTooltip, setNeedTooltip] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setNeedTooltip(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, [ref]);

  return (
    <StyledPromoTitleBox variant={variant}>
      {variant !== "dark" && !loading && (
        <StyledPromoImage
          variant={variant}
          src={image ? image : "/img/ui/placeholder.svg"}
          loading="lazy"
          alt=""
          aria-hidden="true"
        />
      )}
      {variant !== "dark" && loading && (
        <StyledSkeleton
          height={variant === "gray" ? 24 : 36}
          width={variant === "gray" ? 24 : 36}
          style={{ marginRight: variant === "gray" ? "4px" : "8px" }}
        />
      )}
      <StyledPromoTitleInfoBox variant={variant}>
        {loading ? (
          <StyledSkeleton height={14} width={80} />
        ) : (
          <>
            <StyledPromoTitleWrapper>
              <StyledPromoTitle
                ref={ref}
                data-tooltip-id={id}
                variant={variant}
              >
                {title}
              </StyledPromoTitle>
            </StyledPromoTitleWrapper>
            {needTooltip && (
              <StyledTooltip id={id} place="top">
                {title}
              </StyledTooltip>
            )}
          </>
        )}
        {loading ? (
          <StyledSkeleton height={14} width={60} />
        ) : (
          <StyledPromoTitleTime variant={variant} suppressHydrationWarning>
            {eternal || time ? (
              <>
                {["dark", "gray"].includes(variant) ? (
                  <Icon name="hourglass" />
                ) : (
                  `${eternal ? "" : t("promocodes.valid")} `
                )}
                {time && !eternal ? (
                  <Moment
                    date={new Date(time).toISOString()}
                    format="DD/MM/YYYY"
                    suppressHydrationWarning
                  />
                ) : null}
                {eternal ? t("promocodes.eternal.name") : null}
              </>
            ) : null}
          </StyledPromoTitleTime>
        )}
      </StyledPromoTitleInfoBox>
    </StyledPromoTitleBox>
  );
};

PromoTitle.propTypes = {
  title: PT.string,
  image: PT.string,
  time: PT.oneOfType([PT.string, PT.number]),
  eternal: PT.bool
};
