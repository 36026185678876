import { useTranslation } from "react-i18next";

import animation from "../../../assets/animations/free_proxy.riv";
import animationFaq from "../../../assets/animations/how_we_can_help_you.riv";
import {
  fingerprintFaqImage,
  fingerprintFaqImage2x,
  freeProxyHeroImageMobile,
  freeProxyHeroImageMobile2x
} from "../../../assets/img";
import { SeoSection } from "../../../components/common/SeoSection/SeoSection";
import { Rive } from "../../../components/ui/Rive/Rive";

import { FreeProxyInfo } from "./FreeProxyInfo/FreeProxyInfo";
import { FreeProxyList } from "./FreeProxyList/FreeProxyList";
import { getFreeProxyPageState } from "./FreeProxyPage.state";
import {
  FreeProxySection,
  RestyledFAQ,
  RestyledMainSection
} from "./FreeProxyPage.styled";

const FreeProxyPage = () => {
  const { t } = useTranslation();

  return (
    <FreeProxySection>
      <RestyledMainSection
        button={false}
        images={{
          mobile1x: freeProxyHeroImageMobile,
          mobile2x: freeProxyHeroImageMobile2x
        }}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-45}
            right={-1}
            bottom={-12}
            left={-83}
          />
        }
      />
      <FreeProxyList />
      <FreeProxyInfo />
      <RestyledFAQ
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        animation={animationFaq}
      />
      <SeoSection />
    </FreeProxySection>
  );
};

export default FreeProxyPage;

FreeProxyPage.getServerSideState = async (store, params) => {
  await getFreeProxyPageState(store, params);
};
