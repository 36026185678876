import { createSlice } from "@reduxjs/toolkit";

import { siteInitialState } from "./Site.initialState";
import {
  setBurgerMenuReducer,
  setLocationOpenedReducer,
  setMobileMenuReducer,
  setPreviousLangReducer,
  setSearchCountryReducer,
  setSelectedLocationReducer,
  setServiceMenuReducer,
  setServicesMenuStepReducer,
  setServicesOpenedReducer
} from "./Site.reducer";
import {
  setBurgerMenu,
  setLocationOpened,
  setMobileMenu,
  setPreviousLang,
  setSearchCountry,
  setSelectedLocation,
  setServiceMenu,
  setServicesMenuStep,
  setServicesOpened
} from "./Site.thunks";

const SiteSlice = createSlice({
  name: "site",
  initialState: siteInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(setMobileMenu, setMobileMenuReducer)
      .addCase(setBurgerMenu, setBurgerMenuReducer)
      .addCase(setServiceMenu, setServiceMenuReducer)
      .addCase(setPreviousLang, setPreviousLangReducer)
      .addCase(setLocationOpened, setLocationOpenedReducer)
      .addCase(setSelectedLocation, setSelectedLocationReducer)
      .addCase(setServicesMenuStep, setServicesMenuStepReducer)
      .addCase(setServicesOpened, setServicesOpenedReducer)
      .addCase(setSearchCountry, setSearchCountryReducer)
});

export const SiteReducerActions = {
  setMobileMenu,
  setBurgerMenu,
  setServiceMenu,
  setPreviousLang,
  setLocationOpened,
  setSelectedLocation,
  setServicesMenuStep,
  setServicesOpened,
  setSearchCountry
};
export default SiteSlice.reducer;
