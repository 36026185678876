import {
  BoxCell,
  CountryNameCell,
  HeaderNameCell,
  IndexCell,
  TextCell
} from "../../../../../components/common/TableColumns";
import { TextButton } from "../../../../../components/ui/TextButton/TextButton";

export const genColumns = (queryLang, hrefLang, t) => [
  {
    name: (
      <HeaderNameCell minWidth={50} center>
        №
      </HeaderNameCell>
    ),
    cell: (row, index) => {
      const currentNumber = index + 1;
      return (
        <BoxCell width={50}>
          <IndexCell>
            {`${currentNumber < 10 ? "0" : ""}${currentNumber}.`}
          </IndexCell>
        </BoxCell>
      );
    },
    width: "50px"
    // center: true
  },
  {
    name: (
      <HeaderNameCell width={175}>
        {t("mainPage.userChoice.columns.name")}
      </HeaderNameCell>
    ),
    cell: (row) => (
      <BoxCell width={175}>
        <CountryNameCell
          name={row?.countryLocalization?.[queryLang]}
          code={row?.countryCode}
        />
      </BoxCell>
    ),
    width: "175px"
  },

  {
    name: (
      <HeaderNameCell minWidth={95}>
        {t("mainPage.userChoice.columns.price")}
      </HeaderNameCell>
    ),
    cell: (row) => (
      <BoxCell minWidth={95}>
        <TextCell>{`${row?.price} USD`}</TextCell>
      </BoxCell>
    ),
    minwidth: "95px"
  },
  {
    name: (
      <HeaderNameCell minWidth={90}>
        {t("mainPage.userChoice.columns.offers")}
      </HeaderNameCell>
    ),
    cell: (row) => (
      <BoxCell minWidth={90}>
        <TextCell>{row?.offers}</TextCell>
      </BoxCell>
    ),
    minwidth: "90px"
  },
  {
    name: (
      <HeaderNameCell minWidth={80}>
        {t("mainPage.userChoice.columns.site")}
      </HeaderNameCell>
    ),
    cell: (row) => (
      <BoxCell minWidth={80}>
        <TextButton
          size="sm"
          color="table"
          iconRight="arrowAltRight"
          linkTo={`${hrefLang}/proxy/?fpt=IPv4${
            row?.countryUrlParam ? `&fc=${row?.countryUrlParam}` : ""
          }`}
        >
          {t("proxyPage.content.table.site.btn")}
        </TextButton>
      </BoxCell>
    ),
    minwidth: "80px"
  }
];
