import { useTranslation } from "react-i18next";

import { StyledSkeleton } from "../../../../../../../components/ui/Skeleton/components/SkeletonTableComponents.styled";

import { StyledBaseScore, StyledRating } from "./BaseScore.styled";

export const BaseScore = ({ score, loading }) => {
  const { t } = useTranslation();

  // const currentCountWords = (count) => {
  //   let words = "";
  //   if (count === 1) {
  //     words = t("proxySitePage.info.base.reviewCountOne");
  //   } else if (count > 1 && count < 5) {
  //     words = t("proxySitePage.info.base.reviewCountTwo");
  //   } else {
  //     words = t("proxySitePage.info.base.reviewCount");
  //   }
  //   return `${count} ${words}`;
  // };

  return (
    <StyledBaseScore>
      {!loading ? (
        <StyledRating
          rating={score}
          background={false}
          text={t("proxySitePage.about.reviews.basedOn")}
        />
      ) : (
        <StyledSkeleton width={150} height={14} />
      )}
    </StyledBaseScore>
  );
};
