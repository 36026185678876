import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { FormInput } from "../../../components/forms/FormInput/FormInput";
import { useLangUrlDefault } from "../../../hooks";
import { ApiService } from "../../../services";
import { AllActions } from "../../../store/reducers/AllActions";
import { FeedbackSchema } from "../../../utils/validation";
import {
  StyledAuthForm,
  StyledAuthTitle,
  StyledButtonSubmit,
  StyledSubTitle
} from "../AuthPages.styled";

const FeedbackPage = () => {
  const { t } = useTranslation();
  const [, hrefLang] = useLangUrlDefault();

  const navigate = useNavigate();

  // Form
  const methods = useForm({
    resolver: yupResolver(FeedbackSchema(t("forms", { returnObjects: true })))
  });

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    const params = {
      name: data.name.trim(),
      email: data.email.trim().toLowerCase(),
      message: data.message.trim()
    };
    try {
      setIsLoading(true);
      const response = await ApiService.createFeedback(params);

      if (response && response.status !== 200) {
        throw response;
      }

      // Reset form
      methods.reset();

      // Show success message
      navigate(`${hrefLang}/feedback/success`);
      // toast.success(t("notifications.feedback"));
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <StyledAuthTitle> {t("authPages.feedbackPage.title")}</StyledAuthTitle>
      <StyledSubTitle>{t("authPages.feedbackPage.subtitle")}</StyledSubTitle>
      <FormProvider {...methods}>
        <StyledAuthForm onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <FormInput
            name="name"
            type="text"
            placeholder={t("forms.name.label")}
            label={t("forms.name.label")}
            showError
          />
          <FormInput
            name="email"
            type="email"
            placeholder={t("forms.email.label")}
            label={t("forms.email.label")}
            showError
          />
          <FormInput
            name="message"
            type="textarea"
            placeholder={t("forms.message.placeholder")}
            label={t("forms.message.label")}
            showError
          />
          <StyledButtonSubmit
            type="submit"
            fullWidth
            disabled={isLoading}
            loading={isLoading}
          >
            {t("feedbackPage.form.btn")}
          </StyledButtonSubmit>
        </StyledAuthForm>
      </FormProvider>
    </>
  );
};

export default FeedbackPage;

FeedbackPage.getServerSideState = async (store, params) => {
  const { data: pageContent } = await ApiService.getPageContent({
    ...params,
    languageCode: params.locale
  });
  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setPageContent(pageContent));
};
