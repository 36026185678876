import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";
import { Pagination } from "../../../../components/ui/Pagination/Pagination";
import { Title } from "../../../../components/ui/Title/Title";

export const FreeProxyListContainer = styled(Container)`
  position: relative;
  z-index: 6;
  margin-top: -50px;
  margin-bottom: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-top: -90px;
  }
`;

export const InnerBlock = styled.div`
  padding: 24px 16px;
  border-radius: 12px;
  background: ${(p) => p.theme.colors.white};
  box-shadow: ${(p) => p.theme.shadows.card};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 36px;
  }
`;

export const RestyledTitle = styled(Title)`
  margin-bottom: 16px !important;
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 120%;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 36px !important;
    font-size: ${(p) => p.theme.fontSizes.xll};
    line-height: 120%;
  }
`;

export const ExportWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

export const ExportItems = styled.div`
  color: ${(p) => p.theme.colors["neutral-500"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 170%;
  height: 36px;
  margin-bottom: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
  }
`;

export const RestyledPagination = styled(Pagination)`
  padding: 16px 16px 16px 0;

  .items-on-page {
    display: none;
  }

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    justify-content: center;
    padding: 24px 0 0 0;

    .rows-info {
      display: none;
    }

    .items-on-page {
      display: flex;
      position: absolute;
      right: 0;
    }
  }
`;

export const MobileTableContainer = styled(Container)`
  padding-right: 0;
  padding-left: 15px;
  margin-bottom: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none;
  }
`;

export const TableWrapper = styled.div`
  display: ${(p) => (p.mobile ? "block" : "none")};
  min-height: ${(p) => (p.mobile ? "135px" : "0")};
  padding: 16px 0 0 16px;
  border-radius: 12px 0 0 12px;
  background: ${(p) => (p.mobile ? p.theme.colors.white : "taransparent")};
  box-shadow: ${(p) => (p.mobile ? p.theme.shadows.card : "none")};
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 0;
    display: ${(p) => (p.mobile ? "none" : "block")};
    min-height: ${(p) => (p.mobile ? "0" : "150px")};
  }
`;

export const InfoContainer = styled(Container)`
  width: 100%;
  overflow: hidden;
  height: 100%;
  padding-right: 0;
  padding-left: 15px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin: 0 auto;
    padding: 0 15px;
  }
`;

export const MessageInner = styled.div`
  @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
    padding-left: 16px;
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 12px;
    box-shadow: ${(p) => p.theme.shadows.card};
  }
`;
