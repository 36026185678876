import styled from "@emotion/styled";

import { FAQ } from "../../../components/common/FAQ/FAQ";
import { Main } from "../../../components/common/Main/Main";

export const MyAnonymitySection = styled.section`
  background-color: ${(p) => p.theme.colors["background-color"]};
`;

export const RestyledMainSection = styled(Main)`
  padding-bottom: 85px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    // padding-bottom: 0;
    // min-height: 624px;

    & .main-content-wrapper {
      max-width: 600px;
      padding-top: 105px;
      padding-bottom: 300px;
    }
  }

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    // min-height: 688px;
    & .main-content-wrapper {
      max-width: 660px;
    }
    & .main-description {
      width: 100%;
    }
  }

  & .main-image-wrapper {
    padding: 0 15px;
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      padding: 0;
      top: 52px;
      width: 401px;
      height: 280px;
      right: 30px;
    }

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      width: 501px;
      height: 351px;
      right: 15px;
    }

    @media (min-width: 1350px) {
      right: -25px;
    }

    &::before {
      width: 153px;
      height: 153px;
      bottom: 40%;
    }
  }
`;

export const RestyledFAQ = styled(FAQ)`
  img {
    width: 329px;
  }
`;
