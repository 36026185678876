import { useTranslation } from "react-i18next";

import { animationTestPort } from "../../../../../assets/animations";
// import {
//   portScannerCTAImageMobile,
//   portScannerCTAImageMobile2x
// } from "../../../../../assets/img";
import { Button } from "../../../../../components/ui/Button/Button";
import { useSeoBlock } from "../../../../../hooks";
import { parseSeoData } from "../../../../../utils/helpers";
import { scrollToWithOffset } from "../../../../../utils/helpers/scroll.helper";

import {
  StyledAnimation,
  StyledAnimationWrapper,
  StyledContainer,
  StyledContentWrapper,
  StyledDescription, // StyledImage,
  // StyledImageWrapper,
  StyledTitle
} from "./CTA.styled";

export const CTA = () => {
  const { t } = useTranslation();
  const { data } = useSeoBlock(["toolsPortScanerWhichPorts"]);

  if (!data?.toolsPortScanerWhichPorts?.showOnFront) {
    return null;
  }

  const handleClickButton = () => {
    scrollToWithOffset("portScannerTestPort", -60);
  };

  return (
    <StyledContainer>
      <StyledAnimationWrapper>
        <StyledAnimation
          src={animationTestPort}
          autoPlay
          stateMachines="State Machine 1"
        />
      </StyledAnimationWrapper>
      {/* <StyledImageWrapper>
        <picture>
          <source
            srcSet={`${portScannerCTAImageMobile} 1x, ${portScannerCTAImageMobile2x} 2x`}
          />
          <StyledImage
            src={portScannerCTAImageMobile}
            alt={t("portScanner.cta.title")}
          />
        </picture>
      </StyledImageWrapper> */}
      <StyledContentWrapper>
        <StyledTitle tag="h2">
          {parseSeoData(data?.toolsPortScanerWhichPorts?.title)}
        </StyledTitle>
        <StyledDescription>
          {parseSeoData(data?.toolsPortScanerWhichPorts?.description)}
        </StyledDescription>
        <Button onClick={handleClickButton}>
          {t("portScanner.cta.buttonLabel")}
        </Button>
      </StyledContentWrapper>
    </StyledContainer>
  );
};
