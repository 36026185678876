import { $api } from "../../../services/AxiosInstance";
import { AllActions } from "../../../store/reducers/AllActions";
import { getCountryId, getCityId } from "../../../utils/helpers";

export const getFreeProxyPageState = async (store, params) => {
  const {
    content: { countries }
  } = store.getState();

  const countryId = getCountryId(countries.data, params?.params?.country)?.id;

  const { data: cities } = await $api.get("/api/front/cities", {
    params: { countryId }
  });

  const cityId = getCityId(cities, params?.params?.city)?.id;

  const fetchParams = {
    page: 0,
    pageSize: 10,
    countryIds: countryId ? [countryId] : [],
    type: params?.params?.type || "",
    cityIds: cityId ? [cityId] : [],
    google:
      // eslint-disable-next-line no-nested-ternary
      params?.params?.google === "yes"
        ? "true"
        : params?.params?.google === "no"
        ? "false"
        : ""
  };

  const { data: tableData } = await $api.post(
    "/api/front/proxy/free",
    fetchParams
  );
  const { data: allData } = await $api.post("/api/front/proxy/free/all", {
    countryIds: fetchParams.countryIds,
    type: fetchParams.type,
    cityIds: fetchParams.cityIds,
    google: fetchParams.google
  });

  const { data: faqData } = await $api.post("/api/front/faqs", {
    pageTag: params.tag,
    languageCode: params.locale,
    params: params.params
  });

  const fetchSeoBlockParams = {
    languageCode: params.locale,
    keys: [
      "toolsFreeProxyList",
      "toolsFreeProxyHowToUse",
      "toolsFreeProxyDifference",
      "toolsFreeProxyHowDownload"
    ]
  };
  const { data: seoBlock } = await $api.post(
    "/api/front/seoBlock",
    fetchSeoBlockParams
  );
  if (seoBlock) {
    store.dispatch(
      AllActions.setSeoBlock({ data: seoBlock, params: fetchSeoBlockParams })
    );
  }

  if (tableData) {
    store.dispatch(AllActions.setFreeProxyContent(tableData));
  }
  if (allData) {
    store.dispatch(AllActions.setAllFreeProxyContent(allData));
  }
  if (faqData) {
    store.dispatch(
      AllActions.setFaqContent({
        data: faqData,
        pageTag: params.tag,
        languageCode: params.locale
      })
    );
  }
  if (cities) {
    store.dispatch(AllActions.setCities(cities));
  }
};
