import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Title } from "../../components/ui/Title/Title";
import { useDispatchedActions, useLangUrlDefault } from "../../hooks";
import { ApiService } from "../../services";
import { AllActions } from "../../store/reducers/AllActions";
import { getAllContent } from "../../store/reducers/ContentReducer/Content.selectors";

import {
  StyledContainer,
  StyledContent,
  StyledContentTitle,
  StyledContentWrapper
} from "./RequirementsPage.styled";
import { RequirementsTabs } from "./RequirementsTabs/RequirementsTabs";

const RequirementsPage = () => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();
  const [currentTab, setCurrentTab] = useState(null);

  const { requirements } = useSelector(getAllContent);
  const { getRequirements } = useDispatchedActions();

  useEffect(() => {
    if (!requirements.isDataLoaded) {
      getRequirements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requirementsData = requirements.data?.map((el) => ({
    id: el.name,
    title: el.localization?.[queryLang]?.title,
    content: el.localization?.[queryLang]?.body
  }));

  const getCurrentContent = (id) =>
    requirementsData?.find((item) => item.id === id);

  return (
    <section>
      <StyledContainer>
        <Title tag="h1">{t("requirementsPage.title")}</Title>
      </StyledContainer>
      <RequirementsTabs
        data={requirementsData}
        currentTab={currentTab}
        handleClickTab={setCurrentTab}
      />
      <StyledContentWrapper>
        <StyledContentTitle>
          {currentTab === null
            ? requirementsData?.[0]?.title
            : getCurrentContent(currentTab)?.title}
        </StyledContentTitle>
        <StyledContent
          dangerouslySetInnerHTML={{
            __html:
              currentTab === null
                ? requirementsData?.[0]?.content
                : getCurrentContent(currentTab)?.content
          }}
        />
      </StyledContentWrapper>
    </section>
  );
};

export default RequirementsPage;

RequirementsPage.getServerSideState = async (store) => {
  const { data } = await ApiService.getRequirements();

  if (data) {
    store.dispatch(AllActions.setRequirements(data));
  }
};
