import { createSlice } from "@reduxjs/toolkit";

import { blogInitialState } from "./Blog.initialState";
import {
  setBlogCategoriesReducer,
  setBlogContentReducer
} from "./Blog.reducer";
import {
  getBlogCategories,
  getBlogContent,
  setBlogCategories,
  setBlogContent
} from "./Blog.thunks";

const BlogSlice = createSlice({
  name: "blog",
  initialState: blogInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(setBlogContent, setBlogContentReducer)
      .addCase(setBlogCategories, setBlogCategoriesReducer)

      // getBlogContent
      .addCase(getBlogContent.pending, (state) => {
        state.content.isError = null;
        state.content.isLoading = true;
      })
      .addCase(getBlogContent.fulfilled, (state, action) => {
        state.content.isError = null;
        state.content.data = action.payload;
        state.content.isLoading = false;
        state.content.isDataLoaded = true;
      })
      .addCase(getBlogContent.rejected, (state, action) => {
        state.content.isError = action.payload;
        state.content.isLoading = false;
      })

      // getBlogCatgories
      .addCase(getBlogCategories.pending, (state) => {
        state.categories.isError = null;
        state.categories.isLoading = true;
      })
      .addCase(getBlogCategories.fulfilled, (state, action) => {
        state.categories.isError = null;
        state.categories.data = action.payload;
        state.categories.isLoading = false;
        state.categories.isDataLoaded = true;
      })
      .addCase(getBlogCategories.rejected, (state, action) => {
        state.categories.isError = action.payload;
        state.categories.isLoading = false;
      })
});

export const BlogReducerActions = {
  setBlogContent,
  getBlogContent,
  setBlogCategories,
  getBlogCategories
};
export default BlogSlice.reducer;
