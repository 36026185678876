export const contentInitialState = {
  statistic: {
    data: {},
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  pageContent: {
    data: {},
    current: {
      tag: "",
      data: {}
    },
    isLoading: false,
    isDataLoaded: false,
    isError: null
  },
  routeContent: {
    path: "/:lang/",
    tag: "home",
    isContainerFluid: false,
    isHeaderFull: false
  },
  countries: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  goalsByGroups: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  sellers: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  reviews: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  goals: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  topSites: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  currencies: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  rentPeriods: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  proxyTypes: {
    data: {},
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  proxyTypesMenu: {
    data: {},
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  activeFilters: {
    fpt: ["IPv4"],
    s: "reviews",
    fc: "",
    fg: ""
  },
  socials: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  captchaConfig: {
    data: {
      headerName: "",
      headerType: "",
      clientKey: "",
      siteKey: "",
      enable: false
    },
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  mainBlockList: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  seoDataDefault: {
    data: {
      siteTitle: "",
      siteDescription: "",
      globalReviewApproval: false
    },
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  userIp: {
    data: {},
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  activePageParams: {
    languageCode: "",
    location: "",
    tag: "",
    params: {},
    pathname: ""
  },
  userChoice: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  seoDataHtml: {
    data: "",
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  requirements: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  footerPages: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
};
