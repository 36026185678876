import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Progress } from "../../../../../components/common/Progress/Progress";
import { FormInput } from "../../../../../components/forms/FormInput/FormInput";
import { useUserIp } from "../../../../../hooks/useUserIp";
import { portsSelectOptions } from "../../../../../utils/constants/portsSelectOptions";

import {
  StyledAdditionalText,
  StyledButton,
  StyledContainer,
  StyledForm,
  StyledFormSelect,
  StyledInfoMessage,
  StyledInputWrapper,
  StyledResults,
  StyledResultsItem,
  StyledResultsList,
  StyledTitle
} from "./TestPort.styled";

export const TestPort = ({ result, methods, onSubmit, isLoading }) => {
  const { t } = useTranslation();

  const userIp = useUserIp();

  return (
    <StyledContainer id="portScannerTestPort">
      <StyledTitle marginTop="0" tag="h2">
        {t("portScanner.testPort")}
      </StyledTitle>
      <FormProvider {...methods}>
        <StyledForm onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <StyledInputWrapper>
            <FormInput
              name="ipAddress"
              type="text"
              placeholder={t("tracingIp.form.enterIP")}
              label={t("portScanner.form.inputLabel")}
              showError
              showClear
            />
            <StyledAdditionalText
              type="button"
              onClick={() => {
                methods.setValue("ipAddress", userIp?.ipAddress);
                methods.clearErrors();
              }}
            >
              {t("portScanner.form.pasteIP")}
            </StyledAdditionalText>
          </StyledInputWrapper>
          <StyledFormSelect
            name="type"
            id="type"
            placeholder="Popular ports"
            label={t("portScanner.form.selectLabel")}
            showError
            defaultValue="POPULAR"
            returnObject
            options={portsSelectOptions(t)}
          />
          <StyledButton
            type="submit"
            fullWidth
            loading={isLoading}
            iconLeft={isLoading ? "loading" : "scan"}
          >
            {isLoading
              ? t("portScanner.form.loading")
              : t("portScanner.form.scan")}
          </StyledButton>
        </StyledForm>
      </FormProvider>
      <StyledInfoMessage>
        <img src="img/icons/information.svg" alt="Info icon" />
        <span>{t("portScanner.form.infoMessage")}</span>
      </StyledInfoMessage>
      {result || isLoading ? (
        <>
          <StyledTitle tag="h2">{t("portScanner.checkResults")}</StyledTitle>
          <StyledResults isLoading={isLoading}>
            {isLoading ? (
              <Progress isLoading />
            ) : (
              <StyledResultsList>
                {result ? (
                  <>
                    <StyledResultsItem>
                      {t("portScanner.ipDomain")} {result?.ipOrDomain}
                    </StyledResultsItem>
                    <StyledResultsItem>
                      {t("portScanner.notShown")} {result.notShown}
                    </StyledResultsItem>
                    {result?.ports?.map((el, index) => (
                      <StyledResultsItem key={index}>
                        {el.port} -{" "}
                        {el.status
                          ? t("portScanner.open")
                          : t("portScanner.closed")}
                      </StyledResultsItem>
                    ))}
                  </>
                ) : null}
              </StyledResultsList>
            )}
          </StyledResults>
        </>
      ) : null}
    </StyledContainer>
  );
};
