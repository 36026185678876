/* eslint-disable no-console */
import { startTransition, useEffect, useState } from "react";

import { ApiService } from "../services";

import { useLangUrlDefault } from "./useLangUrlDefault";

export const useUserIp = () => {
  // **Props
  const [queryLang] = useLangUrlDefault();
  const [userIp, setUserIp] = useState(null);

  useEffect(() => {
    startTransition(() => {
      ApiService.getUserIp(queryLang)
        .then((response) => setUserIp(response.data))
        .catch((e) => {
          console.log(e);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang]);

  return userIp;
};
