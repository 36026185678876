import { useEffect } from "react";

import { useSelector } from "react-redux";

import { useDispatchedActions } from "../../../hooks";
import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";

import {
  CopyrightDesktop,
  CopyrightMobile,
  FooterInner,
  LogoWrapper,
  RestyledLanguages,
  RestyledLogo,
  StyledFooter
} from "./Footer.styled";
import { Navigation } from "./Navigation/Navigation";

export const Footer = () => {
  const year = new Date().getFullYear();
  const COPYRIGHT_TEXT = `TrustyTech©${year} | All Rights Reserved`;

  const { footerPages } = useSelector(getAllContent);
  const { getFooterPages } = useDispatchedActions();

  useEffect(() => {
    if (!footerPages.isDataLoaded) {
      getFooterPages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledFooter>
      <FooterInner>
        <LogoWrapper>
          <RestyledLogo />
          <RestyledLanguages isMobile />
          <CopyrightDesktop>{COPYRIGHT_TEXT}</CopyrightDesktop>
        </LogoWrapper>
        <Navigation data={footerPages.data} />
        <RestyledLanguages />
        <CopyrightMobile>{COPYRIGHT_TEXT}</CopyrightMobile>
      </FooterInner>
    </StyledFooter>
  );
};
