import "moment/locale/ru";
import "moment/locale/uk";
import PT from "prop-types";
import Moment from "react-moment";
import { Link } from "react-router-dom";

import { useLangUrlDefault } from "../../../../../hooks";
import { Category, Date } from "../../Blog.styled";

import { LatestItemTitle, LatestItemWrapper } from "./LatestItem.styled";

export const LatestItem = ({ data = {} }) => {
  const [queryLang, hrefLang] = useLangUrlDefault();

  return (
    <LatestItemWrapper>
      <Category isCategory={data.category}>
        {data.category?.localization?.[queryLang]}
      </Category>
      <Link to={`${hrefLang}/blog/${data.alias}/`}>
        <LatestItemTitle>{data.title}</LatestItemTitle>
      </Link>
      <Date suppressHydrationWarning>
        {data?.creationDate && (
          <Moment
            format="MMMM DD, YYYY"
            locale={queryLang === "ua" ? "uk" : queryLang}
          >
            {data?.creationDate}
          </Moment>
        )}
      </Date>
    </LatestItemWrapper>
  );
};

LatestItem.propTypes = {
  data: PT.shape({})
};
