import { Tab, Tabs, TabsWrapper } from "./RequirementsTabs.styled";

export const RequirementsTabs = ({ data, currentTab, handleClickTab }) => (
  <TabsWrapper>
    <Tabs>
      {data?.map((item, i) => (
        <Tab
          key={item.id}
          active={currentTab === null ? i === 0 : item.id === currentTab}
          onClick={() => handleClickTab(item.id)}
          title={item.title}
          role="button"
        >
          {item.title}
        </Tab>
      ))}
    </Tabs>
  </TabsWrapper>
);
