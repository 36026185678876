export const setSeoBlockReducer = (state, action) => {
  const {
    data,
    params: { keys, languageCode, params }
  } = action.payload;

  const newData = keys.reduce(
    (acc, key) => ({
      ...acc,
      ...(data.hasOwnProperty(key)
        ? {
            [key]: { ...data[key], showOnFront: true }
          }
        : { [key]: { showOnFront: false } })
    }),
    {}
  );

  state.data = {
    ...state.data,
    [languageCode]: {
      ...state.data[languageCode],
      ...newData
    }
  };

  state.loading = false;
  state.loadingKeys = state.loadingKeys.filter((key) => !keys.includes(key));
  state.proxyType = params?.fpt;
  state.country = params?.country;
};
