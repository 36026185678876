import styled from "@emotion/styled";

import { TextButton } from "../../../../components/ui/TextButton/TextButton";

export const StyledAgreeButton = styled(TextButton)`
  color: ${(p) => p.theme.colors["neutral-800"]};
  &:hover,
  &:focus {
    font-weight: 400;
  }
`;

export const StyledAgreeLabel = styled.p`
  color: ${(p) => p.theme.colors["neutral-500"]};
  word-break: break-all;
`;

export const StyledAuthFormComponentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
`;
