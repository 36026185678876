import { Suspense } from "react";

import { ThemeProvider } from "@emotion/react";
import "focus-visible";
import { createRoot, hydrateRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { withSSR } from "react-i18next";
import { Provider } from "react-redux";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  matchRoutes
} from "react-router-dom";

import { App } from "./App";
import ErrorBoundary from "./components/common/ErrorBoundary";
import { getRoutes } from "./routes/newRoutes";
import { $api } from "./services/AxiosInstance";
import { setupStore } from "./store/store";
import { LOCALS } from "./utils/constants/locals";
import { getCookie } from "./utils/helpers/cookie.helpers";
import { theme } from "./utils/theme";

import "./index.css";

const container = document.getElementById("root");

const ExtendedApp = withSSR()(App);

const getApp = async (initialState) => {
  const pathnameLang =
    Object.values(LOCALS).indexOf(window.location.pathname.slice(1, 3)) !== -1
      ? window.location.pathname.slice(1, 3)
      : "en";

  const initialLanguage = pathnameLang || getCookie("lang") || "en";
  const store = setupStore(initialState);
  // Determine if any of the initial routes are lazy

  const routes = createRoutesFromElements(
    getRoutes({
      lang: initialLanguage,
      store
    })
  );

  const lazyMatches = matchRoutes(routes, window.location)?.filter(
    (m) => m.route.lazy
  );

  // Load the lazy matches and update the routes before creating your router
  // so we can hydrate the SSR-rendered content synchronously
  if (lazyMatches && lazyMatches?.length > 0) {
    // eslint-disable-next-line no-undef
    await Promise.all(
      lazyMatches.map(async (m) => {
        const routeModule = await m.route.lazy();
        Object.assign(m.route, {
          ...routeModule,
          // eslint-disable-next-line no-undefined
          lazy: undefined
        });
      })
    );
  }

  const router = createBrowserRouter(routes);
  return {
    app: (
      <ErrorBoundary>
        <Provider store={store}>
          <HelmetProvider>
            <Suspense fallback={null}>
              <ThemeProvider theme={theme}>
                <ExtendedApp
                  initialLanguage={initialLanguage}
                  provider={RouterProvider}
                  routerProps={{ router }}
                />
              </ThemeProvider>
            </Suspense>
          </HelmetProvider>
        </Provider>
      </ErrorBoundary>
    )
  };
};

const optionRender = async (initialState = {}, isDevMode) => {
  const app = await getApp(initialState, isDevMode);
  if (container.hasChildNodes()) {
    return hydrateRoot(container, app.app, {
      identifierPrefix: "react-app-rating"
    });
  } else {
    const root = createRoot(container);

    return root.render(app.app);
  }
};

const initStore = async () => {
  try {
    const contentId = document.querySelector(
      "meta[name='ssr-content-id']"
    ).content;

    if (contentId) {
      const req = await $api.get(`/ssr/cache/${contentId}`);

      if (req.data.state) {
        return optionRender(req.data.state);
      }
    }

    return optionRender(window.__PRELOADED_STATE__?.state);
  } catch (e) {
    return optionRender(window.__PRELOADED_STATE__?.state);
  } finally {
    delete window.__PRELOADED_STATE__;
    document.getElementById("preloaded-state")?.remove();

    document.querySelector(".preload-ssr")?.remove();
  }
};

initStore();
