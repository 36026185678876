import styled from "@emotion/styled";

import { Container } from "../../../components/common/Container/Container";
import { Title } from "../../../components/ui/Title/Title";

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-top: 16px;
`;

export const Date = styled.span`
  color: ${(props) => props.theme.colors["neutral-400"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42;
  text-transform: uppercase;
`;

export const Category = styled.p`
  display: inline-flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${(props) =>
    props.isCategory ? "rgba(216, 218, 224, 0.5)" : "transparent"};
  color: ${(props) => props.theme.colors["neutral-400"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 12px;
`;

export const BlogItemWrapper = styled.li`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (min-width: 650px) {
    width: 45%;
    justify-content: stretch;
  }

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 307px;
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 16px;
  background: rgba(216, 218, 224, 0.5);

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    overflow: hidden;
    width: 442px;
    min-width: 442px;
    margin-right: 56px;
    margin-bottom: 0;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  a {
    font-size: 0;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    word-break: break-word;
  }
`;

export const StyledTitle = styled(Title)`
  @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
  }
`;

export const Description = styled.div`
  color: ${(props) => props.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
`;

export const BlogSection = styled.section`
  background-color: ${({ theme }) => theme.colors["background-color-seo"]};
`;

export const Heading = styled.h1`
  color: ${(props) => props.theme.colors["neutral-800"]};
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.18;
  padding-bottom: 3.2rem;
  padding-top: 4rem;
  min-height: 110px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.08;
    padding-bottom: 4.8rem;
    padding-top: 6.4rem;
    min-height: 165px;
  }
`;

export const BlogListWrapper = styled.ul`
  display: flex;
  flex-direction: column;

  @media (min-width: 650px) {
    flex-flow: row wrap;
    column-gap: 24px;
  }

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: flex;
    flex-flow: column nowrap;
  }
`;

export const BlogDarkBackground = styled.div`
  background-color: ${({ theme }) => theme.colors["background-color"]};
  padding-top: 40px;
  padding-bottom: 64px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-top: 72px;
    padding-bottom: 120px;
  }
`;

export const StyledBlogList = styled(Container)`
  min-height: 120px;

  & .blog-pagination {
    padding-top: 32px;
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      padding-top: 48px;
    }
  }
`;

export const StyledBlogListEmpty = styled.p`
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.colors["neutral-600"]};
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
`;
