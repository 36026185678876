import { useEffect, useState } from "react";

import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Select } from "../../../../../components/ui/Select/Select";
import { useDispatchedActions, useLangUrlDefault } from "../../../../../hooks";
import { getAllProxySite } from "../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getChartLabels } from "../../../../../utils/helpers";
import { TabTitle } from "../TabTitle/TabTitle";

import {
  StyledRatingContainer,
  StyledRatingFilter,
  StyledRatingFilterLabel,
  StyledRatingFilterSelectBox
} from "./Rating.styled";
import { RATING_CHART_OPTIONS } from "./ratingOptions";

Chart.register(...registerables);

export const Rating = ({ active }) => {
  // **Props
  const { alias } = useParams();
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  const options = [
    {
      label: t("proxySitePage.content.rating.filters.month"),
      value: "days"
    },
    {
      label: t("proxySitePage.content.rating.filters.quarter"),
      value: "quarter"
    },
    {
      label: t("proxySitePage.content.rating.filters.year"),
      value: "months"
    }
  ];

  // **Redux state
  const {
    rating: { data, isLoading }
  } = useSelector(getAllProxySite);
  // **Dispatch
  const { getProxySiteRating } = useDispatchedActions();
  // **Local state
  const [stepRating, setStepRating] = useState("days");

  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    let promise = null;
    if (data?.key !== `${alias}-${stepRating}`) {
      promise = getProxySiteRating({ siteId: alias, step: stepRating });
    }
    return () => promise?.abort?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias, stepRating]);

  useEffect(() => {
    setChartData({
      labels: getChartLabels(
        stepRating,
        t("proxySitePage.content.rating.labels", { returnObjects: true })
      ),
      datasets: [
        {
          label: t("proxySitePage.content.rating.label"),
          data: [...data?.content],
          fill: false,
          borderColor: "#C8EE85",
          pointStyle: "line"
        }
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.key, queryLang]);

  return (
    <StyledRatingContainer active={active}>
      <TabTitle
        title={t("proxySitePage.about.rating.title")}
        imgSrc="/img/site-page/Rating.png"
      />
      <StyledRatingFilter>
        <StyledRatingFilterLabel>
          {t("proxySitePage.about.rating.color")}
        </StyledRatingFilterLabel>
        <StyledRatingFilterSelectBox>
          <Select
            options={options}
            value={stepRating}
            onChange={setStepRating}
            disabled={isLoading}
            size="md"
          />
        </StyledRatingFilterSelectBox>
      </StyledRatingFilter>
      {!!chartData && <Line data={chartData} options={RATING_CHART_OPTIONS} />}
    </StyledRatingContainer>
  );
};
