import { toast } from "react-toastify";

import {
  ActionCell,
  HeaderNameCell,
  IsActiveCell,
  PeriodCell,
  SiteNameCell,
  TextCell
} from "../../../../components/common/TableColumns";
import { ApiService } from "../../../../services";

const handlerChangeActive = (id, fetchCabinetPromocodes, t) => {
  ApiService.trigerPromoSiteActivation(id)
    .then((response) => {
      if (response.status === 200) {
        toast.success(t("dashboard.promocodes.activated.success"));
        fetchCabinetPromocodes();
        return;
      }

      if (response?.status === 400) {
        if (response?.response?.data?.error === "PROMOCODE_STARTS_LATER") {
          toast.error(t("dashboard.promocodes.activated.startsLater"));
          return;
        }
        if (response?.response?.data?.error === "PROMOCODE_IS_EXPIRED") {
          toast.error(t("dashboard.promocodes.activated.isExpired"));
          return;
        }
      }
      if (
        response?.response?.status === 404 &&
        response?.response?.data?.error === "PROMOCODE_NOT_FOUND"
      ) {
        toast.error(t("dashboard.promocodes.activated.notFound"));
        return;
      }
      toast.error(t("notifications.apiError"));
    })
    .catch(() => {
      toast.error(t("notifications.apiError"));
    });
};

export const siteColumn = (siteIds, handleFilter, listSites, t) => ({
  name: (
    <HeaderNameCell
      showFilter
      filter={siteIds}
      setFilter={handleFilter}
      filterOption={listSites}
    >
      {t("dashboard.promocodes.table.columns.promocode")}
    </HeaderNameCell>
  ),
  width: "230px",
  cell: (row) => (
    <SiteNameCell
      imgUrl={row?.imageUrl}
      favicon={row?.favicon}
      faviconContentType={row?.faviconContentType}
      name={row?.siteName}
      showRating={false}
    />
  )
});

export const promocodeColumn = (t) => ({
  name: (
    <HeaderNameCell>
      {t("dashboard.promocodes.table.columns.promocode")}
    </HeaderNameCell>
  ),
  cell: (row) => <TextCell>{row?.promocode}</TextCell>
});

export const dateColumn = (t) => ({
  name: (
    <HeaderNameCell>
      {t("dashboard.promocodes.table.columns.date")}
    </HeaderNameCell>
  ),
  cell: (row) => (
    <PeriodCell
      eternal={row?.eternal}
      startDate={row?.startDate}
      endDate={row?.endDate}
    />
  ),
  sortable: true,
  selector: (row) => row?.startDate
});

export const activeColumn = (fetchCabinetPromocodes, t) => ({
  name: (
    <HeaderNameCell>
      {t("dashboard.promocodes.table.columns.active")}
    </HeaderNameCell>
  ),
  cell: (row) => (
    <IsActiveCell
      isActive={row?.isActive}
      promoId={row?.id}
      onChange={(id) => handlerChangeActive(id, fetchCabinetPromocodes, t)}
    />
  )
});

export const actionColumn = (hrefLang, openModalHandler, t) => ({
  width: "42px",
  cell: (row) => (
    <ActionCell
      id={row.id}
      actions={[
        {
          linkTo: `${hrefLang}/dashboard/promocodes/edit/${row.id}/`,
          icon: "edit",
          label: t("dashboard.promocodes.table.columns.actions.edit")
        },
        {
          onClick: () => openModalHandler(row.id),
          icon: "delete",
          label: t("dashboard.promocodes.table.columns.actions.delete"),
          variant: "danger"
        }
      ]}
    />
  )
});
