import styled from "@emotion/styled";

export const DnsTableWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.card};
  padding: 16px;
  margin-left: 15px;
  margin-right: 15px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    width: 100%;
    height: 390px;
    padding: 0;
    box-shadow: none;
    margin-left: 5px;
    margin-right: 0;
  }

  &::after {
    content: "";
    position: absolute;
    pointer-events: none;
    height: 58px;
    bottom: 28px;
    width: 94%;
    background: ${(p) =>
      p.scrollBottom
        ? "transparent"
        : "linear-gradient(348deg, #fff 18%, #ffffff00 102.73%)"};
    @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
      bottom: 18px;
      width: 100%;
    }

    @media (min-width: ${({ theme }) => theme.screenSizes.xlMin}) {
      bottom: 0;
      right: 6px;
    }
  }
`;

export const ResultTable = styled.div`
  position: relative;
  width: 697px;
  height: 390px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.white};
  padding-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    overflow-x: auto;
    width: 100%;
    padding: 0;
  }

  &::-webkit-scrollbar-button:end:increment {
    width: 1%;
    display: block;
    background: transparent;
  }

  &::-webkit-scrollbar-button:start:increment {
    width: 1%;
    display: block;
    background: transparent;
  }
`;

export const TableBox = styled.div`
  position: absolute;
  display: table;
  width: 100%;
`;

export const TableBody = styled.div`
  border: 0;
  max-height: 320px;
  color: ${({ theme }) => theme.colors["neutral-800"]};
  overflow-y: auto;
`;

export const TableHeadRow = styled.div`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors["background-color"]};
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 48px;
`;

export const TableHeadCol = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : "auto")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : props.width)};
  height: 100%;
  padding-left: 24px;
  align-items: center;
  color: ${({ theme }) => theme.colors["neutral-400"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42;
  text-transform: uppercase;
`;

export const TableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 48px;
  border-bottom: 1px solid rgba(216, 218, 224, 0.55);

  @media (max-width: ${(p) => p.theme.screenSizes.lgMin}) {
    border-bottom: none !important;

    &:nth-of-type(even) {
      background-color: rgba(246, 247, 249, 0.6);
    }
  }
`;

export const TableCol = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : "auto")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : props.width)};
  height: 100%;
  padding: 8px 0px 8px 24px;
  align-items: center;
  color: ${({ theme }) => theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
`;

export const ResultColumnText = styled.span`
  color: ${({ theme }) => theme.colors["neutral-800"]};
  font-size: ${({ theme }) => theme.fontSizes.m};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  line-height: 125%;
`;

export const ResultColumnCountry = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CountryFlag = styled.img`
  width: 22px;
  height: 16px;
  flex-shrink: 0;
`;
export const CountryName = styled.span`
  color: ${({ theme }) => theme.colors["neutral-800"]};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  line-height: 140%;
`;
