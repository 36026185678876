import { useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useDispatchedActions } from "../../../../../../hooks";
import { ModalConfirm } from "../../../../../../pages/Dashboard/components/ModalConfirm/ModalConfirm";
import { ApiService } from "../../../../../../services";
import { getAllUser } from "../../../../../../store/reducers/UserReducer/User.selectors";
import { LeaveReviewModal } from "../../../../Modals/LeaveReviewModal/LeaveReviewModal";
import { ActionCell } from "../../../../TableColumns/ActionCell/ActionCell";
import { StyledAnswers } from "../ReviewItem.styled";

import { Feedback } from "./Feedback/Feedback";
import { StyledReplyPart, StyledtextButton } from "./ReplyPart.styled";
import { Response } from "./Response/Response";

export const ReplyPart = ({
  captchaRef,
  setTokenCaptcha,
  review,
  changeHandler,
  toggleReview,
  isRootReview,
  isReviewsOpened,
  nestedResponseValue,
  parentToggleReview,
  isDashboard
}) => {
  // **Ref
  const responseRef = useRef(null);

  // **Redux state
  const { user, isUserAuthenticated } = useSelector(getAllUser);

  const { t } = useTranslation();

  const { clearProxySiteReviews } = useDispatchedActions();

  // **Local state
  const [maxHeight, setMaxHeight] = useState(null);
  const [modalDeleteId, setModalDeleteId] = useState(null);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const toggleAccordion = () => {
    parentToggleReview?.(true);
    if (!responseRef.current) {
      return;
    }

    const newMaxHeight = responseRef.current.scrollHeight + 1000;

    setMaxHeight(maxHeight ? null : newMaxHeight);
  };

  const handleModalEditOpen = () => {
    setModalEditOpen(!modalEditOpen);
  };

  const handleModalDeleteClose = () => setModalDeleteId(null);

  const confirmDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await ApiService.deleteSiteReviewNew(modalDeleteId);
      if (response && response.status !== 200) {
        throw response;
      }
      setModalDeleteId(null);
      clearProxySiteReviews();
      changeHandler?.();
      toast.success(t("dashboard.reviews.modals.delete.success"));
    } catch (error) {
      toast.error(t("dashboard.reviews.modals.delete.error"));
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <StyledReplyPart>
        <StyledtextButton
          color="secondary"
          iconLeft="reply"
          onClick={toggleAccordion}
        >
          {maxHeight ? t("reviews.reply.cancel") : t("reviews.reply.apply")}
        </StyledtextButton>
        <StyledtextButton
          color="primary"
          variant="answer"
          isActive={isReviewsOpened}
          onClick={toggleReview}
          disabled={review.responses?.length === 0}
        >
          {t("reviews.answers")} - {review.responses?.length}
        </StyledtextButton>
        {isRootReview && (
          <Feedback
            captchaRef={captchaRef}
            setTokenCaptcha={setTokenCaptcha}
            review={review}
            changeHandler={changeHandler}
          />
        )}
        {isRootReview &&
          isDashboard &&
          isUserAuthenticated &&
          review?.userId === user?.uid && (
            <ActionCell
              id={review.id}
              mlauto={false}
              vertical
              actions={[
                {
                  onClick: handleModalEditOpen,
                  icon: "edit",
                  label: t("dashboard.promocodes.table.columns.actions.edit")
                },
                {
                  onClick: () => {
                    setModalDeleteId(review.id);
                  },
                  icon: "delete",
                  label: t("dashboard.promocodes.table.columns.actions.delete"),
                  variant: "danger"
                }
              ]}
            />
          )}
      </StyledReplyPart>
      <StyledAnswers
        ref={responseRef}
        nestingLevel={nestedResponseValue}
        maxHeight={maxHeight}
      >
        <Response
          isDashboard={isDashboard}
          captchaRef={captchaRef}
          setTokenCaptcha={setTokenCaptcha}
          review={review}
          toggleAccordion={toggleAccordion}
          changeHandler={changeHandler}
          nestedResponseValue={nestedResponseValue}
        />
      </StyledAnswers>
      {isRootReview &&
        isDashboard &&
        isUserAuthenticated &&
        review?.userId === user?.uid && (
          <>
            <ModalConfirm
              open={!!modalDeleteId}
              close={handleModalDeleteClose}
              isDeleting={isDeleting}
              srcX1="/img/dashboard/delete-service-x1.png"
              srcX2="/img/dashboard/delete-service-x2.png"
              subtitle={t("dashboard.reviews.modals.delete.subtitle")}
              onConfirm={confirmDelete}
            />
            <LeaveReviewModal
              visible={modalEditOpen}
              isEdit
              reviewItem={review}
              cancelHandler={handleModalEditOpen}
              captchaRef={captchaRef}
              setTokenCaptcha={setTokenCaptcha}
              updateHandler={changeHandler}
            />
          </>
        )}
    </>
  );
};
