import { useEffect } from "react";

export const ExportPage = () => {
  const exportData = JSON.parse(localStorage.getItem("exportData"));

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      localStorage.removeItem("exportData");
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <main>
      <div
        dangerouslySetInnerHTML={{
          __html: exportData ? exportData : "No data"
        }}
      />
    </main>
  );
};
