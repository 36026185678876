import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { FormInput } from "../../../../components/forms/FormInput/FormInput";
import { Button } from "../../../../components/ui/Button/Button";
import { Separator } from "../../../../components/ui/Separator/Separator";
import { useLangUrlDefault } from "../../../../hooks";
import { ApiService } from "../../../../services";
import { ProfileChangePasswordSchema } from "../../../../utils/validation";
import { FormBlock } from "../../components/FormBlock/FormBlock";
import { ModalConfirmCode } from "../ModalConfirmCode/ModalConfirmCode";

import { StyledForm, StyledHelpText } from "./PasswordBlock.styled";

export const PasswordBlock = () => {
  const [queryLang] = useLangUrlDefault();

  const { t } = useTranslation();

  // const methods = useForm({ defaultValues: { email: user.email } });
  const methods = useForm({
    resolver: yupResolver(
      ProfileChangePasswordSchema(t("forms", { returnObjects: true }))
    )
  });
  // const { email } = methods.watch();

  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSave = async (data) => {
    setLoading(true);
    try {
      const res = await ApiService.changePassword({
        ...data,
        locale: queryLang
      });
      if (res.status === 200) {
        toast.success(t("dashboard.profile.confirmModal.successSend"));
        setModalOpen(true);
      }
      if (res.response?.status === 400) {
        if (res.response?.data?.error === "PASSWORD_DOESNT_MATCHES") {
          methods.setError("oldPassword", {
            type: "manual",
            message: t("dashboard.profile.confirmModal.invalidPassword")
          });
        }
      }
    } catch (error) {
      toast.error(t("notifications.serverError"));
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    methods.reset();
    methods.clearErrors();
  };

  useEffect(() => {
    if (methods && Object.keys(methods.formState.errors)?.length > 0) {
      methods.trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang]);
  return (
    <>
      <FormProvider {...methods}>
        <StyledForm onSubmit={methods.handleSubmit(handleSave)}>
          <FormBlock
            title={t("dashboard.profile.blockPassword.title")}
            subtitle={t("dashboard.profile.blockPassword.subtitle")}
          >
            <FormInput
              name="oldPassword"
              label={t("forms.password.labelCurrent")}
              type="password"
              placeholder={t("forms.password.placeholderCurrent")}
              showError
            />
            <FormInput
              name="newPassword"
              label={t("forms.password.labelNew")}
              type="password"
              placeholder={t("forms.password.newPassword")}
              showError
            />
            <StyledHelpText>
              {t("dashboard.profile.blockPassword.help")}
            </StyledHelpText>
          </FormBlock>
          <Separator />
          <FormBlock bodyType="buttons">
            <Button type="submit" loading={loading}>
              {t("forms.buttons.saveChanges")}
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={handleCancel}
              disabled={loading}
            >
              {t("forms.buttons.cancel")}
            </Button>
          </FormBlock>
        </StyledForm>
      </FormProvider>
      <ModalConfirmCode open={modalOpen} close={setModalOpen} isPass />
    </>
  );
};
