export const handleScrollBottomTable = (e, setScrollBottomTable) => {
  if (
    Math.trunc(e.target?.scrollHeight - e.target?.scrollTop) ===
    e.target?.clientHeight
  ) {
    setScrollBottomTable(true);
  } else {
    setScrollBottomTable(false);
  }
};
