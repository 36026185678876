import styled from "@emotion/styled";

import { Container } from "../../components/common/Container/Container";

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  padding-top: 40px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    align-items: flex-start;
    padding-top: 64px;
    padding-bottom: 48px;
  }
`;

export const StyledContentWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    align-items: flex-start;
    padding-bottom: 180px;
  }
`;

export const StyledContentTitle = styled.h2`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 24px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    font-size: ${(p) => p.theme.fontSizes.xll};
    font-weight: 700;
    line-height: 118%;
    margin-bottom: 32px;
  }
`;

export const StyledContent = styled.div`
  p {
    color: ${(p) => p.theme.colors["neutral-800"]};
    font-family: ${(p) => p.theme.fontFamily.lato};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 24px;

    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      font-size: 18px;
      line-height: 1.67;
    }
  }

  ol {
    list-style-type: decimal;
    list-style-position: outside;
    margin-left: 20px;
    margin-bottom: 24px;

    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      margin-left: 24px;
    }
  }

  li {
    color: ${(p) => p.theme.colors["neutral-800"]};
    font-family: ${(p) => p.theme.fontFamily.lato};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      font-size: 18px;
      line-height: 1.67;
    }
  }

  li:not(:last-child) {
    margin-bottom: 16px;
  }
`;
