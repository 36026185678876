import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { YesNo } from "../../../../../components/common/YesNo/YesNo";
import { Select } from "../../../../../components/ui/Select/Select";
import { StyledSkeleton } from "../../../../../components/ui/Skeleton/components/SkeletonTableComponents.styled";
import { useLangUrlDefault } from "../../../../../hooks";
import { getAllProxySite } from "../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { individualProxyTypes } from "../../../../../utils/constants/individualProxyTypes";
import { time } from "../../../../../utils/helpers";
import { InfoItemContainer, InfoItemTitle } from "../Info.styled";

import {
  InformationsContentItem,
  InformationsContentItemValue,
  InformationsContentList
} from "./Informations.styled";

export const Informations = ({ active }) => {
  const [queryLang] = useLangUrlDefault();
  // **Redux state
  const { t } = useTranslation();
  const {
    site: { data }
  } = useSelector(getAllProxySite);

  const [options, setOptions] = useState(
    data?.proxyTypeProps?.map((item) => ({
      label: item?.proxyTypeLocalization?.[queryLang] || item?.proxyType,
      value: item?.proxyTypeId
    })) || []
  );
  const [selected, setSelected] = useState(options[0]?.value || "");
  const [selectedData, setSelectedData] = useState(
    data?.proxyTypeProps[0] || {}
  );

  const handlerSelect = (value) => {
    setSelected(value);
    const newSelectedData = data?.proxyTypeProps.find(
      ({ proxyTypeId }) => proxyTypeId === value
    );
    setSelectedData(newSelectedData);
  };

  const currentPullIp = (value, translate) => {
    if (!value || !translate) {
      return null;
    }
    if (value >= 1000000) {
      return `${Math.round(value / 1000000)} ${translate(
        "proxySitePage.info.informations.million"
      )}`;
    }
    if (value >= 1000) {
      return `${Math.round(value / 1000)} ${translate(
        "proxySitePage.info.informations.thousand"
      )}`;
    }
    return value;
  };

  useEffect(() => {
    const options = data?.proxyTypeProps?.map((item) => ({
      label: item?.proxyTypeLocalization?.[queryLang] || item?.proxyType,
      value: item?.proxyTypeId
    }));

    if (!selected || !options.find((it) => it.value === selected)) {
      setSelected(options[0]?.value);
      setSelectedData(data?.proxyTypeProps[0]);
    }
    setOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, data]);

  return options?.length && selected ? (
    <InfoItemContainer active={active}>
      <InfoItemTitle>
        {`${t("proxySitePage.info.informations.title")} (${options.length})`}
      </InfoItemTitle>
      <Select
        id="informations"
        options={options}
        size="lg"
        value={selected}
        onChange={handlerSelect}
      />
      <InformationsContentList>
        {selectedData?.minRentPeriod?.id ? (
          <InformationsContentItem>
            <span>{t("proxySitePage.info.informations.minRent")}:</span>
            <InformationsContentItemValue>
              {time(
                selectedData?.minRentPeriod?.periodName,
                selectedData?.minRentPeriod?.periodValue,
                queryLang,
                t("times", { returnObjects: true })
              )}
            </InformationsContentItemValue>
          </InformationsContentItem>
        ) : null}
        {selectedData?.minPrice ? (
          <InformationsContentItem>
            <span>{t("proxySitePage.info.informations.minPrice")}:</span>
            <InformationsContentItemValue>
              ${selectedData?.minPrice}
            </InformationsContentItemValue>
          </InformationsContentItem>
        ) : null}
        {selectedData?.proxyType !== "ResidentialProxy" &&
        selectedData?.proxyType !== "FreeProxy" &&
        selectedData?.minIpAmount ? (
          <InformationsContentItem>
            <span>{t("proxySitePage.info.informations.minIP")}:</span>
            <InformationsContentItemValue>
              {selectedData?.minIpAmount}
            </InformationsContentItemValue>
          </InformationsContentItem>
        ) : null}
        {selectedData?.proxyType === "ResidentialProxy" ? (
          <>
            {!!selectedData?.pullIp && (
              <InformationsContentItem>
                <span>{t("proxySitePage.info.informations.pullIP")}:</span>
                <InformationsContentItemValue>
                  {currentPullIp(selectedData?.pullIp, t)}
                </InformationsContentItemValue>
              </InformationsContentItem>
            )}
            {!!selectedData?.minBytes && (
              <InformationsContentItem>
                <span>{t("proxySitePage.info.informations.minGB")}:</span>
                <InformationsContentItemValue>
                  {selectedData?.minBytes
                    ? Number(selectedData?.minBytes) / 1073741824
                    : null}
                </InformationsContentItemValue>
              </InformationsContentItem>
            )}
          </>
        ) : null}
        {individualProxyTypes.includes(selectedData?.proxyType) ? (
          <InformationsContentItem>
            <span>{t("proxySitePage.info.informations.individual")}:</span>
            <InformationsContentItemValue>
              <YesNo value={selectedData?.individual} />
            </InformationsContentItemValue>
          </InformationsContentItem>
        ) : null}
      </InformationsContentList>
    </InfoItemContainer>
  ) : null;
};

Informations.Skeleton = ({ active }) => (
  <InfoItemContainer active={active}>
    <InfoItemTitle>
      <StyledSkeleton width={120} height={20} />
    </InfoItemTitle>
    <StyledSkeleton height={48} />
    <InformationsContentList>
      {[...Array(5)].map((_, index) => (
        <InformationsContentItem key={index}>
          <StyledSkeleton width={120} />
          <InformationsContentItemValue>
            <StyledSkeleton width={70} />
          </InformationsContentItemValue>
        </InformationsContentItem>
      ))}
    </InformationsContentList>
  </InfoItemContainer>
);
