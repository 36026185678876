import { LoaderInner, LoaderWrapper } from "./Loader.styled";

export const Loader = ({ type, scale = 1, noPadding, hide }) => (
  <LoaderWrapper type={type} scale={scale} noPadding={noPadding} hide={hide}>
    <LoaderInner>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoaderInner>
  </LoaderWrapper>
);
