import { useState } from "react";

import "moment/locale/ru";
import "moment/locale/uk";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useSelector } from "react-redux";

import { TextButton } from "../../../../../../components/ui/TextButton/TextButton";
import { Tooltip } from "../../../../../../components/ui/Tooltip/Tooltip";
import { useLangUrlDefault } from "../../../../../../hooks";
import { getAllUser } from "../../../../../../store/reducers/UserReducer/User.selectors";
import { Icon } from "../../../../../ui/Icon/Icon";

import { ModalAppeal } from "./ModalaAppeal";
import {
  StyledFlexBox,
  StyledReviewDataBox,
  StyledStars,
  StyledStatus,
  StyledUserEmail,
  StyledUserExpert,
  StyledUserInfoBlock,
  StyledUserName,
  StyledUserPart
} from "./UserPart.styled";

export const UserPart = ({ review, isRootReview, siteTitle, isDashboard }) => {
  const { t } = useTranslation();
  const [lang] = useLangUrlDefault();

  const { user, isUserAuthenticated } = useSelector(getAllUser);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const maskEmail = (email) => {
    const [firstPart, secondPart] = email.split("@");

    return `${firstPart.slice(0, 1)}***${firstPart.slice(
      -3,
      firstPart?.length
    )}@${secondPart}`;
  };
  const currentName = () => {
    if (review?.byOwner && review?.userId !== user?.uid) {
      return t("reviews.expert");
    }
    if (review?.userName) {
      return review?.userName.includes("@")
        ? review?.userName.split("@")[0]
        : review?.userName;
    }
    return t("reviews.anonymous");
  };
  return (
    <>
      <StyledUserPart isRootReview={isRootReview} isDashboard={isDashboard}>
        <StyledUserInfoBlock>
          <StyledUserName itemProp="author">
            {`${currentName()}${
              isUserAuthenticated && review?.userId === user?.uid
                ? ` (${t("reviews.you")})`
                : ""
            }`}
          </StyledUserName>
          {review?.userEmail && isRootReview && !isDashboard && (
            <StyledUserEmail>{maskEmail(review?.userEmail)}</StyledUserEmail>
          )}
          {isDashboard && isRootReview && (
            <StyledFlexBox>
              <StyledStars
                size={20}
                value={review?.rating}
                mobileNone
                itemProp="reviewRating"
              />
              {user?.roles.includes("SUPERUSER") ? (
                <TextButton
                  iconLeft="info"
                  color="secondary"
                  onClick={handleOpenModal}
                  size="sm"
                >
                  {t("dashboard.reviews.appeal.btn")}
                </TextButton>
              ) : null}
            </StyledFlexBox>
          )}
          {!isRootReview && review?.byOwner && review?.userId !== user?.uid && (
            <StyledUserExpert>
              <Icon name="checkCircle" />
              {siteTitle}
            </StyledUserExpert>
          )}
        </StyledUserInfoBlock>

        <StyledReviewDataBox>
          {isRootReview && (
            <StyledStars
              size={20}
              value={review?.rating}
              desctopNone={isDashboard}
            />
          )}
          <StyledFlexBox>
            {isRootReview && isDashboard && (
              <StyledStatus
                status={review?.isApproved ? "approved" : "moderation"}
              >
                <Tooltip
                  body={t(
                    `dashboard.reviews.statusDescription.${
                      review?.isApproved ? "approved" : "moderation"
                    }`
                  )}
                >
                  <Icon name={review?.isApproved ? "checkCircle" : "warning"} />
                </Tooltip>
                <span>
                  {t(
                    `dashboard.reviews.status.${
                      review?.isApproved ? "approved" : "moderation"
                    }`
                  )}
                </span>
              </StyledStatus>
            )}
            <Moment
              format="MMM DD, YYYY"
              locale={lang === "ua" ? "uk" : lang}
              itemProp="dateCreated"
            >
              {new Date(review.creationDate)}
            </Moment>
          </StyledFlexBox>
        </StyledReviewDataBox>
      </StyledUserPart>
      {isDashboard && isRootReview && (
        <ModalAppeal
          open={openModal}
          close={handleCloseModal}
          idComment={review.id}
        />
      )}
    </>
  );
};
