import styled from "@emotion/styled";

import { FAQ } from "../../../components/common/FAQ/FAQ";
import { Main } from "../../../components/common/Main/Main";

export const RestyledMainSection = styled(Main)`
  padding-bottom: 30px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-bottom: 0;
    // min-height: 575px;
  }

  & .main-content-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      padding-top: 167px;
      padding-bottom: 222px;
    }
  }

  & .main-image-wrapper {
    margin-top: -30px;
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      right: 25px;
      top: 90px;
      width: 402px;
      height: 226px;
    }

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      right: -15px;
      width: 503px;
      height: 283px;
    }
    // @media (min-width: 1500px) {
    //   right: 10vw;
    // }

    &::before {
      bottom: -5px;
    }
  }
`;

export const RestyledFAQ = styled(FAQ)`
  img {
    width: 100%;
    max-width: 409px;
  }
`;
