import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useDispatchedActions, useLangUrlDefault } from "../../hooks";
import { useBrowserFingerprint } from "../../hooks/useBrowserFingerprint";
import { ApiService } from "../../services";
import { AllActions } from "../../store/reducers/AllActions";
import { getAllBlogInner } from "../../store/reducers/BlogInnerReducer/BlogInner.selectors";

import { BlogInner } from "./BlogInner/BlogInner";
import { StyledBreadcrumbs } from "./BlogInnerPage.styled";

const STATUS_DRAFT = "DRAFT";

const BlogInnerPage = () => {
  // **Props
  const [queryLang, hrefLang] = useLangUrlDefault();
  const { alias } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // **Redux state
  const { content } = useSelector(getAllBlogInner);
  const { t } = useTranslation();
  // blogInnerPage, notifications
  const browserFingerprint = useBrowserFingerprint();

  // Dispatch
  const { getBlogContentByAlias, setErrorBlogInnerContent } =
    useDispatchedActions();

  useEffect(() => {
    getBlogContentByAlias({ alias, languageCode: queryLang });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, alias]);

  useEffect(() => {
    if (content?.data?.id && browserFingerprint) {
      ApiService.createCountView(content?.data?.id, browserFingerprint);
    }
  }, [content?.data?.id, browserFingerprint]);

  // Handling error
  useEffect(() => {
    if (content.isError || content?.data?.status === STATUS_DRAFT) {
      toast.error(t("notifications.blogInner.notFoundBlogPost"));
      navigate(`${hrefLang}/blog/?category=all`);
      setErrorBlogInnerContent(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.isError, content?.data?.status]);

  // useEffect(() => {
  //   const wrapper = document.getElementsByClassName("wrapper");
  //   wrapper[0]?.classList.add("bloginner-wrapper");
  // }, []);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });
  }, [pathname]);

  return (
    <>
      <StyledBreadcrumbs
        items={[
          ...t("blogInnerPage.breadcrumbs", { returnObjects: true }),
          { title: content.data?.title || null }
        ]}
      />
      <BlogInner />
    </>
  );
};

export default BlogInnerPage;

BlogInnerPage.getServerSideState = async (store, params) => {
  const [, identifier] = params?.pathname.split("/").reverse();
  const { data: blogContentByAlias } = await ApiService.getBlogContentByAlias(identifier,params.locale);
  store.dispatch(AllActions.setBlogContentByAlias(blogContentByAlias));
};
