export const SPECIAL_OFFER_SWIPER_BREAKPOINTS = {
  320: {
    slidesPerView: 1,
    spaceBetween: 10
  },
  400: {
    slidesPerView: 1,
    spaceBetween: 20
  },
  480: {
    slidesPerView: 1.2,
    spaceBetween: 20
  },
  550: {
    slidesPerView: 1.5,
    spaceBetween: 20
  },
  768: {
    slidesPerView: 2,
    spaceBetween: 20
  },
  900: {
    slidesPerView: 2.5,
    spaceBetween: 20
  },
  992: {
    slidesPerView: 2,
    spaceBetween: 36
  },
  1200: {
    slidesPerView: 3,
    spaceBetween: 24
  }
};
