import { createSlice } from "@reduxjs/toolkit";

import { proxySiteInitialState } from "./ProxySite.initialState";
import {
  clearProxySiteReviewsReducer,
  setAllPromocodesBySiteReducer,
  setAlternativePromocodesReducer,
  setAlternativeSitesReducer,
  setProxySiteInfoReducer,
  setProxySiteRatingReducer,
  setProxySiteRecentReviewsReducer,
  setProxySiteReviewsAmountReducer,
  setProxySiteReviewsReducer,
  setProxySitesFetchParamsReducer,
  setProxySitesReducer,
  setProxySitesTypeReducer,
  setReviewInfoReducer
} from "./ProxySite.reducer";
import {
  clearProxySiteReviews,
  getAllPromocodesBySite,
  getAlternativePromocodes,
  getAlternativeSites,
  getProxySiteCompaints,
  getProxySiteInfo,
  getProxySiteRating,
  getProxySiteRecentReviews,
  getProxySiteReviews,
  getProxySiteReviewsAmount,
  getProxySites,
  setAllPromocodesBySite,
  setAlternativePromocodes,
  setAlternativeSites,
  setProxySiteInfo,
  setProxySiteRating,
  setProxySiteRecentReviews,
  setProxySiteReviews,
  setProxySiteReviewsAmount,
  setProxySites,
  setProxySitesFetchParams,
  setProxySitesType,
  setReviewInfo,
  setSiteViewStatistic
} from "./ProxySite.thunks";

const ProxySiteSlice = createSlice({
  name: "proxysite",
  initialState: proxySiteInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(setReviewInfo, setReviewInfoReducer)
      .addCase(setProxySiteInfo, setProxySiteInfoReducer)
      .addCase(setProxySiteReviewsAmount, setProxySiteReviewsAmountReducer)
      .addCase(setProxySiteRating, setProxySiteRatingReducer)
      .addCase(setAllPromocodesBySite, setAllPromocodesBySiteReducer)
      .addCase(setProxySiteReviews, setProxySiteReviewsReducer)
      .addCase(setProxySites, setProxySitesReducer)
      .addCase(setProxySitesFetchParams, setProxySitesFetchParamsReducer)
      .addCase(setProxySitesType, setProxySitesTypeReducer)
      .addCase(clearProxySiteReviews, clearProxySiteReviewsReducer)
      .addCase(setAlternativeSites, setAlternativeSitesReducer)
      .addCase(setAlternativePromocodes, setAlternativePromocodesReducer)
      .addCase(setProxySiteRecentReviews, setProxySiteRecentReviewsReducer)

      // getProxySiteInfo
      .addCase(getProxySiteInfo.pending, (state) => {
        state.site.isError = null;
        state.site.isLoading = true;
      })
      .addCase(getProxySiteInfo.fulfilled, (state, action) => {
        state.site.isError = null;
        state.site.data = action.payload;
        state.site.isLoading = false;
        state.site.isDataLoaded = true;
      })
      .addCase(getProxySiteInfo.rejected, (state, action) => {
        state.site.isError = action.payload;
        state.site.isLoading = false;
      })

      // setSiteViewStatistic
      .addCase(setSiteViewStatistic.pending, (state) => {
        state.statistic.isError = null;
        state.statistic.isLoading = true;
      })
      .addCase(setSiteViewStatistic.fulfilled, (state, action) => {
        state.statistic.isError = null;
        state.statistic.isStatisticSites = [
          ...state.statistic.isStatisticSites,
          action.payload
        ];
        state.statistic.isLoading = false;
      })
      .addCase(setSiteViewStatistic.rejected, (state, action) => {
        state.statistic.isError = action.payload;
        state.statistic.isLoading = false;
      })

      // getProxySiteReviewsAmount
      .addCase(getProxySiteReviewsAmount.pending, (state) => {
        state.reviewsAmount.isError = null;
        state.reviewsAmount.isLoading = true;
      })
      .addCase(getProxySiteReviewsAmount.fulfilled, (state, action) => {
        state.reviewsAmount.isError = null;
        state.reviewsAmount.data = action.payload;
        state.reviewsAmount.isLoading = false;
      })
      .addCase(getProxySiteReviewsAmount.rejected, (state, action) => {
        state.reviewsAmount.isError = action.payload;
        state.reviewsAmount.isLoading = false;
      })

      // getProxySiteRating
      .addCase(getProxySiteRating.pending, (state) => {
        state.rating.isError = null;
        state.rating.isLoading = true;
      })
      .addCase(getProxySiteRating.fulfilled, (state, action) => {
        state.rating.isError = null;
        state.rating.data = action.payload;
        state.rating.isLoading = false;
      })
      .addCase(getProxySiteRating.rejected, (state, action) => {
        state.rating.data = {
          content: [],
          key: ""
        };
        state.rating.isError = action.payload;
        state.rating.isLoading = false;
      })

      // getProxySiteReviews
      .addCase(getProxySiteReviews.pending, (state) => {
        state.reviews.isError = null;
        state.reviews.isLoading = true;
      })
      .addCase(getProxySiteReviews.fulfilled, (state, action) => {
        state.reviews.isError = null;

        if (action.payload.data?.content) {
          state.reviews.data = {
            content:
              action.payload.params?.showMore > 0
                ? [
                    ...state.reviews.data.content,
                    ...action.payload.data.content
                  ]
                : action.payload.data.content,
            totalElements: action.payload.data.totalElements,
            totalPages: action.payload.data.totalPages,
            lastSixMonthElements: action.payload.data.lastSixMonthElements
          };
        } else {
          state.reviews.data = {
            content: [],
            totalElements: 0,
            totalPages: 0,
            lastSixMonthElements: 0
          };
        }

        state.reviews.fetchedParams = action.payload.params;
        state.reviews.isDataLoaded = true;
        state.reviews.isLoading = false;
      })
      .addCase(getProxySiteReviews.rejected, (state, action) => {
        state.reviews.isError = action.payload;
        state.reviews.isLoading = false;
      })

      // getProxySiteCompaints
      .addCase(getProxySiteCompaints.pending, (state) => {
        state.complaints.isError = null;
        state.complaints.isLoading = true;
      })
      .addCase(getProxySiteCompaints.fulfilled, (state, action) => {
        state.complaints.isError = null;
        state.complaints.data = action.payload;
        state.complaints.isLoading = false;
      })
      .addCase(getProxySiteCompaints.rejected, (state, action) => {
        state.complaints.isError = action.payload;
        state.complaints.isLoading = false;
      })

      // getAllPromocodesBySite
      .addCase(getAllPromocodesBySite.pending, (state) => {
        state.promocodes.isError = null;
        state.promocodes.isLoading = true;
      })
      .addCase(getAllPromocodesBySite.fulfilled, (state, action) => {
        state.promocodes.isError = null;
        state.promocodes.data = action.payload;
        state.promocodes.isLoading = false;
        state.promocodes.isDataLoaded = true;
      })
      .addCase(getAllPromocodesBySite.rejected, (state, action) => {
        state.promocodes.isError = action.payload;
        state.promocodes.isLoading = false;
      })

      // getProxySites
      .addCase(getProxySites.pending, (state) => {
        state.proxySites.isLoading = true;
        state.proxySites.isError = null;
      })
      .addCase(getProxySites.fulfilled, (state, action) => {
        state.proxySites.isLoading = false;
        state.proxySites.content = action.payload.content;
        state.proxySites.totalElements = action.payload.totalElements;
        state.proxySites.totalPages = action.payload.totalPages;
        state.proxySites.skip = action.payload.skip;
        state.proxySites.fetchParams = action.payload.params;
        state.proxySites.isDataLoaded = true;
      })
      .addCase(getProxySites.rejected, (state, action) => {
        state.proxySites.isLoading = false;
        state.proxySites.isError = action.payload;
      })

      // getAlternativeSites
      .addCase(getAlternativeSites.pending, (state) => {
        state.alternative.isLoading = true;
        state.alternative.isError = null;
      })
      .addCase(getAlternativeSites.fulfilled, (state, action) => {
        state.alternative.isLoading = false;
        state.alternative.data = action.payload;
        state.alternative.isDataLoaded = true;
      })
      .addCase(getAlternativeSites.rejected, (state, action) => {
        state.alternative.isLoading = false;
        state.alternative.isError = action.payload;
      })

      // getAlternativePromocodes
      .addCase(getAlternativePromocodes.pending, (state) => {
        state.alternativePromocodes.isLoading = true;
        state.alternativePromocodes.isError = null;
      })
      .addCase(getAlternativePromocodes.fulfilled, (state, action) => {
        state.alternativePromocodes.isLoading = false;
        state.alternativePromocodes.data = action.payload;
        state.alternativePromocodes.isDataLoaded = true;
      })
      .addCase(getAlternativePromocodes.rejected, (state, action) => {
        state.alternativePromocodes.isLoading = false;
        state.alternativePromocodes.isError = action.payload;
      })

      // getProxySiteRecentReviews
      .addCase(getProxySiteRecentReviews.pending, (state) => {
        state.recentReviews.isError = null;
        state.recentReviews.isLoading = true;
      })
      .addCase(getProxySiteRecentReviews.fulfilled, (state, action) => {
        state.recentReviews.isError = null;
        state.recentReviews.data = action.payload.data.content;
        state.recentReviews.isLoading = false;
        state.recentReviews.isDataLoaded = true;
      })
      .addCase(getProxySiteRecentReviews.rejected, (state, action) => {
        state.recentReviews.isError = action.payload;
        state.recentReviews.isLoading = false;
      })
});

export const ProxySiteReducerActions = {
  setReviewInfo,
  setProxySiteInfo,
  setProxySiteReviewsAmount,
  setProxySiteRating,
  setAllPromocodesBySite,
  setProxySiteReviews,
  setProxySites,
  setProxySitesFetchParams,
  setProxySitesType,
  clearProxySiteReviews,
  getProxySiteInfo,
  setSiteViewStatistic,
  getProxySiteReviewsAmount,
  getProxySiteRating,
  getProxySiteReviews,
  getProxySiteCompaints,
  getAllPromocodesBySite,
  getProxySites,
  getAlternativeSites,
  setAlternativeSites,
  getAlternativePromocodes,
  setAlternativePromocodes,
  getProxySiteRecentReviews,
  setProxySiteRecentReviews
};
export default ProxySiteSlice.reducer;
