import { useEffect, useRef, useState } from "react";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthNavigate } from "../../../components/common/AuthNavigate/AuthNavigate";
import { FormInput } from "../../../components/forms/FormInput/FormInput";
import { useLangUrlDefault } from "../../../hooks";
import { ApiService } from "../../../services";
import { AllActions } from "../../../store/reducers/AllActions";
import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";
import { PasswordRecoverySchema } from "../../../utils/validation";
import {
  StyledAlert,
  StyledAuthForm,
  StyledAuthTitle,
  StyledButtonSubmit,
  StyledSubTitle
} from "../AuthPages.styled";

const RecoveryPassPage = () => {
  const captchaRef = useRef(null);

  // **Props
  const { token } = useParams();
  const [hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // **Redux state
  const { pageContent, captchaConfig } = useSelector(getAllContent);

  // **Local state
  const [isLoading, setIsLoading] = useState(true);
  const [, setTokenCaptcha] = useState(null);
  const [email, setEmail] = useState();
  const [error, setError] = useState(false);
  const { enable, clientKey, headerName, headerType } = captchaConfig.data;
  // const metaData = useMetaData();

  // Form
  const methods = useForm({
    resolver: yupResolver(
      PasswordRecoverySchema(t("forms", { returnObjects: true }))
    )
  });

  const onSubmit = async (data) => {
    let header = {};

    if (enable) {
      try {
        const { response: token } = await captchaRef.current.execute({
          async: true
        });
        header = { [headerName]: token, [headerType]: "HCAPTCHA" };
      } catch (ignore) {
        return;
      }
      // const { response: token } = await captchaRef.current.execute({
      //   async: true
      // });
      // header = { [headerName]: token };
    }

    try {
      setIsLoading(true);

      const response = await ApiService.restorePassword(
        {
          email,
          token,
          password: data?.password
        },
        header
      );

      if (response && response.status !== 200) {
        throw response;
      }

      methods.reset();
      toast.success(t("notifications.passwordRecovery"));

      navigate(`${hrefLang}/login/`, { replace: true });
    } catch (err) {
      toast.error(err?.message || t("notifications.apiError"));
    } finally {
      if (enable) {
        captchaRef.current.resetCaptcha();
        setTokenCaptcha(null);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!token) {
      setError(true);
      return;
    }

    ApiService.getEmailByToken(token)
      .then((response) => {
        if ((response && response.status !== 200, !response?.data)) {
          setError(true);
        }
        setEmail(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledAuthTitle>
        {pageContent?.current?.data?.h1 ||
          t("authPages.resetPasswordPage.title")}
      </StyledAuthTitle>
      <StyledSubTitle>
        {t("authPages.resetPasswordPage.subtitle")}
      </StyledSubTitle>
      <FormProvider {...methods}>
        <StyledAuthForm
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
          data-testid="recoveryPasswordForm"
        >
          {error && (
            <StyledAlert type="error">
              {t("authPages.resetPasswordPage.error")}
            </StyledAlert>
          )}
          <FormInput
            name="password"
            type="password"
            placeholder={t("forms.password.newPassword")}
            label={t("forms.password.newPassword")}
            showError
            data-testid="recoveryPasswordForm_inputPassword"
            dataTestIdShowPassword="recoveryPasswordForm_buttonShowPassword"
          />
          {enable && clientKey && (
            <HCaptcha
              ref={captchaRef}
              sitekey={clientKey}
              onVerify={setTokenCaptcha}
              onExpire={() => setTokenCaptcha(null)}
              onError={(err) => toast.error(err?.message)}
              size="invisible"
            />
          )}
          <StyledButtonSubmit
            type="submit"
            data-testid="recoveryPasswordForm_buttonSubmit"
            fullWidth
            disabled={isLoading || error}
            loading={isLoading}
          >
            {t("authPages.resetPasswordPage.btn")}
          </StyledButtonSubmit>
        </StyledAuthForm>
      </FormProvider>
      <AuthNavigate to="login" isStatic />
    </>
  );
};

export default RecoveryPassPage;

RecoveryPassPage.getServerSideState = async (store, params) => {

  const { data: pageContent } = await ApiService.getPageContent({
    ...params,
    languageCode: params.locale
  });

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setPageContent(pageContent));
};
