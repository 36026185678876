import * as yup from "yup";

import { REGEX_IP, REGEX_IPV6, URL_WITHOUT_HTTPS } from "../constants";

export const TraicingIpSchema = (trns) =>
  yup.object().shape({
    ipAddress: yup
      .string()
      .required(trns.form.rules.required)
      .test(
        "validValue",
        trns.form.rules.ip,
        (value) =>
          REGEX_IP.test(value) ||
          URL_WITHOUT_HTTPS.test(value) ||
          REGEX_IPV6.test(value)
      )
  });
