import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Container } from "../../../components/common/Container/Container";
import { useDispatchedActions, useLangUrlDefault } from "../../../hooks";
import { getAllBlog } from "../../../store/reducers/BlogReducer/Blog.selectors";
import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";

import { BlogSection, Heading } from "./Blog.styled";
import { BlogList } from "./BlogList/BlogList";
import { Latest } from "./Latest/Latest";

export const Blog = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const currentCategory = searchParams.get("category");
  const s = searchParams.get("s");

  const [queryLang] = useLangUrlDefault();

  // Dispatch
  const { getBlogContent } = useDispatchedActions();

  // **Redux state
  const {
    pageContent: { current }
  } = useSelector(getAllContent);
  const { content } = useSelector(getAllBlog);

  // **Local state
  const [category, setCategory] = useState("null");
  const [pageParams, setPageParams] = useState({ page: 0, size: 5 });

  const pageChangeHandler = (page) => {
    setPageParams({ ...pageParams, page: page - 1 });
  };

  useEffect(() => {
    if (!currentCategory) {
      if (s) {
        setSearchParams({ category: "all", s });
      } else {
        setSearchParams({ category: "all" });
      }
    }
    setCategory(currentCategory === "all" ? "null" : currentCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory]);

  useEffect(() => {
    if (
      content.data.params?.page === pageParams.page &&
      content.data.params?.category === category &&
      content.data.params?.languageCode === queryLang &&
      content.data.params?.size === pageParams.size &&
      content.data.params?.s === s
    ) {
      return;
    }
    const promise = getBlogContent({
      page: pageParams.page,
      size: pageParams.size,
      categoryName: category,
      languageCode: queryLang,
      ...(s && s.length > 1 && { search: s })
    });

    return () => promise?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageParams, category, queryLang, s]);

  return (
    <BlogSection>
      <Container>
        <Heading>{current?.data?.h1}</Heading>
      </Container>
      <Latest data={content.data.content} />
      <BlogList
        category={category}
        pageParams={pageParams}
        pageChangeHandler={pageChangeHandler}
        emptyText={t("blogPage.emptyArticles")}
      />
    </BlogSection>
  );
};
