import { ApiService } from "../../services";
import { AllActions } from "../../store/reducers/AllActions";

import { Blog } from "./Blog/Blog";

const BlogPage = () => <Blog />;

export default BlogPage;

BlogPage.getServerSideState = async (store, params) => {
  const { s, category } = params.params;

  const { data } = await ApiService.getBlogContent({
    page: 0,
    size: 5,
    categoryName: category === "all" ? "null" : category,
    languageCode: params.locale,
    ...(s && s?.length > 1 && { search: s })
  });

  if (data) {
    store.dispatch(AllActions.setBlogContent(data));
  }
};
