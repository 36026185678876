import styled from "@emotion/styled";

import { Container } from "../../../components/common/Container/Container";

export const TabsWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 48px;
  }
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: auto;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 2px;
  }

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

export const Tab = styled.div`
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.active ? "600" : "400")};
  line-height: 1.45;
  padding: 6px 8px;
  height: 39px;
  color: ${(props) =>
    props.active
      ? props.theme.colors["neutral-800"]
      : props.theme.colors["neutral-400"]};
  background-color: ${(props) =>
    props.active
      ? props.theme.colors["main-500"]
      : props.theme.colors["background-color"]};
  white-space: nowrap;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    background: #eeeff1;
    color: ${(props) => props.theme.colors["neutral-800"]};
    transition: all 0.3s ease;
  }

  &:active {
    background: ${(props) => props.theme.colors["main-500"]};
    color: ${(props) => props.theme.colors["neutral-800"]};
    transition: all 0.3s ease;
  }

  &::before {
    display: block;
    content: attr(title);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    padding-left: 2px;
  }
`;
