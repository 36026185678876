import { useTranslation } from "react-i18next";

import animation from "../../assets/animations/ipv4.riv";
import {
  ipv4FaqImage,
  ipv4FaqImage2x,
  ipv4HeroImageMobile,
  ipv4HeroImageMobile2x
} from "../../assets/img";
import { FAQ } from "../../components/common/FAQ/FAQ";
import { HowToBuy } from "../../components/common/HowToBuy/HowToBuy";
import { SeoSection } from "../../components/common/SeoSection/SeoSection";
import { Rive } from "../../components/ui/Rive/Rive";
import { scrollToWithOffset } from "../../utils/helpers/scroll.helper";

import { ProductProxyMarkup } from "./ProductProxyMarkup/ProductProxyMarkup";
import { Proxy } from "./Proxy/Proxy";
import { getProxyPageState } from "./ProxyPage.state";
import { RestyledMainSection } from "./ProxyPage.styled";
import { SpecialOffer } from "./SpecialOffer/SpecialOffer";

const ProxyPage = () => {
  const { t } = useTranslation();

  const handleButtonClick = () => {
    scrollToWithOffset("proxyContent", -50);
  };

  return (
    <>
      <ProductProxyMarkup />
      <RestyledMainSection
        buttonLabel={t("navigation.selectService")}
        images={{
          mobile1x: ipv4HeroImageMobile,
          mobile2x: ipv4HeroImageMobile2x
        }}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-21}
            right={-18}
            bottom={-115}
            left={-18}
          />
        }
        handleButtonClick={handleButtonClick}
      />
      <SpecialOffer />
      <Proxy />
      <HowToBuy />
      <FAQ image={ipv4FaqImage} image2x={ipv4FaqImage2x} />
      <SeoSection />
    </>
  );
};

export default ProxyPage;

ProxyPage.getServerSideState = async (store, params) => {
  await getProxyPageState(store, params);
};
