import { useState } from "react";

import { useTranslation } from "react-i18next";

import { handleScrollBottomTable } from "../../../../../utils/helpers/resultToolTable.helper";

import {
  CountryFlag,
  CountryName,
  DnsTableWrapper,
  ResultColumnCountry,
  ResultColumnText,
  ResultTable,
  TableBody,
  TableBox,
  TableCol,
  TableHeadCol,
  TableHeadRow,
  TableRow
} from "./ResultTable.styled";

export const ResultsTable = ({ data }) => {
  const { t } = useTranslation();
  const [scrollBottomTable, setScrollBottomTable] = useState(false);

  return (
    <DnsTableWrapper scrollBottom={scrollBottomTable}>
      <ResultTable>
        <TableBox>
          <TableHeadRow>
            <TableHeadCol width="170px">{t("dnsLeak.table.ip")}</TableHeadCol>
            <TableHeadCol width="260px">
              {t("dnsLeak.table.provider")}
            </TableHeadCol>
            <TableHeadCol width="260px">
              {t("dnsLeak.table.country")}
            </TableHeadCol>
          </TableHeadRow>
          <TableBody
            onScroll={(e) => handleScrollBottomTable(e, setScrollBottomTable)}
          >
            {data.map((item, i) => (
              <TableRow key={i}>
                <TableCol width="170px">
                  <ResultColumnText>{item.ip}</ResultColumnText>
                </TableCol>
                <TableCol width="260px">
                  <ResultColumnText>{item.provider}</ResultColumnText>
                </TableCol>
                <TableCol width="260px">
                  <ResultColumnCountry>
                    <CountryFlag src={item.flag} alt={item.country} />
                    <CountryName>{item.country}</CountryName>
                  </ResultColumnCountry>
                </TableCol>
              </TableRow>
            ))}
          </TableBody>
        </TableBox>
      </ResultTable>
    </DnsTableWrapper>
  );
};
