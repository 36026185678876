import { useState } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ApiService } from "../../../../services";
import { ModalConfirm } from "../../components/ModalConfirm/ModalConfirm";

export const PromoModalDelete = ({
  isModalOpened,
  setIsModalOpened,
  deletedPromocodeId,
  setDeletedPromocodeId,
  fetchCabinetPromocodes
}) => {
  // **Redux state
  const { t } = useTranslation();

  // **Local state
  const [isDeleting, setIsDeleting] = useState(false);

  const confirmHandler = async () => {
    try {
      setIsDeleting(true);

      const response = await ApiService.deletePromocode(deletedPromocodeId);

      if (response && response.status !== 200) {
        throw response;
      }

      // Closing modal
      setIsModalOpened(false);

      // Show success message
      toast.success(t("notifications.promocode.removed"));

      setDeletedPromocodeId(null);
      fetchCabinetPromocodes();
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setTimeout(() => {
        setIsDeleting(false);
      }, 300);
    }
  };

  return (
    <ModalConfirm
      open={isModalOpened}
      close={setIsModalOpened}
      isDeleting={isDeleting}
      srcX1="/img/dashboard/delete-promo-x1.png"
      srcX2="/img/dashboard/delete-promo-x2.png"
      subtitle={t("dashboard.promocodes.modal.delete.subtitle")}
      onConfirm={confirmHandler}
      pictureVariant="promocode"
    />
  );
};
