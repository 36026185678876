import styled from "@emotion/styled";

import { FAQ } from "../../../components/common/FAQ/FAQ";
import { Main } from "../../../components/common/Main/Main";

export const FreeProxySection = styled.section`
  background-color: ${(p) => p.theme.colors["background-color"]};
`;

export const RestyledMainSection = styled(Main)`
  margin-bottom: -30px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
    // padding-bottom: 250px;
    // min-height: 595px;
  }

  // @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
  //   padding-bottom: 175px;
  //   min-height: 683px;
  // }

  & .main-content-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      max-width: 600px;
      padding-top: 105px;
      padding-bottom: 200px;
    }
    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      max-width: 700px;
    }
  }

  & .main-image-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      right: -80px;
      top: 39px;
      width: 567px;
      height: 315px;
    }

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      right: -150px;
      width: 709px;
      height: 394px;
    }

    @media (min-width: 1350px) {
      right: -185px;
    }

    &::before {
      bottom: 15%;
    }

    img {
      margin-bottom: -80px;
    }
  }
`;

export const RestyledFAQ = styled(FAQ)`
  img {
    width: 100%;
    max-width: 409px;
  }
`;
