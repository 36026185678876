import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Pagination } from "../../../components/ui/Pagination/Pagination";
import { useDispatchedActions } from "../../../hooks";
import { AllActions } from "../../../store/reducers/AllActions";
import { getAllCabinet } from "../../../store/reducers/CabinetReducer/Cabinet.selectors";
import { NoData } from "../components/NoData/NoData";
import { TitleBlock } from "../components/TitleBlock/TitleBlock";

import { PaginationBox, PromocodesContainer } from "./Promocodes.styled";
import { PromocodesTable } from "./components/PromocodesTable";

const Promocodes = () => {
  // **Redux state
  const { t } = useTranslation();
  const { promocodes } = useSelector(getAllCabinet);

  // **Local state
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState(
    promocodes.prevParams || {
      page: 0,
      size: 10,
      siteIds: []
    }
  );

  // **Dispatch
  const { getCabinetPromocodes, setCabinetPromocodesPrevParams } =
    useDispatchedActions();

  // **Ref
  // const scrollRef = useRef();

  const fetchCabinetPromocodes = useCallback(() => {
    getCabinetPromocodes(params);
    setCabinetPromocodesPrevParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // Change page by clicking pagination
  const pageChangeHandler = (page) => {
    setParams({ ...params, page: page - 1 });
  };

  const pageSizeChangeHandler = (size) =>
    setParams({ ...params, page: 0, size });

  // const changeSortType = (type) => {
  //   setParams({ ...params, sort: type });
  // };

  useEffect(() => {
    if (JSON.stringify(params) !== JSON.stringify(promocodes.prevParams)) {
      fetchCabinetPromocodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (promocodes.data.totalPages !== params.page || params.page === 0) {
      return;
    }
    setParams({ ...params, page: params.page - 1 });
  }, [params, promocodes]);

  useEffect(() => {
    if (
      !promocodes.isLoading &&
      typeof promocodes.data?.anyExists === "boolean"
    ) {
      setLoading(false);
    }
  }, [promocodes.isLoading, promocodes.data?.anyExists]);

  return (
    <>
      <TitleBlock
        title={t("dashboard.promocodes.table.title")}
        linkTo="/dashboard/promocodes/create"
        linkName={t("dashboard.promocodes.table.add")}
      />

      <PromocodesContainer
        noData={!promocodes.isLoading && !promocodes.data?.anyExists}
      >
        {!promocodes.isLoading && !promocodes.data?.anyExists ? (
          <NoData
            title={t("dashboard.promocodes.noData.title")}
            subtitle={t("dashboard.promocodes.noData.subtitle")}
            srcX1="/img/dashboard/no-promocodes-x1.png"
            srcX2="/img/dashboard/no-promocodes-x2.png"
          />
        ) : (
          <>
            <PromocodesTable
              loading={loading}
              fetchCabinetPromocodes={fetchCabinetPromocodes}
              params={params}
              setParams={setParams}
            />
            {!loading && promocodes.data?.totalElements > 0 && (
              <PaginationBox>
                <Pagination
                  rowCount={promocodes.data.totalElements}
                  rowsPerPage={params.size}
                  onChangePage={pageChangeHandler}
                  onChangeRowsPerPage={pageSizeChangeHandler}
                  currentPage={params.page + 1}
                  useCount
                />
              </PaginationBox>
            )}
          </>
        )}
      </PromocodesContainer>
    </>
  );
};

export default Promocodes;

Promocodes.getServerSideState = async (store, params) => {
  const { data: pageContent } = await ApiService.getPageContent({
    ...params,
    languageCode: params.locale
  });

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setPageContent(pageContent));
};
