export const getHttpFingerprintTableData = (data, t) => [
  {
    attribute: t("fingerprint.HTTP.userAgent"),
    similarityRatio: "N/A",
    value: data?.userAgent,
    tooltip: t("fingerprint.HTTP.userAgentTooltip")
  },
  {
    attribute: t("fingerprint.HTTP.accept"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.HTTP.acceptTooltip")
  },
  {
    attribute: t("fingerprint.HTTP.contentEncoding"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.HTTP.contentEncodingTooltip")
  },
  {
    attribute: t("fingerprint.HTTP.contentLanguage"),
    similarityRatio: "N/A",
    value: data?.language,
    tooltip: t("fingerprint.HTTP.contentLanguageTooltip")
  },
  {
    attribute: t("fingerprint.HTTP.ifNoneMatch"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.HTTP.ifNoneMatchTooltip")
  },
  {
    attribute: t("fingerprint.HTTP.upgradeInsecureRequests"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.HTTP.upgradeInsecureRequestsTooltip")
  },
  {
    attribute: t("fingerprint.HTTP.referer"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.HTTP.refererTooltip")
  }
];

export const getJsFingerprintTableData = (data, t, permissions) => [
  {
    attribute: t("fingerprint.JS.userAgentJS"),
    similarityRatio: "N/A",
    value: data?.userAgentJs,
    tooltip: t("fingerprint.JS.userAgentJSTooltip")
  },
  {
    attribute: t("fingerprint.JS.os"),
    similarityRatio: "N/A",
    value: data?.os,
    tooltip: t("fingerprint.JS.osTooltip")
  },
  {
    attribute: t("fingerprint.JS.cookie"),
    similarityRatio: "N/A",
    value: data?.cookie,
    tooltip: t("fingerprint.JS.cookieTooltip")
  },
  {
    attribute: t("fingerprint.JS.timeZone"),
    similarityRatio: "N/A",
    value: data?.timeZone,
    tooltip: t("fingerprint.JS.timeZoneTooltip")
  },
  {
    attribute: t("fingerprint.JS.languageJs"),
    similarityRatio: "N/A",
    value: data?.languageJs,
    tooltip: t("fingerprint.JS.languageJsTooltip")
  },
  {
    attribute: t("fingerprint.JS.canvas"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.canvasTooltip")
  },
  {
    attribute: t("fingerprint.JS.fonts"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.fontsTooltip")
  },
  {
    attribute: t("fingerprint.JS.adblock"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.adblockTooltip")
  },
  {
    attribute: t("fingerprint.JS.doNotTrack"),
    similarityRatio: "N/A",
    value: data?.doNotTrack,
    tooltip: t("fingerprint.JS.doNotTrackTooltip")
  },
  {
    attribute: t("fingerprint.JS.navigatorProperties"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.navigatorPropertiesTooltip")
  },
  {
    attribute: t("fingerprint.JS.buildID"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.buildIDTooltip")
  },
  {
    attribute: t("fingerprint.JS.product"),
    similarityRatio: "N/A",
    value: navigator?.product,
    tooltip: t("fingerprint.JS.productTooltip")
  },
  {
    attribute: t("fingerprint.JS.productSub"),
    similarityRatio: "N/A",
    value: navigator?.productSub,
    tooltip: t("fingerprint.JS.productSubTooltip")
  },
  {
    attribute: t("fingerprint.JS.vendor"),
    similarityRatio: "N/A",
    value: navigator?.vendor,
    tooltip: t("fingerprint.JS.vendorTooltip")
  },
  {
    attribute: t("fingerprint.JS.vendorSub"),
    similarityRatio: "N/A",
    value: navigator?.vendorSub,
    tooltip: t("fingerprint.JS.vendorSubTooltip")
  },
  {
    attribute: t("fingerprint.JS.hardware"),
    similarityRatio: "N/A",
    value: navigator?.hardwareConcurrency,
    tooltip: t("fingerprint.JS.hardwareTooltip")
  },
  {
    attribute: t("fingerprint.JS.java"),
    similarityRatio: "N/A",
    value: data?.java,
    tooltip: t("fingerprint.JS.javaTooltip")
  },
  {
    attribute: t("fingerprint.JS.memory"),
    similarityRatio: "N/A",
    value: navigator?.deviceMemory,
    tooltip: t("fingerprint.JS.memoryTooltip")
  },
  {
    attribute: t("fingerprint.JS.plugins"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.pluginsTooltip")
  },
  {
    attribute: t("fingerprint.JS.screenWidth"),
    similarityRatio: "N/A",
    value: window.screen.width,
    tooltip: t("fingerprint.JS.screenWidthTooltip")
  },
  {
    attribute: t("fingerprint.JS.screenHeight"),
    similarityRatio: "N/A",
    value: window.screen.height,
    tooltip: t("fingerprint.JS.screenHeightTooltip")
  },
  {
    attribute: t("fingerprint.JS.screenDepth"),
    similarityRatio: "N/A",
    value: window.screen?.colorDepth,
    tooltip: t("fingerprint.JS.screenDepthTooltip")
  },
  {
    attribute: t("fingerprint.JS.availableTop"),
    similarityRatio: "N/A",
    value: window.screen?.availTop,
    tooltip: t("fingerprint.JS.availableTopTooltip")
  },
  {
    attribute: t("fingerprint.JS.availableLeft"),
    similarityRatio: "N/A",
    value: window.screen?.availLeft,
    tooltip: t("fingerprint.JS.availableLeftTooltip")
  },
  {
    attribute: t("fingerprint.JS.availableHeight"),
    similarityRatio: "N/A",
    value: window.screen?.availHeight,
    tooltip: t("fingerprint.JS.availableHeightTooltip")
  },
  {
    attribute: t("fingerprint.JS.availableWidth"),
    similarityRatio: "N/A",
    value: window.screen?.availWidth,
    tooltip: t("fingerprint.JS.availableWidthTooltip")
  },
  {
    attribute: t("fingerprint.JS.permissions"),
    similarityRatio: "N/A",
    value: permissions
      ?.filter((item) => item.state === "granted")
      ?.map((el) => el.permissionName),
    tooltip: t("fingerprint.JS.permissionsTooltip")
  },
  {
    attribute: t("fingerprint.JS.webGLVendor"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.webGLVendorTooltip")
  },
  {
    attribute: t("fingerprint.JS.webGLRenderer"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.webGLRendererTooltip")
  },
  {
    attribute: t("fingerprint.JS.webGLData"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.webGLDataTooltip")
  },
  {
    attribute: t("fingerprint.JS.webGLParameters"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.webGLParametersTooltip")
  },
  {
    attribute: t("fingerprint.JS.useOfLocalStorage"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.useOfLocalStorageTooltip")
  },
  {
    attribute: t("fingerprint.JS.useOfSessionStorage"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.useOfSessionStorageTooltip")
  },
  {
    attribute: t("fingerprint.JS.useOfIndexedDB"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.useOfIndexedDBTooltip")
  },
  {
    attribute: t("fingerprint.JS.audioFormats"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.audioFormatsTooltip")
  },
  {
    attribute: t("fingerprint.JS.audioContext"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.audioContextTooltip")
  },
  {
    attribute: t("fingerprint.JS.frequencyAnalyser"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.frequencyAnalyserTooltip")
  },
  {
    attribute: t("fingerprint.JS.audioData"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.audioDataTooltip")
  },
  {
    attribute: t("fingerprint.JS.videoFormats"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.videoFormatsTooltip")
  },
  {
    attribute: t("fingerprint.JS.mediaDevices"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.mediaDevicesTooltip")
  },
  {
    attribute: t("fingerprint.JS.accelerometer"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.accelerometerTooltip")
  },
  {
    attribute: t("fingerprint.JS.gyroscope"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.proximitySensorTooltip")
  },
  {
    attribute: t("fingerprint.JS.battery"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.batteryTooltip")
  },
  {
    attribute: t("fingerprint.JS.connection"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.connectionTooltip")
  },
  {
    attribute: t("fingerprint.JS.key"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.keyTooltip")
  },
  {
    attribute: t("fingerprint.JS.locationBar"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.locationBarTooltip")
  },
  {
    attribute: t("fingerprint.JS.menuBar"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.menuBarTooltip")
  },
  {
    attribute: t("fingerprint.JS.personalBar"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.personalBarTooltip")
  },
  {
    attribute: t("fingerprint.JS.statusBar"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.statusBarTooltip")
  },
  {
    attribute: t("fingerprint.JS.toolBar"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.toolBarTooltip")
  },
  {
    attribute: t("fingerprint.JS.resultState"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.resultStateTooltip")
  },
  {
    attribute: t("fingerprint.JS.listOfFontsFlash"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.listOfFontsFlashTooltip")
  },
  {
    attribute: t("fingerprint.JS.screenResolutionFlash"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.screenResolutionFlashTooltip")
  },
  {
    attribute: t("fingerprint.JS.languageFlash"),
    similarityRatio: "N/A",
    value: "N/A",
    tooltip: t("fingerprint.JS.languageFlashTooltip")
  },
  {
    attribute: t("fingerprint.JS.platformFlash"),
    similarityRatio: "N/A",
    value: navigator?.platform,
    tooltip: t("fingerprint.JS.platformFlashTooltip")
  }
];
