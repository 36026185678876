import { $api } from "../../services/AxiosInstance";
import { AllActions } from "../../store/reducers/AllActions";

export const getSiteState = async (store, params) => {
  let identifier = "";
  if (params.tag === "proxy-site-promo") {
    [, , identifier] = params.pathname.split("/").reverse();
  } else {
    [, identifier] = params.pathname.split("/").reverse();
  }

  const fetchParams = {
    languageCode: params.locale,
    params: {
      siteId: identifier
    },
    tag: params.tag,
    pathname: params.pathname,
    location: params.location
  };

  const { data: pageContent } = await $api.post(
    "/api/page/front/content",
    fetchParams
  );

  const { data: alternative } = await $api.get("/api/front/site/alternative", {
    params: { size: 6 }
  });

  const { data: goals } = await $api.get("api/goal/front/all", {
    params: { params: { languageCode: params.locale } }
  });
  const { data: goalsByGroups } = await $api.get("api/front/goal/group/all", {
    params: { languageCode: params.locale }
  });

  const { data: proxySiteInfo } = await $api.get(
    "api/front/site/proxy/description",
    { params: { id: identifier } }
  );
  const { data: reviewsAmount } = await $api.get(
    "api/front/site/reviews/amount",
    { params: { siteId: identifier } }
  );
  const { data: rating } = await $api.get("api/front/site/rating", {
    params: { siteId: identifier, step: "days" }
  });
  const { data: promocodes } = await $api.get("api/admin/promocode/site", {
    params: { siteId: identifier }
  });
  const { data: review } = await $api.get("api/front/site/review/sort", {
    params: {
      sort: "useful",
      siteId: identifier,
      page: 0,
      size: 4,
      showMore: 0
    }
  });
  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setPageContent({
      data: pageContent,
      lang: params.locale,
      innerTag: params.location
    })
  );
  store.dispatch(AllActions.setAlternativeSites(alternative));
  store.dispatch(AllActions.setGoals(goals));
  store.dispatch(AllActions.setGoalsByGroups(goalsByGroups));
  store.dispatch(AllActions.setProxySiteInfo(proxySiteInfo));
  store.dispatch(AllActions.setProxySiteReviewsAmount(reviewsAmount));
  store.dispatch(
    AllActions.setProxySiteRating({
      content: rating,
      key: `${identifier}-days`
    })
  );
  store.dispatch(AllActions.setAllPromocodesBySite(promocodes));
  store.dispatch(
    AllActions.setProxySiteReviews({
      data: review,
      params: {
        sort: "useful",
        siteId: identifier,
        page: 0,
        size: 4,
        showMore: 0
      }
    })
  );
};
