import styled from "@emotion/styled";

import { Rating } from "../../../../../../../components/ui/Raiting/Rating";

export const StyledBaseScore = styled.div`
  display: flex;
  align-items: center;
  // flex-wrap: wrap;
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  font-style: ${(p) => p.theme.fontStyle.normal};
  line-height: 140%;
  margin-right: auto;
  gap: 4px;
  & > div {
    font-size: ${(p) => p.theme.fontSizes.m};
    line-height: 125%;
  }
`;

export const StyledRating = styled(Rating)`
  display: inline;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & > svg {
    margin-right: 4px;
    margin-bottom: 2px;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
  }

  & span {
    color: ${(p) => p.theme.colors["neutral-800"]};
    font-family: ${(p) => p.theme.fontFamily.lato};
    font-size: ${(p) => p.theme.fontSizes.s};
    font-weight: ${(p) => p.theme.fontWeight.normal};
    font-style: ${(p) => p.theme.fontStyle.normal};
    line-height: 140%;
  }
`;
