import BlogInnerPage from "../pages/BlogInnerPage/BlogInnerPage";
import BlogPage from "../pages/BlogPage/BlogPage";
import MainPage from "../pages/MainPage/MainPage";
import ProxyPage from "../pages/ProxyPage/ProxyPage";
import ServicePage from "../pages/ServicePage/ServicePage";
import RequirementsPage from "../pages/RequirementsPage/RequirementsPage";

export const AppRoutes = (ssr = false) => [
  {
    path: "/:lang?/",
    tag: "home",
    type: "app",
    component: ssr ? MainPage : () => import("../pages/MainPage/MainPage"),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/blog",
    tag: "blog",
    type: "app",
    component: ssr ? BlogPage : () => import("../pages/BlogPage/BlogPage"),
    isHeadLight: true,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/blog/:alias",
    tag: "bloginner",
    type: "app",
    component: ssr
      ? BlogInnerPage
      : () => import("../pages/BlogInnerPage/BlogInnerPage"),
    isHeadLight: true,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/proxy",
    tag: "proxy",
    type: "app",
    component: ssr ? ProxyPage : () => import("../pages/ProxyPage/ProxyPage"),
    isAuthRequired: false,
    isSsr: true
  },

  {
    path: "/:lang?/proxy/:alias",
    tag: "proxy-site",
    type: "app",
    component: ssr
      ? ServicePage
      : () => import("../pages/ServicePage/ServicePage"),
    isHeadLight: true,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/proxy/:alias/promocodes",
    tag: "proxy-site-promo",
    type: "app",
    component: ssr
      ? ServicePage
      : () => import("../pages/ServicePage/ServicePage"),
    isHeadLight: true,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/requirements",
    tag: "requirements",
    type: "app",
    component: ssr ? RequirementsPage : () => import("../pages/RequirementsPage/RequirementsPage"),
    isHeadLight: true,
    isAuthRequired: false,
    isSsr: true
  },
];
