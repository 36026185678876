import styled from "@emotion/styled";

import { TextButton } from "../../../../../components/ui/TextButton/TextButton";

export const StyledInformadionsBlock = styled.div`
    display: ${(p) => (p.active ? "flex" : "none")};
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap 16px;

    margin-top: 24px;

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      padding: 24px;
      gap: 24px;
      border-radius: 12px;
      background: ${(p) => p.theme.colors.white};
      box-shadow: ${(p) => p.theme.shadows.promoCardLight};
    }
`;

export const StyledListInfo = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const StyledListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 0;

  color: ${(p) => p.theme.colors["neutral-500"]};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  font-style: ${(p) => p.theme.fontStyle.normal};
  line-height: 130%;

  border-bottom: 1px solid rgba(218, 218, 225, 0.4);
`;

export const StyledListItemTitle = styled.div`
  flex: 0 1 50%;
  display: flex;
  align-items: center;
  gap: 4px;

  ${(p) => (p.center ? "align-self: center;" : "")}
  & svg {
    width: 20px;
    height: 20px;
    color: #bcbcc4;
    flex: none;
  }
`;

export const StyledListItemValue = styled.div`
  flex: 0 1 50%;
  display: flex;
  flex-direction: ${(p) => (p.column ? "column" : "row")};
  flex-wrap: wrap;
  align-items: ${(p) => (p.column ? "flex-end" : "center")};
  justify-content: flex-end;
  gap: 8px;
  & > span {
    color: ${(p) => p.theme.colors["neutral-800"]};
    font-weight: 600;
  }
`;

export const StyledTextButton = styled(TextButton)`
  & > span {
    &::after {
      width: 100% !important;
    }
  }
`;
