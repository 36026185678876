import { createSlice } from "@reduxjs/toolkit";

import { seoBlockInitialState } from "./SeoBlock.initialState";
import { setSeoBlockReducer } from "./SeoBlock.reducer";
import { fetchSeoBlocks, setSeoBlock } from "./SeoBlock.thunks";

const SeoBlockSlice = createSlice({
  name: "seoblock",
  initialState: seoBlockInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(setSeoBlock, setSeoBlockReducer)

      // fetchSeoBlocks
      .addCase(fetchSeoBlocks.pending, (state, action) => {
        const { keys } = action.meta.arg;
        state.loadingKeys = [...state.loadingKeys, ...keys];
        state.loading = true;
      })
      .addCase(fetchSeoBlocks.fulfilled, (state, action) => {
        const data = action.payload;
        const { keys, languageCode, params } = action.meta.arg;

        const newData = keys.reduce(
          (acc, key) => ({
            ...acc,
            ...(data.hasOwnProperty(key)
              ? {
                  [key]: {
                    ...data[key],
                    showOnFront: true
                  }
                }
              : {
                  [key]: {
                    showOnFront: false
                  }
                })
          }),
          {}
        );

        state.data = {
          ...state.data,
          [languageCode]: {
            ...state.data[languageCode],
            ...newData
          }
        };

        state.loading = false;
        state.loadingKeys = state.loadingKeys.filter(
          (key) => !keys.includes(key)
        );
        state.proxyType = params?.fpt;
        state.country = params.fc;
      })
      .addCase(fetchSeoBlocks.rejected, (state, action) => {
        const { keys, languageCode } = action.meta.arg;

        state.errors = action.payload;
        state.loading = false;

        const notFoundKeys = keys.reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              showOnFront: false
            }
          }),
          {}
        );

        state.data = {
          ...state.data,
          [languageCode]: {
            ...state.data[languageCode],
            ...notFoundKeys
          }
        };
        state.loadingKeys = state.loadingKeys.filter(
          (key) => !keys.includes(key)
        );
      })
});

export const SeoBlockReducerActions = {
  setSeoBlock,
  fetchSeoBlocks
};

export default SeoBlockSlice.reducer;
