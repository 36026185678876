import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Scrollbar } from "../../../../components/common/Scrollbar/Scrollbar";
import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import { getAllBlog } from "../../../../store/reducers/BlogReducer/Blog.selectors";
import { allCategory } from "../../../../utils/constants/articleCategories";
import { BlogSearch } from "../BlogSearch/BlogSearch";

import { Tab, Tabs, TabsContainer, TabsWrapper } from "./BlogTabs.styled";

export const BlogTabs = ({ pageChangeHandler }) => {
  const [queryLang] = useLangUrlDefault();
  const { categories } = useSelector(getAllBlog);
  const { getBlogCategories } = useDispatchedActions();

  const [articleCategories, setArticleCategories] = useState([]);
  // **Props
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!categories.isDataLoaded) {
      getBlogCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (categories.data) {
      setArticleCategories(allCategory.concat(categories.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const handleClickTab = (name) => {
    searchParams.set("category", name);
    setSearchParams(searchParams);
    pageChangeHandler(1);
  };

  return (
    <TabsWrapper>
      <BlogSearch />
      <Scrollbar horizontal>
        <TabsContainer>
          <Tabs>
            {articleCategories?.map((category) => (
              <Tab
                key={category.id}
                active={searchParams.get("category") === category.name}
                onClick={(evt) => {
                  evt.preventDefault();
                  handleClickTab(category.name);
                }}
                title={category.localization?.[queryLang]}
                role="button"
              >
                {category.localization?.[queryLang]}
              </Tab>
            ))}
          </Tabs>
        </TabsContainer>
      </Scrollbar>
    </TabsWrapper>
  );
};
