import styled from "@emotion/styled";

export const StyledHolder = styled.div`
  & .ScrollbarsCustom-Thumb {
    border-radius: 100px !important;
    background-color: ${(props) =>
      props.dark
        ? "rgba(238, 239, 240, 0.05) !important"
        : "#dfe1e3 !important"};
  }
`;

export const StyledTrackX = styled.div`
  width: calc(100% - 6px) !important;
  border-radius: 100px !important;
  background: ${(props) =>
    props.dark ? "rgba(238, 239, 240, 0.05) !important" : "#fcfcfc !important"};
  left: 0 !important;
  height: 6px !important;
`;

export const StyledTrackY = styled.div`
  width: 6px !important;
  border-radius: 100px !important;
  background: ${(props) =>
    props.dark ? "rgba(238, 239, 240, 0.05) !important" : "#eeeff0 !important"};
`;
