import { Icon } from "../../../../components/ui/Icon/Icon";

import { StyledLabelOption } from "./About.styled";

export const generateOptions = (t) => [
  {
    label: (
      <StyledLabelOption>
        <Icon name="info" />
        {t("proxySitePage.about.tabs.info")}
      </StyledLabelOption>
    ),
    value: "info"
  },
  {
    label: (
      <StyledLabelOption>
        <Icon name="messegeText" />
        {t("proxySitePage.about.tabs.reviews")}
      </StyledLabelOption>
    ),
    value: "reviews"
  },
  {
    label: (
      <StyledLabelOption>
        <Icon name="lineChart" />
        {t("proxySitePage.about.tabs.rating")}
      </StyledLabelOption>
    ),
    value: "rating"
  },
  {
    label: (
      <StyledLabelOption>
        <Icon name="ticket" />
        {t("proxySitePage.about.tabs.promocodes")}
      </StyledLabelOption>
    ),
    value: "promocodes"
  }
];
