import styled from "@emotion/styled";

export const StyledOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  max-height: ${(p) => (p.isOpened ? "100%" : "0")};
  z-index: 160;
  transition: all 0.5s ease;
  backdrop-filter: blur(17.5px);
  opacity: ${(p) => (p.isOpened ? "1" : "0")};
`;

export const StyledContent = styled.div`
  position: fixed;
  left: 0;
  top: ${(p) => (p.isOpened ? "0" : "-521px")};
  opacity: ${(p) => (p.isOpened ? "1" : "0")};
  z-index: 165;
  overflow: auto;
  padding: 104px 16px 28px 16px;
  width: 100%;
  height: 100svh;
  background: rgba(14, 18, 24, 0.92);
  backdrop-filter: blur(17.5px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s ease;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 84px 0 0 0;
    min-height: 433px;
    max-height: 90%;
    height: auto !important;
    flex-direction: row;
  }
  ::-webkit-scrollbar-track {
    background-color: rgba(238, 239, 240, 0.05);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(238, 239, 240, 0.08);
  }
`;

export const StyledChildrenWrapper = styled.div`
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
`;
