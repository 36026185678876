import { SectionHead } from "../../../../../components/common/SectionHead/SectionHead";
import { useSeoBlock } from "../../../../../hooks";
import { parseSeoData } from "../../../../../utils/helpers";
import { capitalizeFirstLetter } from "../../../../../utils/helpers/text.helper";

export const ProxyContentTop = () => {
  const { data } = useSeoBlock(["mainPageProxyTable"]);

  return (
    <SectionHead
      title={capitalizeFirstLetter(
        parseSeoData(data?.mainPageProxyTable?.title, "TEXT")
      )}
      description={data?.mainPageProxyTable?.description?.content}
    />
  );
};
