import styled from "@emotion/styled";

import { FAQ } from "../../../components/common/FAQ/FAQ";
import { Main } from "../../../components/common/Main/Main";

export const StyledSection = styled.div`
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    background-color: ${({ theme }) => theme.colors["background-color"]};
  }
`;

export const RestyledMainSection = styled(Main)`
  padding-bottom: 35px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-bottom: 0;
    //   min-height: 580px;
  }

  // @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
  //   min-height: 604px;
  // }

  & .main-content-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      padding-top: 105px;
      padding-bottom: 278px;
    }
  }

  & .main-content-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      max-width: 600px;
    }

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      max-width: 655px;

      & .main-description {
        width: 100%;
      }
    }
  }

  & .main-image-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      top: 45px;
      width: 452px;
      height: 267px;
      right: 15px;
    }

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      right: 0px;
      width: 565px;
      height: 334px;
    }

    @media (min-width: 1350px) {
      right: -85px;
    }

    &::before {
      bottom: -10px !important;
    }
  }
`;

export const RestyledFAQ = styled(FAQ)`
  img {
    width: 100%;
    max-width: 409px;
  }
`;
