import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Scrollbar } from "../../Scrollbar/Scrollbar";
import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import { locationsList } from "../../../../utils/constants";
import { convertCountryCodeAlpha3ToAlpha2 } from "../../../../utils/helpers";
import { Flag } from "../../../ui/Flag/Flag";

import { StyledItem, StyledList, StyledName } from "./Countries.styled";

export const Countries = ({
  countries,
  search,
  selectedLocation,
  dataTestId
}) => {
  const [queryLang, hrefLang] = useLangUrlDefault();
  // const [searchParams, setSearchParams] = useSearchParams();
  const { setServiceMenu, setBurgerMenu } = useDispatchedActions();

  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState(countries?.data);

  const handleClick = (country) => {
    // const proxyType = searchParams.get("fpt");
    // if (proxyType) {
    //   searchParams.set("fc", country);
    //   setSearchParams(searchParams);
    // } else {
    navigate(`${hrefLang}/proxy/?fpt=IPv4&fc=${country}`);
    // }
    setServiceMenu(false);
    setBurgerMenu(false);
  };

  useEffect(() => {
    setFilteredData(
      countries?.data?.filter((country) => {
        const name = queryLang ? country?.localization[queryLang] : "";
        return name.toLowerCase().includes(search.toLowerCase());
      })
    );
  }, [search, countries, queryLang]);

  useEffect(() => {
    if (selectedLocation !== "topLocations" && !search) {
      const selectedContinent = locationsList.find(
        (location) => location.translation === selectedLocation
      );
      setFilteredData(
        countries?.data?.filter(
          (country) => country.continent === selectedContinent?.continent
        )
      );
    } else if (selectedLocation === "topLocations" && !search) {
      setFilteredData(
        countries?.data?.filter((country) => country.bestLocation)
      );
    }
  }, [selectedLocation, countries, queryLang, search]);

  return (
    <StyledList {...(dataTestId && { "data-testid": dataTestId })}>
      <Scrollbar vertical dark>
        {filteredData?.length > 0
          ? filteredData.map((country) => {
              const alpha2 = convertCountryCodeAlpha3ToAlpha2(
                country?.code
              ).toLowerCase();
              const name = queryLang ? country?.localization[queryLang] : "";
              return (
                <StyledItem
                  key={country?.id}
                  onClick={() => handleClick(country?.urlParam)}
                >
                  <Flag countryCode={alpha2} />
                  <StyledName>{name}</StyledName>
                </StyledItem>
              );
            })
          : null}
      </Scrollbar>
    </StyledList>
  );
};
