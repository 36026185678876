import styled from "@emotion/styled";

import { Title } from "../../../../../../components/ui/Title/Title";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // padding-bottom: 48px;
`;

export const StyledContent = styled.div`
  margin-bottom: 32px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
  }
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 32px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 48px !important;
  }
`;

export const StyledStep = styled.div`
  color: ${({ theme }) => theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.21;
  margin-bottom: 24px;
`;

export const StyledDescription = styled.div`
  color: ${({ theme }) => theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.67;
  min-height: 90px;
`;

export const StyledImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0;
  overflow: hidden;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: ${(p) => (p.mobile ? "none" : "block")};
    width: 483px;
    padding-bottom: 256px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 500px;
  }
`;
