import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";
import { Search } from "../../../../components/ui/Search/Search";

export const StyledContainerSearch = styled(Container)`
  width: 100%;
  min-width: 100%;
  padding: 0;
  margin-bottom: 24px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 36px !important;
    margin-right: 0;
    margin-left: 48px;
    padding-top: 2px !important;
    max-width: 248px;
    min-width: 248px;
  }
`;

export const StyledSearch = styled(Search)`
  height: 48px !important;
`;
