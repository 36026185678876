import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ExpandableComponent } from "../../../../../components/common/ExpandableCompononent/ExpandableComponent";
import { Scrollbar } from "../../../../../components/common/Scrollbar/Scrollbar";
import { StyledDataTable } from "../../../../../components/common/Table/Table.styled";
import { NoDataComponent } from "../../../../../components/common/TableColumns";
import { SkeletonProxyPageTable } from "../../../../../components/ui/Skeleton/SkeletonProxyPageTable/SkeletonProxyPageTable";
import { useLangUrlDefault } from "../../../../../hooks";
import { getAllProxySite } from "../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";

import { genColumns } from "./ProxyTable.helper";

export const ProxyTable = () => {
  const [queryLang, hrefLang] = useLangUrlDefault();

  const { t } = useTranslation();
  const { proxySites } = useSelector(getAllProxySite);

  const [data, setData] = useState(proxySites?.content);

  const handleExpand = (event) => {
    const id = event.currentTarget.id.replace("expand-", "");
    const newData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          expanded: !item.expanded
        };
      }
      return { ...item, expanded: false };
    });
    setData(newData);
  };

  const columns = genColumns(
    queryLang,
    hrefLang,
    t,
    handleExpand,
    proxySites?.skip
  );

  const conditionalRowStyles = [
    {
      when: (row) => row?.expanded,
      style: {
        backgroundColor: "#f6f7f9cc !important"
      }
    }
  ];

  useEffect(() => {
    setData(proxySites?.content);
  }, [proxySites?.content]);

  return (
    <Scrollbar horizontal>
      <StyledDataTable
        styleRules={{
          row: {
            noBorder: true
            // noPadding: true
          }
        }}
        columns={columns}
        data={data}
        progressPending={!proxySites?.isDataLoaded}
        progressComponent={<SkeletonProxyPageTable cells={10} />}
        persistTableHead={proxySites?.isDataLoaded}
        noDataComponent={<NoDataComponent />}
        conditionalRowStyles={conditionalRowStyles}
        expandableRowExpanded={(row) => row?.expanded}
        expandableRowsComponent={ExpandableComponent}
        expandableRows
        expandableRowsHideExpander
      />
    </Scrollbar>
  );
};
