import {
  ActionCell,
  AnnotationCell,
  DateCell,
  HeaderNameCell,
  SiteNameCell,
  StatusCell,
  TextCell
} from "../../../components/common/TableColumns";

export const genColumns = (hrefLang, t, openModalHandler) => [
  {
    name: (
      <HeaderNameCell>
        {t("dashboard.services.table.columns.name")}
      </HeaderNameCell>
    ),
    width: "230px",
    cell: (row) => (
      <SiteNameCell
        imgUrl={row?.imageURL || row?.imageFile}
        favicon={row?.favicon}
        faviconContentType={row?.faviconContentType}
        name={row?.name}
        rating={row?.rating}
        showRating
      />
    )
  },
  {
    name: (
      <HeaderNameCell>
        {t("dashboard.services.table.columns.date")}
      </HeaderNameCell>
    ),
    sortable: true,
    sortField: "date",
    selector: (row) => row?.createDate,
    // minWidth: "140px",
    cell: (row) => <DateCell date={row?.createDate} />
  },
  {
    name: (
      <HeaderNameCell>
        {t("dashboard.services.table.columns.status")}
      </HeaderNameCell>
    ),
    width: "160px",
    sortable: true,
    sortField: "statusSort",
    selector: (row) => row?.statusType,
    cell: (row) => (
      <StatusCell
        status={row?.statusType}
        tooltipText="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet."
      />
    )
  },
  {
    name: (
      <HeaderNameCell>
        {t("dashboard.services.table.columns.views")}
      </HeaderNameCell>
    ),
    // minWidth: "100px",

    selector: (row) => <TextCell>{row?.statistic?.views || 0}</TextCell>
  },
  {
    name: (
      <HeaderNameCell>
        {t("dashboard.services.table.columns.reviews")}
      </HeaderNameCell>
    ),
    // minWidth: "100px",

    selector: (row) => (
      <AnnotationCell
        positive={row.ratingPositive}
        negative={row.ratingNegative}
      />
    )
  },
  {
    name: (
      <HeaderNameCell>
        {t("dashboard.services.table.columns.conversions")}
      </HeaderNameCell>
    ),
    // minWidth: "120px",

    cell: (row) => <TextCell>{row?.statistic?.conversions || 0}</TextCell>
  },
  {
    width: "42px",
    selector: (row) => (
      <ActionCell
        id={row.id}
        actions={[
          {
            linkTo: `${hrefLang}/dashboard/services/edit/${row.id}/`,
            icon: "edit",
            label: t("dashboard.services.table.columns.actions.edit")
          },
          row?.statusType === "added"
            ? {
                linkTo: `${hrefLang}/proxy/${row.id}/`,
                onNewPage: true,
                icon: "viewFile",
                label: t("dashboard.services.table.columns.actions.view")
              }
            : null,
          {
            onClick: () => openModalHandler(row.id),
            variant: "danger",
            icon: "delete",
            label: t("dashboard.services.table.columns.actions.delete")
          }
        ].filter(Boolean)}
      />
    )
  }
];
