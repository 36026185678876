import { AnonimityTime } from "../../pages/ToolsPages/MyAnonymityPage/IpDetails/AnonimityTime";
import {
  Flag,
  Point
} from "../../pages/ToolsPages/MyAnonymityPage/IpDetails/IpDetails.styled";

const preparelanguage = (language, t) => {
  if (!language) {
    return null;
  }
  const languagesArray = language.split(",");
  const languagesTemp = languagesArray.map((lang) => {
    let langCode = lang;
    if (lang.includes("-")) {
      const [, upper] = lang.split("-");
      langCode = upper;
    }
    if (lang.includes("_")) {
      const [, upper] = lang.split("_");
      langCode = upper;
    }
    return langCode.toLowerCase();
  });
  const languages = languagesTemp
    .filter((lang) => lang.length === 2)
    .filter((lang, index, self) => self.indexOf(lang) === index);
  if (languages.length === 0) {
    return null;
  }

  return {
    id: "language",
    title: t("myAnonymity.details.language"),
    image: "img/ui/language.svg",
    listData: languages.map((lang) => ({
      value: (
        <>
          <Flag countryCode={lang} />
          {lang.toUpperCase()}
        </>
      )
    }))
  };
};

const prepareOpenPorts = (openPorts) => {
  if (!openPorts) {
    return null;
  }
  const keys = Object.keys(openPorts);
  const preperedOpenPorts = keys.reduce((acc, key) => {
    if (openPorts[key] && openPorts[key].length) {
      return [...acc, `${key} - ${openPorts[key].join(", ")}`];
    }
    return acc;
  }, []);
  if (!preperedOpenPorts.length) {
    return null;
  }
  return prepareOpenPorts;
};

const renderEnabled = (value, t) =>
  value ? (
    <>
      <Point color="green" /> {t("myAnonymity.details.enabled")}
    </>
  ) : (
    <>
      <Point color="red" /> {t("myAnonymity.details.disabled")}
    </>
  );
export const getIpDetailsData = (anonymity, t, navigator) =>
  [
    {
      id: "location",
      title: t("myAnonymity.details.location"),
      image: "img/ui/location.svg",
      listData: [
        {
          key: t("myAnonymity.details.country"),
          value: (
            <>
              <Flag countryCode={anonymity?.countryCode} />
              {anonymity?.country}
            </>
          )
        },
        {
          key: t("myAnonymity.details.region"),
          value: anonymity?.region
        },
        {
          key: t("myAnonymity.details.city"),
          value: anonymity?.city
        },
        {
          key: t("myAnonymity.details.zip"),
          value: anonymity?.zipcode
        },
        {
          key: t("myAnonymity.details.hostname"),
          value: anonymity?.host
        },
        {
          key: t("myAnonymity.details.reversed"),
          value: anonymity?.reversed
        },
        {
          key: t("myAnonymity.details.ipRange"),
          value: anonymity?.range
        },
        {
          key: t("myAnonymity.details.isp"),
          value: anonymity?.isp
        },
        {
          key: t("myAnonymity.details.organization"),
          value: anonymity?.organization
        },
        {
          key: t("myAnonymity.details.asOrganization"),
          value: anonymity?.asnOrganization
        },
        {
          key: t("myAnonymity.details.asNumber"),
          value: anonymity?.asnNumber
        }
      ]
    },
    preparelanguage(anonymity?.language, t),
    {
      id: "browser",
      title: t("myAnonymity.details.browser"),
      image: "img/ui/browser.svg",
      listData: [
        { key: t("myAnonymity.details.headers"), value: anonymity?.userAgent },
        { key: "JavaScript", value: anonymity?.userAgent }
      ]
    },
    {
      id: "time",
      title: t("myAnonymity.details.time"),
      image: "img/ui/time.svg",
      listData: [
        { key: t("myAnonymity.details.zone"), value: anonymity?.timeZone },
        {
          key: t("myAnonymity.details.local"),
          value: (
            <AnonimityTime
              suppressHydrationWarning={true}
              time={anonymity?.systemTime}
            />
          )
        },
        {
          key: t("myAnonymity.details.system"),
          value: (
            <AnonimityTime
              suppressHydrationWarning={true}
              time={anonymity?.time}
            />
          )
        }
      ]
    },
    // {
    //   id: "social Media",
    //   title: t("myAnonymity.details.socialMedia"),
    //   image: "img/ui/language.svg",
    //   listData: [
    //     // {
    //     //   value: (
    //     //     <>
    //     //       <SocialIcon src="img/icons/gmail.svg" alt="gmail" />
    //     //       Gmail
    //     //     </>
    //     //   )
    //     // },
    //     // {
    //     //   value: (
    //     //     <>
    //     //       <SocialIcon src="img/icons/facebook.svg" alt="facebook" />
    //     //       Facebook
    //     //     </>
    //     //   )
    //     // }
    //   ]
    // },
    {
      id: "interactive detection",
      title: t("myAnonymity.details.interactiveDetection"),
      image: "img/ui/interactive-detection.svg",
      listData: [
        {
          key: "Ip address",
          value: anonymity?.ip
        },
        { key: "Flash", value: anonymity?.flash },
        { key: "WebRTC", value: anonymity?.webRtc },
        { key: "Java (TCP)", value: anonymity?.java },
        { key: "Java (UDP)", value: anonymity?.java },
        { key: "Java (system)", value: anonymity?.java },
        { keyTitle: "DNS" },
        {
          key: t("myAnonymity.details.browser"),
          value: anonymity?.browser
        },
        { key: "Flash", value: anonymity?.flash },
        { key: "Java (request)", value: anonymity?.java },
        { key: "Java (system)", value: anonymity?.java },
        { keyTitle: t("myAnonymity.details.os") },
        { key: t("myAnonymity.details.headers"), value: anonymity?.userAgent },
        { key: "JavaScript", value: anonymity?.js },
        { key: "Java (request)", value: anonymity?.java },
        { key: "Java (system)", value: anonymity?.java },
        { keyTitle: t("myAnonymity.details.ports") },
        {
          key: t("myAnonymity.details.openPorts"),
          value: prepareOpenPorts(anonymity?.openPorts)
        }
      ]
    },
    {
      id: "navigator",
      title: t("myAnonymity.details.navigator"),
      image: "img/ui/navigator.svg",
      listData: [
        { key: "ProductSub", value: anonymity?.productSub },
        { key: "Vendor", value: anonymity?.vendor },
        { key: "MaxTouchPoints", value: anonymity?.maxTouchPoints },
        { key: "PdfViewerEnabled", value: anonymity?.pdfViewerEnabled },
        { key: "HardwareConcurrency", value: anonymity?.maxTouchPoints },
        { key: "CookieEnabled", value: anonymity?.cookie },
        { key: "AppCodeName", value: anonymity?.app },
        { key: "AppName", value: anonymity?.appType },
        { key: "AppVersion", value: anonymity?.appMajorVersion },
        { key: t("myAnonymity.details.platform"), value: anonymity?.platform },
        { key: "UserAgent", value: anonymity?.userAgent },
        { key: t("myAnonymity.details.language"), value: anonymity?.language },
        { key: "OnLine", value: anonymity?.onLine },
        { key: "Webdriver", value: anonymity?.webdriver },
        { key: "DeviceMemory", value: anonymity?.deviceMemory }
      ]
    },
    {
      id: "screen",
      title: t("myAnonymity.details.screen"),
      image: "img/ui/screen.svg",
      listData: [
        { key: "ColorDepth", value: anonymity?.color },
        { key: "PixelDepth", value: anonymity?.color },
        {
          key: t("myAnonymity.details.height"),
          value: anonymity?.screen ? anonymity?.screen.split("x")[0] : null
        },
        {
          key: t("myAnonymity.details.width"),
          value: anonymity?.screen ? anonymity?.screen.split("x")[1] : null
        },
        { key: "AvailHeight", value: anonymity?.availHeight },
        { key: "AvailWidth", value: anonymity?.availWidth },
        { key: "Top", value: anonymity?.top },
        { key: "Left", value: anonymity?.left },
        { key: "AvailTop", value: anonymity?.availTop },
        { key: "AvailLeft", value: anonymity?.availLeft },
        { key: t("myAnonymity.details.windowSize"), value: anonymity?.screen }
      ]
    },
    {
      id: "scripts",
      title: t("myAnonymity.details.scripts"),
      image: "img/ui/scripts.svg",
      listData: [
        {
          key: "JavaScript",
          value: renderEnabled(anonymity?.javaScript, t)
        },
        {
          key: "Flash",
          value: renderEnabled(anonymity?.flash, t)
        },
        {
          key: "Java",
          value: renderEnabled(anonymity?.java, t)
        },
        {
          key: "ActiveX",
          value: renderEnabled(anonymity?.activeX, t)
        },
        {
          key: "WebRTC",
          value: renderEnabled(anonymity?.webRtc, t)
        },
        {
          key: "VBScript",
          value: renderEnabled(anonymity?.vbsScript, t)
        },
        {
          key: "AdBlock",
          value: renderEnabled(anonymity?.adBlock, t)
        }
      ]
    },
    {
      id: "plugins",
      title: t("myAnonymity.details.plugins"),
      image: "img/ui/plugins.svg",
      listData: [
        {
          key: "PDF Viewer",
          value: navigator?.plugins?.["PDF Viewer"]?.filename || "N/A"
        },
        {
          key: "Chrome PDF Viewer",
          value: navigator?.plugins?.["Chrome PDF Viewer"]?.filename || "N/A"
        },
        {
          key: "Chromium PDF Viewer",
          value: navigator?.plugins?.["Chromium PDF Viewer"]?.filename || "N/A"
        },
        {
          key: "Microsoft Edge PDF Viewer",
          value:
            navigator?.plugins?.["Microsoft Edge PDF Viewer"]?.filename || "N/A"
        },
        {
          key: "WebKit built-in PDF",
          value: navigator?.plugins?.["WebKit built-in PDF"]?.filename || "N/A"
        }
      ]
    }
  ].filter((item) => item);
