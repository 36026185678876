import { $api } from "../../services/AxiosInstance";
import { AllActions } from "../../store/reducers/AllActions";
import { PROXY_FILTERS } from "../../utils/constants/filter.constant";
import { getCountryId, getGoalId } from "../../utils/helpers";

export const getMainPageState = async (store, params) => {
  const fptParams = params.params.fpt || "IPv4";

  const {
    content: { countries, goals }
  } = store.getState();

  const fetchParams = {
    proxyType: Array.isArray(fptParams) ? fptParams : [fptParams],
    country: getCountryId(countries.data, params?.params?.fc)?.id || "",
    sort: params?.params?.filter || PROXY_FILTERS.reviews,
    goal: getGoalId(goals.data, params?.params?.fg)?.id || "",
    top: false,
    useNew: true,
    page: 0,
    size: 10
  };
  const { data: sites } = await $api.post("/api/front/site/proxy", fetchParams);

  const { data: UserChoiceData } = await $api.get("/api/front/userChoices", {
    params: {
      size: 10
    }
  });

  const fetchSeoBlockParams = {
    languageCode: params.locale,
    keys: ["mainPageProxyTable"]
  };
  const { data: seoBlock } = await $api.post(
    "/api/front/seoBlock",
    fetchSeoBlockParams
  );

  store.dispatch(
    AllActions.setSeoBlock({ data: seoBlock, params: fetchSeoBlockParams })
  );
  store.dispatch(AllActions.setProxySites({ ...sites, fetchParams }));
  store.dispatch(AllActions.setUserChoice(UserChoiceData));
};
