import {
  BoxCell,
  CustomExpandCell,
  HeaderNameCell,
  IndexCell,
  SiteNameCell,
  TextCell
} from "../../../../../components/common/TableColumns";
import { YesNo } from "../../../../../components/common/YesNo/YesNo";
import { TextButton } from "../../../../../components/ui/TextButton/TextButton";
import {
  sortMinPriceColumn,
  sortMinRentColumn,
  time
} from "../../../../../utils/helpers";

// eslint-disable-next-line max-params
export const genColumns = (queryLang, hrefLang, t, handleExpand, skip) => [
  {
    name: (
      <HeaderNameCell width={50} center>
        №
      </HeaderNameCell>
    ),
    selector: (row, index) => {
      const currentNumber = index + 1 + skip;
      return (
        <BoxCell width={50}>
          <IndexCell top={row?.topSite}>
            {`${currentNumber < 10 ? "0" : ""}${currentNumber}.`}
          </IndexCell>
        </BoxCell>
      );
    },
    width: "50px"
    // center: true
  },
  {
    name: (
      <HeaderNameCell minWidth={290}>
        {t("proxyPage.content.table.name.name")}
      </HeaderNameCell>
    ),
    cell: (row) => (
      <BoxCell minWidth={290}>
        <SiteNameCell
          imgUrl={row?.imageFile}
          favicon={row?.favicon}
          faviconContentType={row?.faviconContentType}
          name={row?.name}
          rating={row?.rating}
          showRating
          showAnnotation
          positive={row?.ratingPositive}
          negative={row?.ratingNegative}
          showReviewButton={row?.showReviewButton}
          reviewButtonUrl={row?.reviewButtonUrl}
          // linkToReview={`${hrefLang}/proxy/${row.alias}/#reviews`}
        />
      </BoxCell>
    ),
    width: "290px"
  },
  {
    name: (
      <HeaderNameCell width={110} sortable>
        {t("proxyPage.content.table.minPrice.title")}
      </HeaderNameCell>
    ),
    cell: ({ currentTypeProp }) => (
      <BoxCell width={110}>
        <TextCell>
          {!currentTypeProp ||
          (!currentTypeProp?.minPrice && currentTypeProp?.minPrice !== 0)
            ? ""
            : `$${currentTypeProp?.minPrice}`}
        </TextCell>
      </BoxCell>
    ),
    sortable: true,
    selector: (row) => row?.currentTypeProp?.minPrice,
    sortFunction: (rowA, rowB) => sortMinPriceColumn(rowA, rowB, true)
    // width: "110px"
  },
  {
    name: (
      <HeaderNameCell width={110} sortable>
        {t("proxyPage.content.table.minRent.title")}
      </HeaderNameCell>
    ),
    cell: ({ currentTypeProp }) => (
      <BoxCell width={110}>
        <TextCell>
          {!currentTypeProp || !currentTypeProp?.minRentPeriod
            ? ""
            : time(
                currentTypeProp?.minRentPeriod?.periodName,
                currentTypeProp?.minRentPeriod?.periodValue,
                queryLang,
                t("times", { returnObjects: true })
              )}
        </TextCell>
      </BoxCell>
    ),
    sortable: true,
    selector: (row) => row?.currentTypeProp?.minRentPeriod?.periodMilliseconds,
    sortFunction: (rowA, rowB) => sortMinRentColumn(rowA, rowB, true)
    // width: "110px"
  },
  {
    name: (
      <HeaderNameCell width={90}>
        {t("proxyPage.content.table.freeTest.title")}
      </HeaderNameCell>
    ),
    cell: (row) => (
      <BoxCell width={90}>
        <YesNo value={row?.freeTest} />
      </BoxCell>
    )
    // width: "90px"
  },
  {
    name: (
      <HeaderNameCell width={105}>
        {t("proxyPage.content.table.site.title")}
      </HeaderNameCell>
    ),
    cell: (row) => (
      <BoxCell width={105}>
        <TextButton
          size="sm"
          color="table"
          iconRight="arrowAltRight"
          linkTo={`${hrefLang}/proxy/${row.alias}/`}
        >
          {t("proxyPage.content.table.site.btn")}
        </TextButton>
      </BoxCell>
    )
    // width: "105px"
  },
  {
    cell: (row) => (
      <BoxCell width={44}>
        <CustomExpandCell
          id={`expand-${row.id}`}
          open={row?.expanded}
          onClick={handleExpand}
        />
      </BoxCell>
    ),
    width: "44px"
  }
];
