import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Scrollbar } from "../../../components/common/Scrollbar/Scrollbar";
import { StyledDataTable } from "../../../components/common/Table/Table.styled";
import { NoDataComponent } from "../../../components/common/TableColumns";
import { Pagination } from "../../../components/ui/Pagination/Pagination";
import { SkeletonDashboardServiceTable } from "../../../components/ui/Skeleton/SkeletonDashboardServiceTable/SkeletonDashboardServiceTable";
import { SkeletonPaginationDashboardTable } from "../../../components/ui/Skeleton/SkeletonPaginationDashboardTable/SkeletonPaginationDashboardTable";
import { TableCheckbox } from "../../../components/ui/TableCheckbox/TableCheckbox";
import { useDispatchedActions, useLangUrlDefault } from "../../../hooks";
import { ApiService } from "../../../services";
import { AllActions } from "../../../store/reducers/AllActions";
import { getAllCabinet } from "../../../store/reducers/CabinetReducer/Cabinet.selectors";
import { ModalConfirm } from "../components/ModalConfirm/ModalConfirm";
import { NoData } from "../components/NoData/NoData";
import { SortBlock } from "../components/SortBlock/SortBlock";
import { TitleBlock } from "../components/TitleBlock/TitleBlock";

import { genColumns } from "./Services.columns";
import { PaginationBox, ServicesContainer } from "./Services.styled";

const Services = () => {
  const [queryLang, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { t } = useTranslation();

  const { proxies, siteStatuses } = useSelector(getAllCabinet);

  // **Local state
  const [deletedSiteId, setDeletedSiteId] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState(
    proxies.prevParams || {
      proxyType: [],
      country: "",
      statusId: "",
      page: 0,
      size: 10
    }
  );
  const [isDeleting, setIsDeleting] = useState(false);

  // **Dispatch
  const { getCabinetSites, setCabinetProxiesPrevParams } =
    useDispatchedActions();

  const fetchCabinetSites = useCallback(() => {
    getCabinetSites(params);
    setCabinetProxiesPrevParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // Change page by clicking pagination
  const pageChangeHandler = (page) => {
    setParams({ ...params, page: page - 1 });
  };

  const pageSizeChangeHandler = (size) =>
    setParams({ ...params, page: 0, size });

  const changeSortType = (data) => {
    setParams({ ...params, page: 0, statusId: data === "all" ? "" : data });
  };

  const openModalHandler = (id) => {
    setIsModalOpened(true);
    setDeletedSiteId(id);
  };

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const confirmHandler = async () => {
    try {
      setIsDeleting(true);

      const response = await ApiService.deleteProxySite(deletedSiteId);

      if (response && response.status !== 200) {
        throw response;
      }

      // Closing modal
      setIsModalOpened(false);

      // Show success message
      toast.success(t("notifications.site.removed"));

      setDeletedSiteId(null);
      fetchCabinetSites();
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setTimeout(() => {
        setIsDeleting(false);
      }, 300);
    }
  };

  const columns = genColumns(hrefLang, t, openModalHandler);

  const conditionalRowStyles = [
    {
      when: (row) => selectedRows.some((r) => r.id === row.id),
      style: {
        backgroundColor: "rgba(246, 247, 249, 0.80)"
      }
    }
  ];

  const customStyles = {
    table: {
      style: {
        marginBottom: "18px"
      }
    },
    rows: {
      style: {
        minHeight: "64px"
      }
    }
  };

  useEffect(() => {
    if (siteStatuses.data?.length) {
      setStatusOptions([
        ...siteStatuses.data.map((item) => ({
          label: item.translations?.[queryLang] || item.name,
          value: item.id
        })),
        { label: t("dashboard.services.table.all"), value: "all" }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteStatuses.data, queryLang]);

  useEffect(() => {
    if (JSON.stringify(params) !== JSON.stringify(proxies.prevParams)) {
      fetchCabinetSites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (proxies.data.totalPages !== params.page || params.page === 0) {
      return;
    }

    setParams({ ...params, page: params.page - 1 });
  }, [params, proxies]);

  useEffect(() => {
    if (!proxies.isLoading && typeof proxies.data?.anyExists === "boolean") {
      setLoading(false);
    }
  }, [proxies.isLoading, proxies.data?.anyExists]);

  const handleSort = (column, sortDirection) => {
    const newParams = { ...params };
    if (column.sortField === "date") {
      delete newParams.statusSort;
    } else {
      delete newParams.date;
    }
    setParams({ ...newParams, [column.sortField]: sortDirection, top: false });
  };

  return (
    <>
      <TitleBlock
        title={t("dashboard.services.table.title")}
        linkTo="/dashboard/services/create/"
        linkName={t("dashboard.services.table.add")}
      />

      <ServicesContainer
        noData={!proxies.isLoading && !proxies.data?.anyExists}
      >
        {!proxies.isLoading && !proxies.data?.anyExists ? (
          <NoData
            title={t("dashboard.services.noData.title")}
            subtitle={t("dashboard.services.noData.subtitle")}
            srcX1="/img/dashboard/no-service-x1.png"
            srcX2="/img/dashboard/no-service-x2.png"
            imgMaxWidth={343}
          />
        ) : (
          <>
            <SortBlock
              defaultValue="all"
              value={params.statusId || "all"}
              label={t("dashboard.services.table.filter")}
              options={statusOptions}
              changeSortType={changeSortType}
            />
            <Scrollbar horizontal>
              <StyledDataTable
                styleRules={{
                  showedAll: true
                }}
                columns={columns}
                data={proxies.data?.content}
                selectableRows
                selectableRowsComponent={TableCheckbox}
                onSelectedRowsChange={handleRowSelected}
                conditionalRowStyles={conditionalRowStyles}
                customStyles={customStyles}
                progressPending={loading}
                progressComponent={<SkeletonDashboardServiceTable cells={10} />}
                persistTableHead={!loading}
                noDataComponent={<NoDataComponent bordered />}
                onSort={handleSort}
              />
            </Scrollbar>
            {loading && <SkeletonPaginationDashboardTable />}
            {!loading && proxies.data?.totalElements > 0 && (
              <PaginationBox>
                <Pagination
                  rowCount={proxies.data.totalElements}
                  rowsPerPage={params.size}
                  onChangePage={pageChangeHandler}
                  onChangeRowsPerPage={pageSizeChangeHandler}
                  currentPage={params.page + 1}
                  useCount
                />
              </PaginationBox>
            )}
          </>
        )}
      </ServicesContainer>
      <ModalConfirm
        open={isModalOpened}
        close={setIsModalOpened}
        isDeleting={isDeleting}
        srcX1="/img/dashboard/delete-service-x1.png"
        srcX2="/img/dashboard/delete-service-x2.png"
        subtitle={t("dashboard.services.modal.delete.subtitle")}
        onConfirm={confirmHandler}
      />
    </>
  );
};

export default Services;

Services.getServerSideState = async (store, params) => {
  const { data: pageContent } = await ApiService.getPageContent({
    ...params,
    languageCode: params.locale
  });

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setPageContent(pageContent));
};
