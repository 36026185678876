import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Button } from "../../../../../components/ui/Button/Button";
import { StyledSkeleton } from "../../../../../components/ui/Skeleton/components/SkeletonTableComponents.styled";
import { TextButton } from "../../../../../components/ui/TextButton/TextButton";
import { useBrowserFingerprint, useLangUrlDefault } from "../../../../../hooks";
import { ApiService } from "../../../../../services";
import { getAllProxySite } from "../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { fingerprintLibVersion } from "../../../../../utils/constants/fingerprintLibVersion";
import { getSiteDescription } from "../../../../../utils/helpers";
import { scrollTo } from "../../../../../utils/helpers/scroll.helper";

import {
  StyledAboutButtonBox,
  StyledAboutContainer,
  StyledAboutDescription,
  StyledAboutTitle,
  StyledAboutTitleBox
} from "./HeadAbout.styled";

export const HeadAbout = ({ id }) => {
  const { t } = useTranslation();
  const browserFingerprint = useBrowserFingerprint();

  const [queryLang] = useLangUrlDefault();

  const {
    site: { data, isDataLoaded }
  } = useSelector(getAllProxySite);

  const [showFull, setShowFull] = useState(false);
  const [description, setDescription] = useState(
    getSiteDescription(data?.description, queryLang) || ""
  );

  const triggerShowFull = (event) => {
    if (showFull) {
      const windowScroll = window?.scrollY;
      const element = document.getElementById(id);
      if (element && windowScroll > element.offsetTop) {
        scrollTo(id);
      }
    }
    setShowFull((prev) => !prev);
    event.currentTarget?.blur();
  };

  useEffect(() => {
    if (isDataLoaded && data?.description) {
      setDescription(getSiteDescription(data?.description, queryLang) || "");
    }
  }, [isDataLoaded, data, queryLang]);

  const handleButtonClick = (id) => {
    ApiService.sendStatistics({
      siteId: id,
      type: "REFERRAL",
      fingerprint: browserFingerprint,
      fingerprintLibVersion
    });
  };

  return (
    <StyledAboutContainer>
      <StyledAboutTitleBox>
        <StyledAboutTitle>{t("proxySitePage.about.title")}</StyledAboutTitle>
        <StyledAboutButtonBox>
          {isDataLoaded ? (
            <>
              {data?.showReviewButton && data?.reviewButtonUrl && (
                <Button
                  variant="outlined"
                  size="md"
                  linkTo={data?.reviewButtonUrl}
                >
                  {t("proxySitePage.about.readReview")}
                </Button>
              )}
              <Button
                size="md"
                linkTo={data?.referralUrl ? data?.referralUrl : data?.url}
                onClick={() => handleButtonClick(data?.id)}
                dataTestId="inner-service-visit-website"
                target="blank"
              >
                {t("proxySitePage.about.visit")}
              </Button>
            </>
          ) : (
            <>
              <StyledSkeleton width={140} height={40} />
              <StyledSkeleton width={140} height={40} />
            </>
          )}
        </StyledAboutButtonBox>
      </StyledAboutTitleBox>

      {isDataLoaded ? (
        <>
          {!!description && (
            <StyledAboutDescription showFull={showFull}>
              {description?.length > 500 && !showFull
                ? `${description.slice(0, 500)}...`
                : description}
            </StyledAboutDescription>
          )}
          {description?.length > 500 && (
            <TextButton onClick={triggerShowFull}>
              {showFull ? t("reviews.showLess") : t("reviews.showFull")}
            </TextButton>
          )}
        </>
      ) : (
        <StyledAboutDescription>
          <StyledSkeleton count={3} />
        </StyledAboutDescription>
      )}
    </StyledAboutContainer>
  );
};
