import { useMemo } from "react";

import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";

import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import {
  getMinPrice,
  nextYearValue
} from "../../../utils/helpers/productMarkup.helpers";

export const ProductProxyMarkup = () => {
  const { routeContent } = useSelector(getAllContent);
  const { proxySites } = useSelector(getAllProxySite);
  const {
    pageContent: { current }
  } = useSelector(getAllContent);

  const homeURL = process.env.REACT_APP_URL;

  const randomRating = (Math.random() + 4).toFixed(1);

  const reviewsCountByDate = useMemo(() => {
    let initReviewsCount = 104;
    const initTimestamp = new Date("2024-08-19T00:00:00Z").getTime();
    const currentTimestamp = Date.now();
    const millisecondsIn30Days = 30 * 24 * 60 * 60 * 1000;
    const monthsPassed = Math.floor(
      (currentTimestamp - initTimestamp) / millisecondsIn30Days
    );
    if (monthsPassed <= 0) {
      return initReviewsCount;
    }
    initReviewsCount *= 1.03 ** monthsPassed;
    return Math.round(initReviewsCount);
  }, []);

  return (
    <Helmet>
      <script type="application/ld+json">
        {`
                {
                "@context": "http://schema.org",
                "@type": "Product",
                "image": [
                  "${homeURL}/img/meta_logo.jpg"
                ],
                "name": "Proxy server ${
                  proxySites.fetchParams?.proxyType?.[0]
                }",
                "description": "${current?.data?.description}",
                "brand": {
                  "@type": "Brand",
                  "name": "Trusty Tech"
                },
                "sku": "535605",
                "mpn": "22765",
                "aggregateRating":{
                  "@type":"AggregateRating",
                  "ratingValue": ${randomRating},
                  "reviewCount": ${reviewsCountByDate},
                  "bestRating": 5
                },
                "review": {
                  "@type": "Review",
                  "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": ${randomRating},
                    "bestRating": "5"
                  },
                  "author": {
                    "@type": "Person",
                    "name": "Peter"
                  }
                },
                "offers": {
                  "@type": "Offer",
                  "url": "${homeURL}${routeContent?.location}",
                  "priceCurrency": "USD",
                  "price": "${getMinPrice(proxySites.content)}",
                  "priceValidUntil": "${nextYearValue()}",
                  "availability": "https://schema.org/InStock",
                  "itemCondition": "https://schema.org/NewCondition"
                }
              }
            `}
      </script>
    </Helmet>
  );
};
