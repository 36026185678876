import { Scrollbar as ReactScrollbar } from "react-scrollbars-custom";

import { StyledHolder, StyledTrackX, StyledTrackY } from "./Scrollbar.styled";

export const Scrollbar = ({ vertical, horizontal, children, dark }) => (
  <ReactScrollbar
    translateContentSizeYToHolder
    noScrollY={!!horizontal}
    noScrollX={!!vertical}
    renderer={(props) => {
      const { elementRef, ...restProps } = props;
      return <StyledHolder dark={dark} {...restProps} ref={elementRef} />;
    }}
    trackXProps={{
      renderer: (props) => {
        const { elementRef, ...restProps } = props;
        return <StyledTrackX {...restProps} dark={dark} ref={elementRef} />;
      }
    }}
    trackYProps={{
      renderer: (props) => {
        const { elementRef, ...restProps } = props;
        return <StyledTrackY {...restProps} ref={elementRef} dark={dark} />;
      }
    }}
  >
    {children}
  </ReactScrollbar>
);
