import { $api } from "../../services/AxiosInstance";
import { AllActions } from "../../store/reducers/AllActions";
import { PROXY_FILTERS } from "../../utils/constants/filter.constant";
import { getCountryId, getGoalId } from "../../utils/helpers";

export const getProxyPageState = async (store, params) => {
  const fptParams = params.params.fpt || "IPv4";
  const { data: pageContent } = await $api.post("/api/page/front/content", {
    ...params,
    languageCode: params.locale,
    location: params.params.fpt
      ? params.location
      : `${params.location}?fpt=IPv4`,
    params: {
      ...params.params,
      fpt: Array.isArray(fptParams)
        ? fptParams[fptParams?.length - 1]
        : fptParams || null
    }
  });

  const {
    content: { countries, goals }
  } = store.getState();

  const fetchParams = {
    proxyType: Array.isArray(fptParams) ? fptParams : [fptParams],
    country: getCountryId(countries.data, params?.params?.fc)?.id || "",
    sort: params?.params?.filter || PROXY_FILTERS.rating,
    goal: getGoalId(goals.data, params?.params?.fg)?.id || "",
    useNew: true,
    top: true,
    page: 0,
    size: 10
  };
  const { data: sites } = await $api.post("/api/front/site/proxy", fetchParams);
  const fetchFaqsParams = {
    pageTag: params.tag,
    languageCode: params.locale,
    params: {}
  };
  const keys = [params.tag];
  if (params.params.fpt) {
    fetchFaqsParams.params.fpt = params.params.fpt;
    keys.push(params.params.fpt);
  }
  if (params.params.fc) {
    fetchFaqsParams.params.fc = params.params.fc;
    keys.push(params.params.fc);
  }
  const { data: faqData } = await $api.post("/api/front/faqs", fetchFaqsParams);

  const specialPromocodesType = Array.isArray(fptParams)
    ? fptParams?.[0]
    : fptParams;
  const { data: specialPromocodes } = await $api.get(
    "/api/front/promocode/special",
    {
      params: {
        proxyType: specialPromocodesType
      }
    }
  );

  const fetchSeoBlockParams = {
    languageCode: params.locale,
    keys: [
      "specialOffer",
      "proxyPageProxyTable",
      "howToBuy",
      "infoAboutProxyType"
    ]
  };
  const { data: seoBlock } = await $api.post(
    "/api/front/seoBlock",
    fetchSeoBlockParams
  );
  store.dispatch(
    AllActions.setSeoBlock({ data: seoBlock, params: fetchSeoBlockParams })
  );
  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setPageContent({
      data: pageContent,
      lang: params.locale,
      innerTag: params.location
    })
  );

  store.dispatch(
    AllActions.setSpecialPromocodes({
      data: specialPromocodes,
      type: specialPromocodesType
    })
  );
  store.dispatch(AllActions.setProxySites({ ...sites, fetchParams }));

  if (faqData) {
    store.dispatch(
      AllActions.setFaqContent({
        data: faqData,
        languageCode: params.locale,
        pageTag: keys.join("-")
      })
    );
  }
};
