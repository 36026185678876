import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

// API
import { ApiService } from "../../../services";

export const getBlogContentByAlias = createAsyncThunk(
  "bloginner/getBlogContentByAlias",
  async ({ alias, languageCode }, { rejectWithValue }) => {
    try {
      const response = await ApiService.getBlogContentByAlias(
        alias,
        languageCode
      );

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getBlogComments = createAsyncThunk(
  "bloginner/getBlogComments",
  async (params, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAllComments(params);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getBlogSidebarContent = createAsyncThunk(
  "bloginner/getBlogSidebarContent",
  async ({ languageCode }, { rejectWithValue, signal }) => {
    try {
      const response = await ApiService.getBlogSidebarContent(
        languageCode,
        signal
      );

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return { data: response.data, languageCode };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const setErrorBlogInnerContent = createAction(
  "bloginner/setErrorBlogInnerContent"
);

export const setBlogSidebarContent = createAction(
  "bloginner/setBlogSidebarContent"
);

export const setBlogContentByAlias = createAction(
  "bloginner/setBlogContentByAlias"
);

export const setBlogComments = createAction("bloginner/setBlogComments");
