/* eslint-disable no-undef */
import { useEffect, useState } from "react";

import Moment from "react-moment";

const currentTime = (time, type) => {
  if (!time) {
    return time;
  }
  if (type === "sec") {
    return time * 1000;
  }
  if (type === "min") {
    return time * 60000;
  }
  // if (type === "hour") return time * 3600000;

  return time;
};

export const Timer = ({ time, onEnd, type, restartTriger }) => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer((prev) => prev - 1000);
      } else {
        clearInterval(interval);
        onEnd?.();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer, onEnd]);

  useEffect(() => {
    setTimer(currentTime(time, type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restartTriger]);

  return (
    <Moment format="mm:ss" utc>
      {timer}
    </Moment>
  );
};
