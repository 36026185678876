import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Scrollbar } from "../../../../../components/common/Scrollbar/Scrollbar";
import { NoDataComponent } from "../../../../../components/common/TableColumns";
import { SkeletonUserChoise } from "../../../../../components/ui/Skeleton/SkeletonUserChoise/SkeletonUserChoise";
import { useDispatchedActions, useLangUrlDefault } from "../../../../../hooks";
import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";

import { genColumns } from "./UserChoiceTable.helper";
import {
  StyledUserChoiceSpase,
  StyledUserChoiceTable,
  StyledUserChoiceTitle
} from "./UserChoiceTable.styled";

export const UserChoiceTable = () => {
  const { t } = useTranslation();
  const [queryLang, hrefLang] = useLangUrlDefault();
  const { userChoice } = useSelector(getAllContent);

  const { getUserChoice } = useDispatchedActions();

  const columns = genColumns(queryLang, hrefLang, t);

  useEffect(() => {
    if (!userChoice?.isDataLoaded) {
      getUserChoice(10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledUserChoiceSpase>
      <StyledUserChoiceTitle tag="h3">
        {t("mainPage.userChoice.title")}
      </StyledUserChoiceTitle>
      <Scrollbar horizontal>
        <StyledUserChoiceTable
          data={userChoice?.data}
          columns={columns}
          progressPending={!userChoice?.isDataLoaded}
          progressComponent={<SkeletonUserChoise cells={10} />}
          persistTableHead={userChoice?.isDataLoaded}
          noDataComponent={<NoDataComponent />}
        />
      </Scrollbar>
    </StyledUserChoiceSpase>
  );
};
